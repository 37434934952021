@use "sass/core/variables";

sf-files-customfield > md-input-container,
sf-files-customfield-display {
    > .files-box {
        min-height: 50px;
        max-height: 145px;
        border: 1px solid rgb(0 0 0 / 12%);
        padding: 6px;
        overflow: auto;
        margin-top: 4px;

        .file-text {
            padding-top: 10px;

            md-icon {
                margin-right: 4px;
                margin-top: -2px;
            }
        }

        div.file-text {
            padding-left: 6px;
        }

        span.file-text {
            text-align: right;
        }

        > .empty {
            margin-top: 16px;
            padding-left: 6px;
        }

        .sf-icon-delete {
            color: variables.$text-color;
            margin-top: -2px;
        }

        .single {
            margin-top: 6px;
        }

        // For some inputs like md-chips, md-required doesn't get applied to the label, so we need to do it manually.
        label:not(.md-container-ignore).manual-error-class.form-submitted {
            color: rgb(213 0 0);
        }

        .manual-error-message {
            color: rgb(213 0 0);

            div {
                font-size: 12px;
                line-height: 14px;
                overflow: hidden;
                transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
                opacity: 1;
                margin-top: 0;
                padding-top: 5px;
                padding-right: 5px;
                padding-left: 0;
            }
        }
    }

    > .files-box.ng-invalid.form-submitted {
        border: 1px solid rgb(213 0 0);
    }

    > .button-div {
        .button-add {
            margin-top: 8px;
        }
    }
}

sf-files-customfield-display {
    margin-left: 16px;

    > .files-box {
        margin-left: 16px;
    }

    > md-list > md-list-item > div.md-tile-content.flex > md-chip > div > md-icon.md-avatar {
        margin-right: 4px;
    }
}
