@use "sass/core/variables";

#upgrade-dialog {
    width: 900px;
    height: 600px;

    @media screen and (height <= 700px) {
        p {
            font-size: 14px;
        }
    }

    .upgrade-container {
        width: 300px;
        background-color: #303c4f;

        a:not(.md-button) {
            text-decoration: underline;
            color: variables.$white-color;
            font-size: 14px;
        }

        .pricing-info {
            margin-top: 4px;
        }

        @media screen and (max-width: variables.$layout-breakpoint-sm) {
            width: 100%;
            height: 200px;
        }

        @media screen and (height <= 840px) and (max-width: variables.$layout-breakpoint-sm) {
            max-height: 150px;

            h1 {
                font-size: 14px;
            }
        }

        &.restricted {
            @media screen and (height <= 840px) and (max-width: variables.$layout-breakpoint-sm) {
                max-height: 150px;

                h1 {
                    margin: 0;
                }
            }
        }

        & > div {
            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                margin: auto;
                padding: variables.$default-spacing;
            }

            &.settings-container {
                margin: variables.$default-spacing;
                padding: 0;

                md-icon {
                    color: variables.$white-color;
                    margin: 0 2px 2px 0;
                }
            }

            margin: 8px auto;
            color: white;

            h1 {
                text-align: center;
            }

            .md-button {
                background-color: white;
                margin: 6px variables.$default-spacing;
            }

            p {
                text-align: center;
                margin-top: 0;
                font-size: 13px;
            }
        }

        .restricted-trial-actions {
            margin-top: variables.$default-spacing * 0.5;
        }
    }

    .features-container {
        .swipe-container {
            margin-top: 40px;
        }

        .swipe-wrap {
            @media screen and (height <= 600px) and (max-width: variables.$layout-breakpoint-sm) {
                h1 {
                    font-size: 18px;
                }
            }

            h1 {
                .sf-plan-flag {
                    vertical-align: middle; // This is needed to vertically align the chip with the title text
                }
            }

            & > div > div {
                text-align: center;
                padding: 0 (variables.$default-spacing * 0.5);

                img {
                    max-height: 300px;

                    @media screen and (height <= 700px) and (max-width: variables.$layout-breakpoint-sm) {
                        max-height: 160px;
                    }

                    @media screen and (height >= 700px) and (height <= 800px) and (max-width: variables.$layout-breakpoint-sm) {
                        max-height: 240px;
                    }

                    max-width: 90%;
                }
            }
        }

        .sf-carousel-indicators {
            text-align: center;
            margin: 16px;

            .sf-carousel-indicator {
                margin: 0 4px;
                width: 8px;
                height: 8px;
                display: inline-block;
                border-radius: 100%;
                background: #000;
                opacity: 0.2;

                &.active {
                    background: #007aff;
                    opacity: 1;
                }
            }
        }
    }
}

.upgrade-confirm-dialog {
    .list-container {
        margin-bottom: 14px; // = <ul> margin-top
        ul {
            font-size: 14px; // = font-size rest of dialog
        }
    }
}
