@use '@angular/material' as mat;

$sf-select-typography: mat.define-typography-config(
        $font-family: var(--default-font),
        $body-1: mat.define-typography-level(14px, 24px, 400),
);

$sf-select-theme: mat.define-light-theme(
                (
                        typography: $sf-select-typography
                )
);

.mat-mdc-select {

    &.inline-dropdown {
        width: auto;
        border: none;

        .mat-mdc-select-trigger {
            gap: 8px;
        }
    }

    &:not(.inline-dropdown) {
        .mat-mdc-select-arrow-wrapper {
            transform: none;
        }
    }

    .mat-mdc-select-placeholder {
        color: var(--sf-black);
    }
}

.mat-mdc-form-field-type-mat-select {
    .mat-mdc-form-field-infix.mat-mdc-form-field-infix.mat-mdc-form-field-infix.mat-mdc-form-field-infix.mat-mdc-form-field-infix {
        min-height: 0.8rem;

        padding-bottom: 0;
    }

    &.no-subscript .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

.mdc-menu-surface.mat-mdc-select-panel.mat-mdc-select-panel {
    padding: 0;
}
