eco-fab-speed-dial {

    .mat-icon {
        color: var(--sf-white);
    }
    .mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
        background-color: var(--sf-light-blue);
    }
}

.mat-mdc-tooltip.action-tooltip {
    padding: 4px 8px;
}
