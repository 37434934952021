@use "sass/core/variables";
@use "sass/core/utils";

#account-info .domain-alert {
    background-color: variables.$account-info-domain-alert-bg-color;
}

#account-info {
    > div.flex > span > md-list > md-list-item > button > div > div > span {
        margin-right: 0;
    }

    // Override for the custom fields subheader since it's part of the md-list
    md-list .md-subheader {
        @extend %no-select;

        @include utils.font-size(16);

        font-weight: 700 !important;

        .md-subheader-inner {
            flex: 1;
        }
    }

    md-list-item.sf-list-item-info {
        min-height: 36px;

        button.md-icon-button.hover-button {
            display: none;
            height: 18px;
            width: 18px;
            margin: 0;
        }

        :hover {
            button.md-icon-button.hover-button {
                display: inline-block;
            }
        }

        md-icon.extendable {
            margin-top: -6px;
        }
    }

    md-list-item {
        background-color: variables.$gray-color;

        &.user-disabled {
            sf-avatar,
            p {
                opacity: 0.25;
            }
        }
    }

    button.md-button {
        text-transform: none;
    }

    .contact-suggestion {
        #accept-suggestion {
            color: green;
        }

        #reject-suggestion {
            color: red;
        }
    }

    // also used on person info
    .info-entry-empty-state {
        color: variables.$secondary-color;
        padding: 0 variables.$default-spacing variables.$default-spacing;

        /*
        &.without-items {
            margin-bottom: 36px; // height of a button
        }

        &.without-actions {
            margin-bottom: 52px; // height of button that would be there
        }
*/
    }

    .compensate-for-button {
        margin-bottom: 16px; // height of button that would be there
    }

    .opportunity-menu {
        margin: -2px -17px 0 0;

        md-icon {
            margin-bottom: 4px;
        }
    }
}
