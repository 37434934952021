.assignee-item .md-list-item-inner.md-no-style {
    padding-left: 16px;
    padding-right: 16px;

    md-checkbox {
        margin-left: 6px;
        margin-right: 18px;
    }
}

.assign-buttons {
    display: block;
    text-align: right;
    margin: 0 16px 16px;
    padding-bottom: 8px;
}
