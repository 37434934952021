@use "sass/core/variables";

#choose-audience {
    sf-filters {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            width: 360px;
            border-right: 1px solid variables.$light-gray-color;
        }

        .entity-filter .filter-header {
            margin: 0;
        }

        overflow-y: auto;
    }

    form.sticky-form {
        min-height: 78px;
    }

    .md-subheader.white {
        background-color: variables.$white-color;
    }

    md-list md-list-item {
        p {
            line-height: 20px;
            font-size: 14px;
        }

        .md-list-item-inner {
            &.md-no-style {
                padding: 0 variables.$default-spacing;
            }

            md-checkbox {
                margin-left: 6px;
                margin-right: 22px;
            }
        }
    }
}
