@use '@angular/material' as mat;

$sf-dialog-typography: mat.define-typography-config(
        $font-family: var(--default-font),
        $headline-6: mat.define-typography-level(16px, normal, 700, $letter-spacing: normal),
        $body-1:  mat.define-typography-level(14px, normal, 400, $letter-spacing: normal),
);

.mdc-dialog__container {
    --mdc-dialog-supporting-text-color: var(--sf-black);

    .mdc-dialog__title::before {
        display: none;
    }
}

.sfx-dialog-container-unthemed {
    .mdc-dialog__container {
        .mdc-dialog__title {
            padding: 0;
            margin: 0;

            &::before {
                display: none;
            }
        }

        .mat-mdc-dialog-content.mdc-dialog__content {
            padding: 0;
            margin: 0;

            > :last-child {
                margin-bottom: 1em;
            }

            > :first-child {
                margin-top: 1em;
            }

            > :first-child:last-child {
                margin-top: unset;
            }
        }

        .mat-mdc-dialog-surface.mdc-dialog__surface {
            padding: 24px 24px 0;
        }

        .mat-mdc-dialog-actions.mdc-dialog__actions {
            margin-right: -24px;
            padding: 0 16px;
        }
    }
}

.sfx-dialog-container, .sfx-dialog-container-sm, .sfx-dialog-container-xs {

    &.sfx-dialog-container {
        width: 790px;
        .mdc-dialog__container {
            padding: 0;
        }
    }

    &.sfx-dialog-container-xs, &.sfx-dialog-container-sm {
        .mdc-dialog__container {
            padding: 0;
            min-width: 240px;

            .mdc-dialog__content, .mat-mdc-dialog-content {

                padding: 0 24px 16px;
            }
        }
    }

    &.sfx-dialog-container-xs {
        .mat-mdc-dialog-container {

            .mat-mdc-dialog-content.mdc-dialog__content {

                min-height: 100px;
            }
        }
    }

    &.sfx-dialog-container-sm {
        .mat-mdc-dialog-container {

            .mat-mdc-dialog-content.mdc-dialog__content {

                min-height: 140px;
            }
        }
    }

    .mdc-dialog__container {

        .mat-mdc-dialog-content.mdc-dialog__content,
        .mat-mdc-dialog-actions.mdc-dialog__actions {
            margin: 0;
            padding: 0 16px;
        }

        .mdc-dialog__actions.primary, .mat-mdc-dialog-actions.primary {
            background: var(--sf-dark-blue);

            .mdc-button, .mat-mdc-button {
                color: var(--sf-white);
            }
        }
    }
}

