@use "sass/core/variables";

md-card.sf-report-card {
    box-shadow: none;
    border: 1px solid variables.$light-gray-color;
    margin: 6px 0 0 6px;

    &.sf-report-card_small {
        height: calc(15vh - 24px);
        min-height: 100px;
        max-height: 200px;
        overflow: visible;
    }

    &.sf-report-card_large {
        position: relative;

        md-card-content {
            height: 100%;
            padding: 12px;
        }

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            height: calc(40vh - 24px);
            min-height: 260px;
            max-height: 450px;

            md-card-content {
                overflow: auto;
            }
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        overflow: auto;
    }

    // We do this to ensure the icons are properly aligned with the content inside the md-card
    md-card-content.scroll {
        overflow-y: scroll;
    }

    // We do this to ensure the icons are properly aligned with the content inside the md-card
    md-card-title.scroll {
        overflow-y: scroll;
    }

    .no-data {
        position: absolute;
        background-color: variables.$white-color;
        font-size: 22px;
        opacity: 0.7;
        z-index: 2;
        color: variables.$text-color;
        width: 100%;
        height: 100%;
        pointer-events: none;
        bottom: 0;
    }

    h1 {
        margin: 0;
        font-weight: 900;
        font-size: 18px;
        color: variables.$salesflare-color;
        display: inline-block;
    }

    h2 {
        margin: 0;
        font-weight: 600;
        color: variables.$salesflare-color;
        font-size: 14px;
    }

    h3 {
        margin: 0;
        font-size: 13px;
        text-align: end;
    }

    .difference-indicator {
        font-size: 13px;

        .negative {
            color: variables.$red-color;
        }

        .positive {
            color: variables.$insights-positive-color;
        }

        md-icon.negative {
            line-height: 23px;
        }

        md-icon.positive {
            line-height: 27px;
        }

        md-icon.inverse-negative {
            line-height: 27px;
        }

        md-icon.inverse-positive {
            line-height: 23px;
        }

        .inverse-negative {
            color: variables.$red-color;
        }

        .inverse-positive {
            color: variables.$insights-positive-color;
        }
    }

    md-card-title {
        flex: none;
        padding: 8px 16px;

        .md-headline {
            line-height: 22px;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 12px;
        }
    }
}

#insights-revenue .report-container {
    padding-bottom: 6px;
}
