@use "sass/core/variables";

$task-create-height: 48px; // height of list item aka task line
$task-create-padding: 0 (variables.$default-spacing * 0.5) 0 variables.$default-spacing;
$task-create-actions-width: 70px; // same as on a sf-task
$layout-breakpoint-mobile-m-min: 360px;
$layout-breakpoint-mobile-s-max: 359px;
$layout-breakpoint-mobile-l-min: 425px;

.taskCreate {
    margin: variables.$default-spacing 0 0 0;
    background-color: variables.$white-color;
    height: $task-create-height;
    padding: $task-create-padding;

    .plusIcon {
        margin-right: variables.$default-spacing;
        color: variables.$text-color;
        opacity: 0.9;
    }

    .description {
        border: 0;
        padding: 0;
        height: 100%; // Make input fill whole height
    }

    .accountSearchContainer {
        // There is a weird rule on md-list-items where a bunch of stuff gets margin-right: 4px
        // so we need to take that into account to align create with task

        margin-right: variables.$default-spacing * 0.5;

        .accountSearchIcon {
            margin-right: 0;
        }
    }

    // Reset a bunch of properties to match `create box`'s style
    .accountSearch {
        height: $task-create-height;

        md-autocomplete-wrap {
            height: $task-create-height;
            box-shadow: none;

            input {
                height: $task-create-height;
                line-height: normal;
                color: variables.$accent-color;
            }
        }
    }

    .assign-icon {
        margin-right: 4px;
        margin-bottom: 1px;
    }

    .create-assign {
        margin-right: 4px;
    }

    .task-assignee-0 {
        z-index: 2;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .task-assignee-1 {
        z-index: 1;
        margin: 0 0 0 -10px;
    }

    .no-display-assignee {
        display: none;
    }

    .remaining-assignees {
        color: variables.$blue-color;
        margin: auto auto auto 2px;
        cursor: default;
    }

    button.snooze-button {
        height: 36px;
        padding: 0 6px;

        @media screen and (max-width: $layout-breakpoint-mobile-s-max) {
            padding: 0 6px 0 5px;
        }

        @media screen and (min-width: $layout-breakpoint-mobile-m-min) {
            margin-right: variables.$default-spacing * 0.5;
        }

        md-icon.picked {
            @media screen and (max-width: $layout-breakpoint-mobile-l-min) {
                display: none;
            }
        }
    }

    @media screen and (max-width: variables.$layout-breakpoint-xs-max) {
        button {
            min-height: 36px;
            line-height: normal;
            margin: auto 0;
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        margin: variables.$default-spacing variables.$default-spacing 0 variables.$default-spacing;

        .createButtons {
            min-width: $task-create-actions-width;
        }

        .reminder-date-input {
            margin-left: variables.$default-spacing * 0.5;
            margin-right: variables.$default-spacing * 0.5;
        }

        button {
            min-height: 36px;
            line-height: normal;
        }
    }

    @media screen and (max-width: variables.$layout-breakpoint-sm) {
        box-shadow: none;
        border-bottom: 1px solid #cad3df;

        .accountSearch {
            height: $task-create-height - 1;
        }
    }

    @media screen and (max-width: variables.$layout-breakpoint-xs) and (min-width: $layout-breakpoint-mobile-m-min) {
        .accountSearch {
            width: 134px;

            input {
                font-size: 13px;
                padding: 0 0 0 variables.$default-spacing * 0.5;
            }
        }

        .snooze-button {
            font-size: 13px;
        }
    }

    @media screen and (max-width: $layout-breakpoint-mobile-s-max) {
        .accountSearchContainer {
            max-width: 120px;
        }

        .accountSearch {
            // On mobile-S the add button would get pushed of the screen
            // that's why ellipsing is needed
            input {
                font-size: 13px;
                text-overflow: ellipsis;
                padding-right: 0;
            }
        }
    }
}

.taskUpdate {
    margin: variables.$default-spacing 0 0 0;
}

.task-error-spacer {
    padding-top: 2px;
    margin: 0 variables.$default-spacing (variables.$default-spacing * 0.5) variables.$default-spacing;
    height: 14px;

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        margin: 0 variables.$default-spacing variables.$default-spacing variables.$default-spacing;
    }

    &.on-update {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            margin: 0 0 12px;
        }
    }

    .task-error-message {
        font-size: 12px;
        color: variables.$error-color;
        font-weight: 400;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    }
}
