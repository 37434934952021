.mat-mdc-form-field-hint {

    --mdc-typography-body1-line-height: normal;
    --mdc-typography-body1-letter-spacing: 0;
    letter-spacing: 0;

    &.hint-valid {
        color: var(--sf-green);
    }

    &.hint-error {
        color: var(--sf-red);
    }

    &.hint-warning {
        color: var(--sf-warning);
    }

    &:not(.hint-valid, .hint-error, .hint-warning) {
        color: var(--sf-light-blue);
    }
}