@use "sass/core/variables";

md-toast {
    &.error {
        .md-toast-content {
            background-color: variables.$toast-error-color;
        }
    }

    &.success {
        .md-toast-content {
            background-color: variables.$green-color;
        }
    }

    &.info {
        .md-toast-content {
            background-color: variables.$blue-color;
        }
    }

    &.warning {
        .md-toast-content {
            background-color: variables.$orange-color;
        }
    }
}

/* fix to show toast in bottom-right corner on smaller screens as well */

/* also needed for modal dialogs in add-ins */
@media (min-width: variables.$layout-breakpoint-xs) {
    md-toast {
        min-width: 288px + 16px;
        left: auto;
        right: 0;
        width: initial;
        max-width: initial;
        border-radius: initial;
        bottom: initial;
        padding: 8px;

        &.md-bottom {
            bottom: 0;
        }

        &.md-left {
            left: 0;
        }

        &.md-right {
            right: 0;
        }

        &.md-top {
            top: 0;
        }
    }
}
