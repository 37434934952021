@use "sass/core/variables";

// Actual dialog
md-dialog[id^="dialogContent_"].reorder-dashboards-dialog:not(.md-dialog-fullscreen) {
    // Make it so the height and width are not the big defaults
    min-height: 350px; // This allows showing just a bit more than 4 dashboards + top and bottom bar
    height: unset;
    width: 350px;
}

// Template used in the dialog
#reorder-dashboards-dialog {
    .as-sortable-placeholder {
        background-color: variables.$material-gray-color;
    }
}

// This needs to be global as it needs to apply when dragging as well.
// While dragging the list item is added at the end of the dom so not in #reorder-dashboards-dialog anymore.
.dashboard-list-item {
    // The sortable lib forces this to display block which messed up the layout so resetting here
    display: flex;

    .icon {
        // This vertically aligns the icon better with the text
        margin-bottom: variables.$default-spacing;
    }
}

// Hide the divider when dragging as it is only needed in the list itself.
.as-sortable-dragging {
    .dashboard-list-item {
        md-divider {
            display: none;
        }
    }
}
