@use "sass/core/variables";

sf-pivot-table {
    md-table-container {
        height: 100%;

        table.md-table {
            overflow: scroll; // Default is hidden which breaks position: sticky in Chrome

            tbody tr > :first-child {
                position: sticky;
                left: 0;
                background-color: variables.$white-color; // Needed to show sticky content over scrolling cells
            }

            thead tr > th {
                position: sticky;
                z-index: 1;
                top: 0;
                background-color: variables.$white-color; // Needed to show sticky content over scrolling cells
            }

            thead tr > :first-child {
                z-index: 2;
                left: 0;
                top: 0;
                background-color: variables.$white-color; // Needed to show sticky content over scrolling cells
            }
        }
    }
}
