md-switch {
    .md-container {
        width: 44px; // actual width
    }

    // Make it decently wrap to multiple lines when no space
    .md-label {
        white-space: normal;
        line-height: 1; // normal messed up the vertical alignment
    }
}

mat-slide-toggle {
    .mat-label {
        white-space: normal;
        line-height: 1; // normal messed up the vertical alignment
    }
}
