@use "sass/core/variables";
@use "shared";

sf-onboarding-questions {
    .clarifying-text {
        max-width: 500px;
    }

    .questionsForm {
        max-width: 500px;
        padding: variables.$default-spacing 0;

        md-input-container {
            margin: calc(#{variables.$default-spacing} / 2) 0;
        }
    }

    .md-button.md-raised {
        min-width: shared.$button-min-width; // reset to material since it looks better

        &:not([disabled]),
        &:not([disabled]):hover {
            background-color: variables.$blue-color;
            color: variables.$white-color;
        }
    }
}

.question-select-container {
    md-select-menu,
    md-select-menu md-content {
        max-height: 304px !important;
    }
}
