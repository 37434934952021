@use "sass/core/variables";

.self-select {
    .self-select-header {
        margin: 8px 16px;

        p {
            color: variables.$text-color;
            margin: 16px 8px;
            margin-bottom: 0;
            font-size: variables.$font-size-14 + px;
            line-height: normal;
        }
    }

    .self-select-content {
        margin-left: 16px;
        margin-right: 16px;
    }

    md-card {
        border: 1px solid variables.$light-gray-color;
        border-radius: 4px;
        box-shadow: none;
    }

    md-dialog-content {
        background-color: variables.$white-color;
    }

    md-dialog-actions .md-button {
        color: variables.$white-color;
    }
}
