@use "sass/core/variables";

.sf-icon-salesflare {
    color: variables.$salesflare-light-color;
}

.sf-icon-enriched:before {
    content: "\e000";
}

.sf-icon-close-date:before {
    content: "\e027";
}

.sf-icon-check:before {
    content: "\e039";
}

.sf-icon-bar-chart:before {
    content: "\e0a0";
}

.sf-icon-bar-chart {
    transform: rotate(90deg);
}
