@use "sass/core/variables";

sf-report-view {
    overflow: auto; // Make sure content scrolls and doesn't overflow our view component

    &.score-card {
        .chart-view .sf-chart-container {
            padding: 12px 0 0;
        }
    }

    .chart-view {
        display: flex;
        flex-flow: column;
        height: 100%;

        .report-empty-state {
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-size: 22px;
            opacity: 0.7;
            color: variables.$text-color;
            text-align: center;
            pointer-events: none;

            .report-empty-state--line {
                margin: 8px 32px;
            }
        }

        md-select {
            margin: 0 4px;

            md-select-value {
                border-bottom-color: transparent;
            }
        }

        .sf-chart-container {
            height: 100%;
            padding: variables.$default-spacing * 0.5;

            sf-scorecard {
                width: 100%;

                // Reset some md-card styles
                md-card {
                    border: none;
                    width: 100%;
                    height: auto;
                    min-height: 0; // override the min-height for the regular score card, since it needs to fit in a report view
                    min-width: 0; // override the min-width for the regular score card, since it needs to fit in a report view

                    md-card-title {
                        display: none;
                    }
                }
            }
        }
    }
}
