@use "sass/core/utils";
@use "common";

sf-activity-per-rep {
    @include common.report-card-reset;

    .activity-per-rep {
        .team-header {
            margin: 8px;
            height: 24px;
        }

        .table-row {
            padding: 8px;
        }

        md-card-title {
            .header-icon {
                margin: auto;
            }
        }

        md-card-content {
            padding-top: 3px;
        }
    }
}
