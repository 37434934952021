@use "sass/core/variables";

#refer-a-friend-dialog {
    .title-container {
        padding: (variables.$default-spacing * 0.5) variables.$default-spacing 0;

        h3 {
            color: variables.$salesflare-color;
        }
    }

    md-dialog-content {
        padding: 0 variables.$default-spacing (variables.$default-spacing * 0.5);
    }

    .refer-a-friend-card {
        max-width: 440px;
        text-align: center;
        padding: variables.$default-spacing;
        margin: (variables.$default-spacing * 0.5) 0;

        h2 {
            margin: 0 0 variables.$default-spacing 0;
        }

        md-icon.blue {
            color: variables.$blue-color;
        }
    }
}
