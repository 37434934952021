@use "sass/core/variables";

sf-report-edit {
    md-toolbar {
        .md-toolbar-tools {
            md-icon.blue:not([disabled]) {
                color: variables.$blue-color;
            }

            .report-toolbar-button {
                margin-right: variables.$default-spacing * 0.5;

                &.blue:not([disabled]) {
                    color: variables.$blue-color;
                }

                &[disabled] {
                    color: variables.$disabled-color;

                    md-icon:before {
                        color: variables.$disabled-color;
                    }
                }

                + sf-plan-flag {
                    margin-left: -(variables.$default-spacing); // negate the margin-right of the create button and the margin of the plan flag itself when we add a plan flag
                }

                md-icon:before {
                    color: variables.$blue-color;
                }

                div {
                    display: flex;
                    align-items: center;

                    span {
                        margin-left: variables.$default-spacing * 0.25;
                    }
                }
            }
        }
    }

    .sf-empty-state {
        margin: 0;
        padding-top: 96px;
        width: 100%;
    }

    // Add a visual vertical divider between report config, chart builder, etc and the filter component
    md-content > div {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            border-right: 1px solid variables.$light-gray-color;
        }
    }

    sf-filters {
        width: 360px;
    }

    .hidden-form-element {
        display: none;
    }

    .dashboard-filter-section {
        padding: variables.$default-spacing variables.$default-spacing 0 variables.$default-spacing;

        sf-dashboards-filter {
            .filter-container {
                padding: 0;
            }

            form {
                padding: variables.$default-spacing 0 0 0;
            }
        }

        @media screen and (min-width: variables.$layout-breakpoint-xs) {
            md-input-container.filter-time-on {
                width: 270px;
                padding: 0 variables.$default-spacing 6px 0;
            }
        }
    }

    .advanced-section {
        padding-bottom: variables.$default-spacing;

        .label {
            padding: variables.$default-spacing;
        }

        .advanced-options-container {
            .checkbox-container {
                padding: (variables.$default-spacing * 0.5) 0;

                md-checkbox {
                    margin: 0 (variables.$default-spacing * 0.5) 0 variables.$default-spacing;
                }
            }
        }
    }

    .filter-form {
        margin: variables.$default-spacing;

        .report-filter-link-container {
            margin-top: variables.$default-spacing;
        }
    }

    form.report-config-form {
        padding: variables.$default-spacing 0 0 0;

        .report-config-container {
            padding: 0 variables.$default-spacing;
        }

        md-input-container {
            .md-resize-wrapper {
                width: 100%;
            }
        }

        @media screen and (min-width: variables.$layout-breakpoint-md) {
            md-input-container.segment-by {
                width: 300px;
            }
        }

        @media screen and (max-width: variables.$layout-breakpoint-md) {
            md-input-container.segment-by {
                width: 240px;
            }
        }

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            md-input-container:not(.chart-dimension-autocomplete-container, .filter-time-on) {
                width: 300px;
                padding: 0 variables.$default-spacing variables.$default-spacing 0;
            }

            .report-config-form-input-column {
                margin-right: variables.$default-spacing;
            }
        }

        @media screen and (max-width: variables.$layout-breakpoint-sm) {
            md-input-container {
                margin: variables.$default-spacing 0;
            }
        }
    }
}
