@use '@angular/material' as mat;
@use 'palettes';

.mdc-switch {

    .mdc-switch__icon {
        display: none;
    }

    --mdc-switch-unselected-focus-state-layer-color: var(--sf-white);
    --mdc-switch-unselected-pressed-state-layer-color: var(--sf-white);
    --mdc-switch-unselected-hover-state-layer-color: var(--sf-white);

    --mdc-switch-unselected-focus-track-color: rgba(0,0,0, .38);
    --mdc-switch-unselected-hover-track-color: rgba(0,0,0, .38);
    --mdc-switch-unselected-pressed-track-color: rgba(0,0,0, .38);
    --mdc-switch-unselected-track-color: rgba(0,0,0, .38);

    --mdc-switch-unselected-focus-handle-color: var(--sf-white);
    --mdc-switch-unselected-hover-handle-color: var(--sf-white);
    --mdc-switch-unselected-pressed-handle-color: var(--sf-white);
    --mdc-switch-unselected-handle-color: var(--sf-white);

/*
    --mdc-switch-selected-focus-state-layer-color: var(--sf-white);
    --mdc-switch-selected-pressed-state-layer-color: var(--sf-white);
    --mdc-switch-selected-hover-state-layer-color: var(--sf-white);
*/

    --mdc-switch-selected-focus-track-color: var(--sf-blue-200);
    --mdc-switch-selected-hover-track-color: var(--sf-blue-200);
    --mdc-switch-selected-pressed-track-color: var(--sf-blue-200);
    --mdc-switch-selected-track-color: var(--sf-blue-200);

    --mdc-switch-selected-focus-handle-color: var(--sf-light-blue);
    --mdc-switch-selected-hover-handle-color: var(--sf-light-blue);
    --mdc-switch-selected-pressed-handle-color: var(--sf-light-blue);
    --mdc-switch-selected-handle-color: var(--sf-light-blue);
}

.mat-mdc-slide-toggle {

    .mdc-form-field {

        label {

            margin-left: 16px;

            font-size: 14px;
            color: var(--sf-muted-grey);
        }
    }
}

$sf-slide-toggle-typography: mat.define-typography-config(
        $font-family: var(--default-font)
);

/* Set angular material slide-toggle theme */
$sf-slide-toggle-primary: mat.define-palette(palettes.$sf-toggle-palette, 900, 900, 900);
$sf-slide-toggle-accent:  mat.define-palette(palettes.$sf-toggle-palette, 900, 900, 900);
$sf-slide-toggle-theme: mat.define-light-theme(
                (
                        color: (
                                primary: $sf-slide-toggle-primary,
                                accent: $sf-slide-toggle-accent
                        ),
                        typography: $sf-slide-toggle-typography
                )
);
