@use "sass/core/variables";
@use "sass/core/utils";

md-datepicker {
    .md-datepicker-input-container {
        width: 100%;
    }

    .md-datepicker-input {
        @include utils.font-size(14); // so it is the same as other inputs

        max-width: none; // make sure it stretches to 100%
    }
}

// catch 'clear icons' with or without error spacers for the md-datepicker
md-datepicker + div + md-icon.clear-date,
md-datepicker + md-icon.clear-date {
    color: rgb(0 0 0 / 54%);
    position: absolute;
    top: 9px;
    right: 16px;
    left: auto;
    cursor: pointer;
    font-size: 14px;

    &.campaign-start-date {
        top: 12px;
    }
}

.md-calendar {
    color: variables.$salesflare-color;
}

.md-calendar-day-header {
    color: variables.$salesflare-color;
}
