@use "sass/core/variables";

.fake-sticky {
    pointer-events: none; // since the fake sticky is outside the content we are scrolling, but does not appear so we allow scrolling the content beneath it
    background-color: variables.$white-color;
    position: absolute;
    width: calc(100% - 9px); // calc to avoid overlap with scrollbar
    z-index: 2;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);
    display: none; // do not do @include hide here since that is to forced for this
}
