@use "sass/core/variables";
@use "sass/core/utils";

#delete-import-dialog {
    .text {
        padding: 0 16px;
    }

    md-switch {
        .md-label {
            @include utils.font-size(16);

            @media screen and (max-width: variables.$layout-breakpoint-xs) {
                padding-left: 8px;
            }
        }
    }
}
