@use "sass/core/variables";

md-radio-button {
    &.checkmark-radio {
        .md-on {
            background-color: inherit;
            color: variables.$text-color;
            padding-top: 2px;
            padding-left: 4px;

            &:before {
                content: "\e039";
                font-family: salesflare !important; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                speak: none;
                -webkit-font-smoothing: antialiased;
            }
        }

        .md-off {
            border: none;
        }

        &.md-checked .md-on {
            transform: scale(2);
        }
    }
}

.md-checked md-radio-button.checkmark-radio .md-on {
    transform: scale(2);
}
