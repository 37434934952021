@use "sass/core/variables";

sf-tasks-completed {
    .list .md-subheader-inner {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            // Put the text vertically in the middle
            // row = 48px
            // text = 16px

            padding-top: variables.$default-spacing;
            padding-bottom: variables.$default-spacing;
        }

        // On mobile we want to center the text vertically as well in the 72px high block
        padding-top: variables.$default-spacing * 2.5;
        padding-bottom: variables.$default-spacing;
    }
}
