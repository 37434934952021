@use "sass/core/variables";

sf-tasks-account {
    #tasks-account {
        height: 100%;

        .today {
            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                margin: 0 0 0 variables.$default-spacing;

                h1 {
                    margin-left: 0;
                    margin-top: 0;
                }
            }
        }

        .list.today {
            margin-top: 0;
            margin-bottom: 0;
        }

        .list.completed {
            margin-top: 0;
        }

        .list {
            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                margin: variables.$default-spacing variables.$default-spacing (variables.$default-spacing * 2) variables.$default-spacing;
            }
        }
    }
}
