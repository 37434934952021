@use "sass/core/variables";

.customfields-pipeline-select {
    background-color: variables.$white-color;
    padding-right: variables.$default-spacing;
    padding-left: variables.$default-spacing;
    color: variables.$accent-color;

    > md-select {
        margin: variables.$default-spacing 0;
    }
}

#custom-fields-settings {
    .md-toolbar-tools {
        padding: 0 16px 0 0;
    }
}

#custom-fields-settings-list {
    md-list {
        margin-bottom: 100px;

        .md-subheader {
            font-size: 16px;
            font-size: 1.1429rem;
            font-weight: 700 !important;
        }
    }
}

.disable-button {
    color: variables.$red-color;

    &.field-disabled {
        color: variables.$blue-color;
    }
}

.field-disabled {
    sf-avatar,
    h1,
    p {
        opacity: 0.25;
    }
}

// Edit custom field dialog styling
#customFieldForm {
    .label {
        color: rgb(0 0 0 / 54%);
        font-size: 12px;
    }

    #customFieldOptions {
        display: inline-block;
        position: relative;

        md-icon {
            line-height: 0;
        }

        .option-field {
            display: flex;
        }
    }
}
