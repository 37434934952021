@use '@angular/material' as mat;

$sf-tab-typography: mat.define-typography-config(
        $font-family: var(--default-font),
        $body-1: mat.define-typography-level(14px, 24px, 500, $letter-spacing: .005em),
);

.sfx-toolbar[role=navigation] {

    .mat-mdc-tab-nav-bar {

        height: 100%;

        .mat-mdc-tab-links {

            height: 100%;
        }

        .mat-mdc-tab-link {

            height: 100%;

            --mat-tab-header-label-text-tracking: initial;

            .mdc-tab__ripple {

                display: none;
            }

            padding: 0;

            .mdc-tab__text-label {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }
    }

    &.mobile {

        .mat-mdc-tab-nav-bar {

            .mat-mdc-tab-link {

                &.active {

                    background-color: #111e34;

                    .mdc-tab__text-label {

                        color: var(--sf-white);

                        .salesflare.mat-icon {
                            color: var(--sf-white);
                        }
                    }
                }

                &:not(.active) {

                    background-color: #263143;

                    .mdc-tab__text-label {

                        color: rgba(255,255,255,.35);

                        .salesflare.mat-icon {
                            color: rgba(255,255,255,.35);
                        }
                    }
                }
            }
        }
    }


    &:not(.mobile) {

        .mat-mdc-tab-nav-bar {

            .mat-mdc-tab-link {

                .mdc-tab__text-label {

                    color: var(--sf-dark-blue);
                    font-size: 12px;

                    .salesflare.mat-icon {
                        color: var(--sf-dark-blue);
                        padding: 4px;
                    }
                }

                &.active {

                    .mdc-tab__text-label {

                        .salesflare.mat-icon {
                            background-color:  var(--sf-purple);
                            border-radius: 4px;
                        }
                    }
                }

                &:hover {

                    .mdc-tab__text-label {

                        .salesflare.mat-icon {
                            background-color:  var(--sf-dark-purple);
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }

    .mat-toolbar-row {

        padding: 0;
    }
}
