#selectaccount {
    overflow: visible;

    md-content {
        max-height: 320px;
        min-width: 280px;
    }

    h3 {
        margin-bottom: 16px !important;
    }

    md-list-item:last-child md-divider {
        display: block;
    }

    .create-new {
        margin-left: 4px;
        margin-right: 20px;
    }
}
