/*
 ng-sortable: Fix mobile touch scrolling 
*/
.as-sortable-item {
    -ms-touch-action: auto;
    touch-action: auto;
    -webkit-touch-callout: inherit;
}
.as-sortable-item-handle {
    -ms-touch-action: auto;
    touch-action: auto;
    -webkit-touch-callout: inherit;
}