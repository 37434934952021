@use "sass/core/variables";
@use "common";

sf-funnel-analysis {
    @include common.report-card-reset;
    @include common.common-report-card-classes;

    .funnel-analysis {
        background-color: transparent;

        .stage-bar {
            height: 24px;
            vertical-align: middle;
        }

        .no-days {
            display: none;
        }
    }

    .funnel {
        .stage-amount {
            width: 60px;
        }

        .stage-duration {
            width: 84px;
        }

        .flow-row {
            margin-left: 144px;

            .flow-indicator {
                line-height: 24px;
            }
        }

        @include common.css-chart-border-reset;
    }

    .stage-row {
        margin-top: 4px;
        font-size: 12px;
        color: variables.$salesflare-color;

        .win-rate {
            position: absolute;
            right: 8px;
        }

        .stage-name {
            position: absolute;
            margin-left: 8px;
            font-weight: 600;
        }
    }
}
