@use "sass:math";

// We use max-height so that we can animate elements that have no fixed height
// The trick is to give those elements a max-height that is way larger than the element will ever be
// Work around from http://stackoverflow.com/a/8331169/2339622

.ng-hide {
    // keep in sync with @mixin hide (utils.scss)
    opacity: 0;
    max-height: 0;
}

.easein {
    transition: all ease-in 0.5s;
}

.easein_fast {
    transition: all ease-in 0.2s;
}

// Don't animate when dragging and dropping, e.g. Predefined charts while reordering dashboards
.as-sortable-dragging {
    .easein-width,
    .easein-height {
        animation: none;
        max-width: none;
        transition: all 0s ease 0s;
    }
}

.easein-width {
    max-width: 0;
    animation: animateWidth 1s ease-in;
    animation-fill-mode: forwards;
}

@keyframes animateWidth {
    0% {
        max-width: 0;
    }

    100% {
        max-width: 100%;
    }
}

.easein-height {
    animation: animateHeight 1s ease-in;
    animation-fill-mode: forwards;
}

@keyframes animateHeight {
    0% {
        max-height: 0;
    }

    100% {
        max-height: 100%;
    }
}

.fade-in.ng-enter {
    transition: 0.75s linear;
    opacity: 0;
}

.fade-in.ng-enter.ng-enter-active {
    opacity: 1;
}

@keyframes CollapseLargeListItem {
    from {
        height: 72px;
    }
}

@keyframes FadeIn {
    0% {
        opacity: 0;
        transform: scale(0.1);
    }

    85% {
        opacity: 1;
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(480px);
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(480px);
    }

    100% {
        transform: translateX(0);
    }
}

.horizontal-sliding-list-item_out {
    animation: slideOut 1s forwards;
}

.horizontal-sliding-list-item_in {
    animation: slideIn 1s forwards;
}

md-list-item.large.collapse-list-item {
    min-height: 0; // Set min-height to 0 so we can animate on the height
    animation: CollapseLargeListItem 0.15s linear;
    height: 0;
}

.sliding-list-item {
    animation: FadeIn 0.6s linear;
    animation-fill-mode: both;
}

.sliding-list-item:nth-child(1) {
    animation-delay: 0s;
}

.sliding-list-item:nth-child(2) {
    animation-delay: 0.1s;
}

.sliding-list-item:nth-child(3) {
    animation-delay: 0.2s;
}

.sliding-list-item:nth-child(4) {
    animation-delay: 0.3s;
}

.sliding-list-item:nth-child(5) {
    animation-delay: 0.4s;
}

.sliding-list-item:nth-child(6) {
    animation-delay: 0.5s;
}

.sliding-list-item:nth-child(7) {
    animation-delay: 0.6s;
}

.sliding-list-item:nth-child(8) {
    animation-delay: 0.7s;
}

.sliding-list-item:nth-child(9) {
    animation-delay: 0.8s;
}

.sliding-list-item:nth-child(10) {
    animation-delay: 0.9s;
}

@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }

    2% {
        transform: rotate(-1deg);
    }

    3.5% {
        transform: rotate(1deg);
    }

    5% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.wiggle {
    animation: wiggle 5s infinite;
}

/* fireworks scss */
$particles: 100;
$width: 500;
$height: 500;
$box-shadow: ();
$box-shadow2: ();

@for $i from 0 through $particles {
    $box-shadow: $box-shadow, math.random($width) -$width * 0.5 + px math.random($height) - math.div($height, 1.2) + px hsl(math.random(360) 100% 50%); // stylelint-disable-line scss/no-duplicate-dollar-variables, scss/operator-no-unspaced

    $box-shadow2: $box-shadow2, 0 0 #fff; // stylelint-disable-line scss/no-duplicate-dollar-variables
}

.pyro {
    z-index: 101; // To assure the fireworks appear above any md-backdrop
}

.pyro > .before,
.pyro > .after {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    box-shadow: $box-shadow2;
    animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
    animation-delay: 1.25s, 1.25s, 1.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
}

@keyframes bang {
    to {
        box-shadow: $box-shadow;
    }
}

@keyframes gravity {
    to {
        transform: translateY(200px);
        opacity: 0;
    }
}

@keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }

    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }

    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }

    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }

    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}
