@use "sass/core/variables";
@use "sass/core/utils";

.sf-addin-dialog {
    height: 100%;
    opacity: initial;
    min-width: initial;
    max-width: initial;
    max-height: initial;
    position: initial;
    box-shadow: initial;

    .md-toolbar-tools {
        height: 64px;
        max-height: 64px;
    }
}

md-dialog,
.sf-addin-dialog {
    z-index: 82;

    md-toolbar {
        min-height: 52px;
        height: 52px;

        .md-toolbar-tools {
            height: 52px;
            max-height: 52px;
        }

        &:not(.md-menu-toolbar) md-icon {
            color: variables.$white-color;
        }

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            color: variables.$white-color !important;
            background-color: variables.$salesflare-color !important;
            flex: none;
            border-radius: 4px 4px 0 0;
        }
    }

    md-dialog-title {
        margin: 0 16px;
        color: variables.$salesflare-color;
    }

    md-dialog-content {
        color: variables.$salesflare-color;

        p,
        ul {
            margin-bottom: 0;
            font-size: variables.$font-size-16 + px;
        }

        h2.md-title {
            color: variables.$salesflare-color;
            font-weight: bold;
            font-size: variables.$font-size-16 + px;
            font-family: variables.$default-font;
        }

        h3 {
            margin: 16px 16px 0 !important;
        }

        .md-subheader .md-subheader-inner {
            font-size: variables.$font-size-14 + px;
        }
    }

    md-dialog-actions {
        .md-button,
        .md-button.md-default-theme.md-primary,
        .md-button.md-primary {
            min-width: 48px;
        }
    }

    &.md-content-overflow {
        .md-actions,
        md-dialog-actions {
            border: none;
        }
    }

    .hide-in-dialog {
        @include utils.hide;
    }

    .dialog-title {
        margin: 10px;
        text-align: center;
    }

    // Overwrite angular material margin-top
    md-dialog-content:not([layout="row"]) > :first-child:not(.md-subheader).dialog-title {
        margin-top: 10px;
    }
}

md-dialog[id^="dialogContent_"] {
    background-color: variables.$salesflare-color;

    &:not(.bulk-dialog, .edit-signature-dialog, .save-filter-dialog, .questions-dialog) {
        height: 80%;

        // Jodit fixed some padding issues which made the toolbar split into two lines
        // Increasing the width of the dialog to take the extra padding into account

        width: 790px;
    }

    &.bulk-dialog {
        min-height: 45%;
        width: 350px;
    }

    &.questions-dialog {
        width: 420px;
    }

    &.talk-to-us-dialog {
        width: 500px;
        height: auto;
    }

    &.you-can-book-me-dialog {
        max-width: 900px;

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            height: 80%;
        }
    }

    &.save-filter-dialog {
        height: auto;
        max-width: 500px;
        min-width: 350px;
    }

    &.export-fields-dialog {
        height: auto;
        max-width: 500px;
        min-width: 350px;
    }

    &.edit-signature-dialog {
        height: 60%;
    }

    &.choose-audience-dialog {
        max-width: 900px;
        width: 900px;
    }

    & > div {
        // fix for IE/Edge
        height: 100%;
    }
}

md-dialog[id^="dialogContent_"],
.sf-addin-dialog {
    md-dialog-actions {
        background-color: variables.$salesflare-color;
        padding-right: 14px;

        .md-button {
            color: variables.$white-color;

            &:disabled {
                color: variables.$grey-color;
            }
        }
    }
}

.date-dialog {
    overflow: visible;
}

.filter-dialog {
    width: 85%;
    max-width: 396px;

    md-dialog-content > md-input-container {
        margin-bottom: 18px;
    }
}

.dialog-content-form-spacing {
    margin: variables.$default-spacing variables.$default-spacing 6px variables.$default-spacing;
}

/* Reminder dialog */
#reminder {
    md-input-container[md-no-float] .md-errors-spacer {
        min-height: 0;
    }
}

/* DomainDialog */
#domain-dialog md-dialog-content h2 {
    margin-bottom: 16px !important;
}

/* Online Offline Dialog */
md-dialog-content#dialogContent_onlineOffline {
    display: flex;
    align-items: center;
}
