@use "sass/core/variables";

body.print {
    sf-dashboard {
        overflow: initial;
        background-color: variables.$bg-color;

        .grid-container {
            margin: 16px 16px 0;
            grid-gap: 16px;

            @media print {
                margin-left: 0;

                // make sure that right border is not cut off
                margin-right: 2px;
            }

            &.charts {
                display: inline-block;
            }

            .grid-item {
                &.chart {
                    height: auto;
                    min-height: auto;
                    max-height: none;
                    page-break-inside: avoid;
                    margin-bottom: 16px;
                }

                &.chart.canvas-chart {
                    height: 300px;
                }

                .report-container {
                    .title {
                        md-icon.sf-icon-info {
                            display: none;
                        }

                        md-menu {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

sf-dashboard,
sf-dashboard-edit {
    height: 100%;
    background: variables.$black-color;
    overflow: auto;
    background-color: variables.$list-bg-color;

    .grid-container {
        margin: 6px 6px 0;
        display: grid;
        grid-gap: 6px;

        &.charts {
            grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
        }

        .as-sortable-placeholder {
            background-color: variables.$material-gray-color;
        }

        &.score-cards {
            grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        }

        @media screen and (min-width: variables.$layout-breakpoint-md) {
            &.charts:not(.as-sortable-drag) {
                grid-template-columns: repeat(3, minmax(370px, 1fr));
            }

            &.score-cards:not(.as-sortable-drag) {
                grid-template-columns: repeat(6, minmax(140px, 1fr));
            }
        }

        .grid-item {
            &.chart {
                height: calc(40vh - 24px);
                min-height: 260px;
                max-height: 450px;
            }

            &.score-card {
                height: calc(15vh - 24px);
                min-height: 120px;
                max-height: 170px;
            }

            .report-container {
                box-shadow: none;
                border: 1px solid variables.$light-gray-color;
                width: 100%;
                display: flex;
                flex-flow: column;
                height: 100%;
                position: relative;

                .title {
                    padding: (variables.$default-spacing * 0.5) (variables.$default-spacing * 0.5) (variables.$default-spacing * 0.25) (variables.$default-spacing * 0.5);
                    flex: 0 1 auto;
                    width: 100%;
                    z-index: 5;

                    .md-headline {
                        line-height: 22px;
                        text-transform: uppercase;
                        font-weight: 800;
                        font-size: 12px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .drag-icon {
                        margin: 0 0 0 -8px; // This makes it visually have the same left padding as the title's original left padding.
                    }

                    md-icon {
                        margin-left: variables.$default-spacing * 0.25;
                        margin-right: -8px; // This makes it visually have the same right padding as the title's left padding.
                        color: variables.$text-color;

                        &.sf-icon-info {
                            margin-top: 4px;
                        }
                    }

                    md-menu {
                        margin-left: auto;
                    }
                }

                sf-report-view {
                    flex: 1 1 auto;
                }
            }
        }
    }
}
