@use 'sass/core/variables';
@use 'sass/core/utils';


#loading-bar-spinner {
    top: 50%;
    left: 50%;
    margin-top: 42px;
    margin-left: -18px;
}

#loading-bar-spinner .spinner-icon {
    width: 36px;
    height: 36px;
    border: solid 4px transparent;
    border-top-color: variables.$loading-bar-spinner-color;
    border-left-color: variables.$loading-bar-spinner-color;
    border-radius: 36px;
}

#loading-bar-spinner.ng-leave.ng-leave-active {
    @include utils.hide;
}
