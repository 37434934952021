@use "sass/core/variables";

#files {
    position: relative;

    md-progress-linear {
        margin-bottom: 2px;
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        .interaction {
            margin: 0 20px;
        }
    }

    md-list-item {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            border-right: {
                width: 1px;

                color: variables.$light-gray-color;

                style: solid;
            }

            border-left: {
                width: 1px;

                color: variables.$light-gray-color;

                style: solid;
            }
        }
    }

    .drag-overlay {
        pointer-events: none; // Needed in Chrome to prevent flickering when the drop zone contains child elements, see: https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
        position: absolute;
        opacity: 0.7;
        width: 100%;
        height: calc(100%); // Height of toolbar
        background-color: variables.$drag-overlay-background-color;
        border: 3px solid variables.$drag-overlay-border-color;

        // Z-index needed to top the z-index of the date headers in files
        z-index: 3;
    }

    .file-time {
        margin-right: variables.$default-spacing * 0.5;
    }

    .file-time-text {
        font-size: 12px;
    }

    .file {
        overflow: auto;
        padding: 0 0 0 16px;

        @media screen and (width >= 960px) {
            md-menu {
                visibility: hidden;
            }
        }
    }

    @media screen and (width >= 960px) {
        .file:hover md-menu {
            visibility: visible;
        }
    }

    .md-list-item-inner {
        width: 100%;
    }

    .lower-line {
        margin-right: 3.5px;
    }

    .file-menu-icon {
        color: variables.$text-color;
    }

    .file-button-icon {
        margin-right: 8px;
        color: variables.$kebab-menu-icon-color;
    }
}
