@use "sass/core/variables";

.feed-filter {
    // position in top right corner above all the other stuff
    position: fixed; // fixed instead of absolute so it doesn't scroll together with the rest
    margin-top: (48px - 24px) * 0.5; // (height of header - height of icon) // margin since this causes the isFiltered icon to be in the correct place vs padding
    z-index: 3;
    right: variables.$default-spacing * 1.5;

    // When we show the account info next to the feed we need a bigger right offset
    // I couldn't find another fix that both floats and offsets properly

    @media screen and (min-width: variables.$layout-breakpoint-sm-max) {
        right: (variables.$default-spacing * 1.5) + 360px;
    }

    md-menu-bar {
        padding: 0; // reset padding since we position using `right`
    }
}

#feed {
    .sf-avatar_face {
        width: 40px;
        height: 40px;
    }

    .interaction {
        background-color: variables.$white-color;
        border-bottom: 1px solid variables.$light-gray-color;

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            margin: 8px 0;
            border: 1px solid variables.$light-gray-color;
            border-radius: 4px;
        }

        md-list-item > md-icon:first-child {
            color: variables.$text-color;
            padding: 8px;
            margin-right: 16px;
            background-color: variables.$highlight-color;
            border-radius: 4px;
        }

        .sf-icon-conf-call {
            color: variables.$blue-color;
        }

        .icon.red {
            color: variables.$red-color;
        }

        .meeting-header__main {
            display: inline-block; // otherwise the person name will be forced on the next line
        }

        .interaction-detail {
            padding: 0 16px 16px;
        }
    }

    .interaction-detail.meeting {
        padding-bottom: 0; // meetings already have padding to the fact that they have an edit button

        .text {
            white-space: pre-line; // show new lines as actual new lines
            margin-top: 12px;
            margin-bottom: 12px;
            overflow: auto; // show horizontal scroll bar if needed
            padding-bottom: 5px; // make sure horizontal scroll bar does not stick to last line
        }
    }

    // Massive HACK to make sure that when we expand the notes of the reminder on click
    // The rest of the contents of the header of the interaction does not move only the notes expand downwards
    // See `AccountFeedController.showHideFullHeader`
    .reminder {
        .md-list-item-text {
            margin-top: 4px;
        }

        .sf-icon-reminder {
            margin-top: 19px;
        }
    }

    .team-update {
        p {
            padding: 0 20px;
            text-align: end;
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        md-list {
            padding-bottom: 20px;
        }

        .interaction:not(.message) {
            margin-left: 20px;
            margin-right: 20px;
        }

        .team-update {
            p {
                padding: 0 40px;
            }
        }
    }

    md-list-item {
        background-color: transparent;

        h1 {
            line-height: 24px;
        }
    }

    .empty-state {
        .company-empty-state-explainer-paragraph {
            margin-top: -8px;
        }
    }
}

/* mentions (from account feed) */
.mention {
    border-radius: 4px;
    padding: 1px 2px;
    color: variables.$white-color;
    background-color: variables.$green-color;

    a {
        color: variables.$white-color;
    }
}

.footer .sf-icon-send {
    position: absolute;
    right: 10px;
    bottom: 12px;
    color: variables.$salesflare-light-color;
    cursor: pointer;
}

// prevent mention from looking like one and being clickable on accounts
md-list-item .mention a {
    color: variables.$text-color;
    pointer-events: none;
}

/* end mentions */

#feed .message {
    border-radius: 4px;
    border-width: 1px;
    border-color: variables.$light-gray-color;
    border-style: solid;
    box-shadow: none;
    color: variables.$salesflare-color;

    /*
    margin-bottom: 16px;
    margin-top: 16px;
*/
    word-wrap: break-word; // make sure content does not bleed
    background-color: variables.$message-background-color;

    &.left {
        margin-left: 0;
    }

    &.right {
        margin-right: 16px;

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            margin-right: 20px;
        }
    }

    md-card-content {
        line-height: 22px;
        padding-bottom: 0;

        div {
            padding: 0;
        }
    }

    .message-time {
        margin: 0 20px variables.$default-spacing * 0.5 auto;
        color: variables.$text-color;
    }

    &.message__edit-box {
        // reset bg color
        background-color: variables.$white-color;

        > .md-button {
            width: 66px; // reset but still align
            margin-left: auto; // push it to the left
            border-bottom-right-radius: 12px;
        }

        > sf-message-box {
            padding-top: 16px;
            border-radius: 12px;

            // allow growing of the edit box
            height: 100%;
            min-height: 42px;

            > div {
                // make sure the editing doesn't overlap with the rounded corners
                margin-left: 4%;
                margin-right: 4%;

                // allow growing of the edit box
                height: 100%;
                min-height: 42px;
            }
        }
    }

    .md-menu {
        display: none;
        margin: 6px variables.$default-spacing * 0.5 auto auto;
    }

    &:hover {
        .md-menu {
            cursor: pointer;
            display: block;
        }
    }
}
