@use "sass/core/variables";

#edit-task {
    #taskForm {
        md-time-picker {
            padding-top: 2px;

            button.md-icon-button {
                display: none;
            }
        }

        .mobile-task-time {
            margin-bottom: variables.$default-spacing;
            margin-top: -(variables.$default-spacing);
        }
    }
}
