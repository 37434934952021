@use "sass/core/variables";
@use "sass/components/salesflare/predefined-reports/common";

#insights-revenue {
    background-color: variables.$gray-color;

    // Wrapper so we dynamically set the height of the chart's canvas
    .responsive-chart-wrapper {
        position: relative;
        height: 100%;
        padding: 0;
    }

    .funnel-analysis {
        background-color: transparent;

        .stage-bar {
            height: 24px;
        }
    }

    .funnel {
        .flow-indicator {
            line-height: 24px;
        }

        @include common.css-chart-border-reset;
    }

    .stage-label {
        position: absolute;
        right: 8px;
        margin-top: 4px;
        top: auto;
        font-size: 12px;
        color: variables.$salesflare-color;
    }

    .top-lost-reasons-chart,
    .top-lead-sources-chart {
        padding: 0;
        margin: 16px 0;

        .lost-reason-bar {
            background-color: variables.$red-color;
            height: 12px;
        }

        .lead-source-bar {
            background-color: variables.$insights-now-color;
            height: 12px;
        }

        @include common.css-chart-border-reset;
    }

    .negative {
        color: variables.$red-color;
    }

    .positive {
        color: variables.$insights-positive-color;
    }

    .revenue-vs-previous-period {
        height: 150px;

        .previous {
            background-color: variables.$insights-previous-color;
        }

        .now {
            background-color: variables.$insights-now-color;
        }

        .bar {
            position: relative;
            border-top: 0;

            > div {
                border-bottom: 0;
            }

            .bar-trend-label {
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                top: -20px;
                border-bottom: 0;
                border-top: 0;
            }
        }
    }

    .target {
        position: absolute;
        top: -14px;
        height: 60px;
        background-color: transparent;
        border-right: 2px solid variables.$salesflare-color;
        z-index: 2;
        border-bottom: 0;
        border-top: 0;
    }

    .caption {
        height: 20px;
        width: 20px;
        margin-right: 16px;
    }

    .caption-row {
        padding: 8px 0;

        > span {
            margin: auto;
        }

        .align-end {
            margin: auto;
        }
    }

    .won {
        background-color: variables.$insights-positive-color;
    }

    .expected {
        background-color: variables.$insights-expected-color;
    }

    .remaining {
        background-color: variables.$white-color;
    }

    .revenue-vs-goal {
        > div {
            height: 30px;
        }

        @include common.css-chart-border-reset;
    }
}
