@use "sass/core/variables";

#company {
    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        md-tabs.main-tabs {
            width: 249px;
            margin: auto;
        }
    }

    md-tabs.info-tab {
        width: 60px;
        position: absolute;
        right: 0;

        .md-tab {
            position: absolute;
            right: 0;
            cursor: pointer;

            md-icon {
                background-color: transparent;
            }
        }
    }

    #accountCarousel {
        background-color: variables.$white-color;
        text-align: center;

        > div {
            height: 56px;
        }

        .md-button md-icon {
            color: variables.$blue-color;
        }
    }
}
