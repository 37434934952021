@use "sass/core/variables";

md-select,
mat-select {
    md-select-value,
    mat-select-value {
        div {
            display: inline-block;

            &:first-child {
                overflow: visible !important;
                transform: none !important;
            }
        }
    }
}

// Temporary solution for md-select width. See issue https://github.com/angular/material/issues/8488
md-select-menu[multiple] {
    margin-left: 24px;
}

md-select-menu md-content md-option[selected] {
    md-icon {
        color: variables.$material-selected-color;
    }
}

// Reset text color of the selected option, was turning blue on select before
md-select-menu md-content md-option[selected],
md-select-menu md-content md-option[selected]:focus {
    color: variables.$reset-text-color;
}

md-optgroup md-option,
mat-optgroup mat-option {
    padding-left: 16px;
}
