// When anything updates here, please update it also here: https://salesflare.slack.com/archives/C07H0D3S4/p1642150553011200

:root {
    /*    Colors    */
    --sf-black: #0F1528;
    --sf-transparant-black: #07152C42;
    --sf-red: #f44336;
    --sf-green: #4caf50;
    --sf-dark-blue: #0F1528;
    --sf-light-blue: #0053FF;
    --sf-blue-200: #80c1ea;
    --sf-lighter-blue: #e1f5fe;
    --sf-orange: #FF6738;
    --sf-warning: #ffa500;
    --sf-dark-grey: #EEEEEE;
    --sf-purple: #f1f0f7;
    --sf-dark-purple: #eae8f1;
    --sf-muted-grey: #607087; // Maps to legacy sf text color
    --sf-disabled-grey: #00000042;
    --sf-light-grey: #FBFBFB;
    --sf-white: #FFFFFF;
    --sf-transparant-white: #fff;
    /*   Gradients  */
    --chip-bg-old-browsers: #ff512f;
    --chip-bg: linear-gradient(to right, #f09819, #ff512f);
}

