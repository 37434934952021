@use "sass/core/variables";

#restricted-dialog {
    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        width: 500px;
    }

    .text {
        padding: 0 16px;

        ul {
            padding-left: 18px;
        }
    }
}
