@use "sass/core/variables";
@use "sass/core/utils";

sf-plan-flag {
    .sf-plan-flag {
        display: inline;
        margin: auto (variables.$default-spacing * 0.5);
        font-weight: 700;
        padding-right: 10px;
        padding-left: 10px;
        cursor: pointer;
        text-align: center;
        float: unset; // unset float from material style so we can properly position the chip, usually on the right (material has a `float: left` which obv breaks that)

        // ui-gradient sunrise
        background: variables.$chip-bg-old-browsers;
        background: variables.$chip-bg;
        color: variables.$white-color;

        &.not-clickable {
            cursor: default;
        }

        @include utils.font-size(13);

        &.dialog-chip {
            vertical-align: text-bottom;
            cursor: default;
        }
    }
}
