.mat-mdc-snack-bar-container {

    --mdc-snackbar-supporting-text-color: white;

    &.success {
        --mdc-snackbar-container-color: var(--sf-green, inherit);

        .mdc-snackbar__surface {
            background: var(--sf-green, inherit);
        }
    }

    &.error {
        --mdc-snackbar-container-color: var(--sf-red, inherit);

        .mdc-snackbar__surface {
            background: var(--sf-red, inherit);
        }
    }

    &.info {
        --mdc-snackbar-container-color: var(--sf-light-blue, inherit);

        .mdc-snackbar__surface {
            background: var(--sf-light-blue, inherit);
        }
    }

}
