@use "sass/core/variables";

#downgrade-confirmation-dialog {
    max-height: 85%;

    .text {
        padding: 0 variables.$default-spacing;
        max-width: 500px;
    }

    md-icon:not(.info) {
        color: variables.$red-color;
        float: left;
        margin-right: 6px;
    }

    md-icon.info {
        margin-left: 4px;
        color: lightgrey;
        position: relative;
        bottom: 1px;

        .md-tooltip {
            height: auto;
        }
    }
}
