@use "sass/core/variables";
@use "common";

sf-slipping-deals {
    @include common.report-card-reset;

    .md-icon {
        margin-right: variables.$default-spacing * 0.5;
    }

    .slipping-deal {
        margin-bottom: variables.$default-spacing;
    }
}
