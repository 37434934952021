@use "sass:color";

// When anything updates here, please update it also here: https://salesflare.slack.com/archives/C07H0D3S4/p1642150553011200

/*    Colors    */
$salesflare-color: #0f1528;
$salesflare-light-color: #0053ff;
$text-color: #0f1528;
$secondary-color: #8e8e93;
$reset-text-color: rgb(0 0 0 / 87%); // use if you want to reset to black text
$white-color: #fff;
$soft-white-color: #fafafa;
$orange-color: #ff6738;
$browser-orange: #ffa500;
$pink-color: #f26d63;
$red-color: #f44336;
$dark-red-color: #b3312d;
$error-color: #d50000;
$blue-color: #0053ff;
$green-color: #4caf50;
$gray-color: #fbfbfb;
$dark-gray-color: #6d7684;
$purple-color: #f1f0f7;
$dark-purple-color: #eae8f1;
$gray-highlight-color: #eee;
$light-green-color: #1abc9c;
$dark-blue-color: $salesflare-color;
$light-gray-color: #cad3df;
$light-blue-color: #3cb6e3;
$black-color: #000;
$grey-color: grey;
$yellow-color: #f1c40f;
$bg-color: $gray-color;
$list-bg-color: $gray-color;
$highlight-color: $purple-color;
$hover-color: $dark-purple-color;
$subheader-color: $gray-color;
$salesflare-less-alpha-color: color.change($salesflare-color, $alpha: 0.9); // slight alpha to make the icons less busy
$button-color: $blue-color;
$button-bg-color: rgb(158 158 158 / 20%);
$toolbar-background-color: #263143;
$message-background-color: $purple-color;
$chart-border-color: #b0bfc2;
$resizer-color: #666;
$carousel-indicator-color: #617ba0;
$textcomplete-color: rgb(158 158 158 / 20%);
$warning-color: $browser-orange;
$loading-bar-spinner-color: #29d;
$search-input-color: rgb(245 248 255 / 6%);
$toolbar-h2-color: rgb(255 255 255 / 56%);
$tab-color: rgb(255 255 255 / 35%);
$tab-active-bg-color: #111e34;
$toast-error-color: darkred;
$timeline-header-border-color: #8bb6dc;
$timeline-header-bg-color: #dbe5f0;
$sidenav-bg-color: $salesflare-color;
$sidenav-color: #a3a9b1;
$sidenav-content-color: #b7babf;
$sidenav-divider-color: #4c5b71;
$sidenav-profile-h2-color: #b1b8c1;
$speeddial-tooltip-color: rgb(233 233 237);
$load-more-color: #ddd;
$disabled-bg-color: #eee;
$disabled-color: rgb(0 0 0 / 38%);
$toolbar-primary-bg-color: #2196f3;
$switch-bg-color: rgb(158 158 158 / 20%);
$accent-color: $salesflare-color;
$semi-accent-color: rgb(0 0 0 / 50%);
$bg-white-input-border-color: #e8e8e8;
$input-border-color: #c8cbcf;
$grid-border-color: #acbcbf;
$grid-bg-color: #f7f9fb;
$account-info-domain-alert-bg-color: #e0e0e0;
$select-color: rgb(0 0 0 / 73%);
$select-border-color: rgb(0 0 0 / 12%);
$file-zip-color: #3d83f8;
$file-word-color: #315fa0;
$file-excel-color: #247246;
$file-powerpoint-color: #d0472d;
$file-audio-color: #db4437;
$file-video-color: #e71c4f;
$forward-color: #9c27b0;
$message-icon-color: #ff5722;
$open-color: #00b050;
$email-icon-color: #ffc107;
$meeting-live-color: #3f51b5;
$phone-color: #4bae4f;
$meeting-color: #71a394;
$skype-color: #00aff0;
$list-item-secondary-icon-color: #bdbdbd;
$wifi-background-color: #c4c7cc;
$insights-positive-color: #97c626;
$insights-expected-color: #e0eebe;
$insights-previous-color: $timeline-header-bg-color;
$insights-now-color: #8bb6dc;
$material-gray-color: #e7e7e7;
$material-selected-color: rgb(3 169 244);
$twitter-reply-color: #1da1f2;
$twitter-retweet-color: #19cf86;
$twitter-like-color: #e81c4f;
$gmail-color: #d14836;
$office365-color: #d83b01;
$outlook-color: #0888e0;
$drag-overlay-background-color: rgb(232 240 254);
$drag-overlay-border-color: $toolbar-primary-bg-color;
$kebab-menu-icon-color: #0082d5;
$bulk-select-color: #e1f5fe;
$walkthrough-step-element-color: #2e9ee0;

// Standard report colors
$dashboard-palette-blue: #8bb6dc;
$dashboard-palette-light-blue: #dbe5f0;
$dashboard-palette-green: #97c626;
$dashboard-palette-yellow: #f1c40f;
$dashboard-palette-orange: #f4a261;
$dashboard-palette-red: #f44336;

/*   Gradients  */
$chip-bg-old-browsers: #ff512f;
$chip-bg: linear-gradient(to right, #f09819, #ff512f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

/*    Layout    */
$layout-breakpoint-xs: 600px !default;
$layout-breakpoint-xs-max: 959px !default;
$layout-breakpoint-sm: 960px !default;
$layout-breakpoint-sm-max: 1279px !default;
$layout-breakpoint-md: 1280px !default;
$layout-breakpoint-md-max: 1919px !default;
$layout-breakpoint-lg: 1920px !default;

// Used for min- or max-height
$layout-vertical-breakpoint-xs: 570px !default;

/*     Fonts     */
$font-size-11: 11;
$font-size-12: 12;
$font-size-14: 14;
$font-size-16: 16;
$font-size-18: 18;
$font-size-19: 19;
$font-size-22: 22;
$font-size-24: 24;
$font-size-36: 36;

/*    Defaults   */
$default-spacing: 16px;
$default-font: "Source Sans 3", sans-serif;

// When anything updates here, please update it also here: https://salesflare.slack.com/archives/C07H0D3S4/p1642150553011200
