@use "sass/core/variables";

#settings {
    width: 100%;

    md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
        margin: auto 0;
        margin-right: 16px;
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        width: 360px;
        flex: none;

        md-content {
            width: 360px;

            md-list-item {
                height: 70px;

                // TODO remove
                &.active {
                    background-color: variables.$highlight-color;
                }
            }

            md-icon {
                color: variables.$text-color;
            }
        }
    }

    md-list-item {
        .md-no-style,
        .md-no-style.md-button {
            p {
                @media screen and (min-width: variables.$layout-breakpoint-sm) {
                    line-height: 26px;
                }
            }
        }

        @media screen and (max-width: variables.$layout-breakpoint-sm) {
            md-icon {
                color: variables.$text-color;
            }
        }

        & > md-icon:last-child,
        .md-list-item-inner > md-icon:last-child {
            color: variables.$salesflare-color !important;
        }
    }
}
