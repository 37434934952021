@use "sass/core/variables";
@use "sass/core/utils";

.sf-status-bar {
    @include utils.font-size(16);

    background-color: variables.$blue-color;
    color: variables.$white-color;
    text-align: center;
    font-weight: 600;
    padding: 0 (variables.$default-spacing * 0.5) 0 0;
    max-height: 500px; // This is for animation purposes (see animations.scss)

    // enable for awesomeness
    // background: linear-gradient(180deg, $blue-color, #ff0090);

    @media screen and (max-width: variables.$layout-breakpoint-xs) {
        padding: variables.$default-spacing * 0.5;
    }

    &.error {
        background-color: variables.$dark-gray-color;
    }

    &.success {
        background-color: variables.$green-color;
    }

    &.warning {
        background-color: variables.$orange-color;
        color: variables.$white-color;
    }

    .spinner {
        display: inline-block;
        margin-right: variables.$default-spacing * 0.5;
        margin-left: variables.$default-spacing * 0.5;
    }

    .text-block {
        margin: 0;
        min-height: variables.$default-spacing * 2;
        line-height: 1.5;

        .link {
            color: variables.$white-color;
        }
    }

    .sf-icon-close {
        color: variables.$white-color;
    }
}
