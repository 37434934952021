@use "sass/core/variables";

#editPerson {
    #full-name-input {
        cursor: pointer;
        text-overflow: ellipsis;
        color: variables.$reset-text-color;
        border-bottom-color: rgb(0 0 0 / 12%);
        background-image: none;
    }

    .name-fields {
        background-color: aliceblue;
        margin-bottom: 16px;
        padding: 4px;
    }

    .short-input {
        width: 20%;
    }

    .md-toolbar-tools > .sf-icon-camera {
        padding: 0 6px;
    }

    .md-toolbar-tools > .md-button:last-child {
        margin-right: 0;
    }

    md-input-container.clickable {
        cursor: pointer;

        input {
            pointer-events: none;
        }
    }

    .email-hint {
        md-icon {
            margin-bottom: 2px;
        }
    }

    .find-email-info-icon {
        margin-left: 2px;
    }

    .find-email-button-wrapper {
        margin-top: 13px; // To visually align with the input field of the input container
        width: 60px;

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            width: 100px;
        }

        .md-button {
            height: 30px;
            line-height: 30px;
            width: 60px;

            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                width: 100px;
            }
        }

        md-progress-circular {
            margin: auto;
        }
    }
}
