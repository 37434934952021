#save-filter-dialog {
    md-input-container:not(.md-input-has-value) input:not(:focus) {
        color: inherit;
    }

    md-input-container.inline {
        position: absolute;
        top: 20px;
        left: 180px;
    }
}
