@use "sass/core/variables";
@use "sass/core/utils";

.social-feed {
    md-list-item,
    md-list-item.large,
    md-list-item.extra_large {
        height: 100%;

        &:last-child {
            padding-bottom: 8px;
        }

        sf-avatar {
            margin: 12px 16px auto 0;
        }

        .md-list-item-text {
            margin: 0 auto;
            padding-bottom: 0; // compensate for empty (if hidden) space by actions

            p {
                line-height: 24px;
            }

            .header {
                margin-right: 0;

                h1 {
                    margin-right: 4px;
                }
            }

            img {
                width: 100%;
                margin-top: 6px;
            }

            .retweeted {
                @include utils.font-size(12);

                margin: 2px 0;

                md-icon {
                    color: variables.$text-color;
                    margin-right: 0;
                }

                a {
                    color: variables.$text-color;
                }
            }

            &:hover {
                .actions {
                    visibility: visible;
                }
            }

            .actions {
                margin: 2px 0;
                visibility: hidden;

                a {
                    color: variables.$grey-color;

                    md-icon {
                        color: variables.$grey-color;
                    }
                }

                // use twitter colors here

                // reply
                .sf-icon-level-up:hover {
                    color: variables.$twitter-reply-color;
                }

                // retweet
                .sf-icon-retweet:hover {
                    color: variables.$twitter-retweet-color;
                }

                // like
                .sf-icon-heart:hover {
                    color: variables.$twitter-like-color;
                }
            }
        }
    }
}

// always show actions on a touch screen, no hover state there
// we need to be super specific to override above rule
.touch .social-feed {
    md-list-item,
    md-list-item.large,
    md-list-item.extra_large {
        .md-list-item-text .actions {
            visibility: visible;
        }
    }
}

.actions {
    .reply {
        line-height: 0.5em;
        margin-right: 9px;
        margin-bottom: -6px;

        md-icon {
            transform: rotate(270deg);
        }
    }

    .retweet {
        margin-right: 6px;
    }
}
