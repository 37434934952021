@use "sass/core/variables";

.sf-top-toolbar {
    .md-toolbar-tools {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            padding-left: 0;

            #tabs-filler {
                height: 100%;
            }

            .search-mode {
                margin-left: 11px;
                width: 100%;
            }

            .sf-icon-close {
                margin: 0;
            }
        }
    }
}
