@use "sass/core/variables";

ui-cropper {
    .loading {
        display: none;
    }
}

.cropArea {
    height: 100%;
    width: 100%;
    background-color: variables.$gray-color;
}
