/* stylelint-disable selector-class-pattern */
@use "sass:math";
@use "sass/core/variables";
@use "sass/core/utils";

.jodit-container {
    // Put the toolbar at the bottom
    display: flex;
    flex-direction: column-reverse;
    min-height: 38px; // same height as the toolbar
    position: relative; // make sure the formatting toolbar with md-panel is shown relative to the jodit container

    .jodit-toolbar__box {
        min-height: 38px; // same height as the toolbar
    }

    // Make blockquotes visible
    blockquote {
        @include utils.show;
    }

    // Make sure the attachments container is pushed to the bottom
    .jodit-workplace {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
    }

    .jodit-workplace .jodit-wysiwyg {
        // unset the height and overflow to allow us to scroll the whole workplace and not just the editor
        height: auto;
        overflow: visible;
    }

    &:not(.jodit_inline) { // this :not() is to get on the same or higher specificy level as Jodit's own css rules
        border: none;

        .jodit-wysiwyg,
        .jodit-placeholder {
            color: variables.$reset-text-color;
            padding: variables.$default-spacing;
        }

        .jodit-placeholder {
            @include utils.font-size(14);

            color: variables.$disabled-color;
            font-family: "Source Sans 3", sans-serif;
        }

        .jodit-wysiwyg table {
            width: auto; // Jodit by default sets the width of a table to 100%. We unset it here so signatures with tables display correctly.
            table-layout: inherit; // Jodit sets this to fixed which looks different then what the final result would be since browsers default is auto

            // I originally wanted to replace border with outline as border moves things around and outline doesn't.
            // But outline draws over border. So if you have a border color set you won't see it, which is not wanted.
            tr td,
            tr th {
                vertical-align: inherit; // Unset vertical align because it is different from the browsers thus making things look different
            }
        }

        .jodit-workplace {
            background-color: variables.$gray-color;

            // Make the workplace scrollable instead of the editor. This is mainly to accommodate the jodit-attachments-container
            overflow-y: auto;
        }
    }
}

.jodit-container > .jodit-toolbar__box > .jodit-toolbar-editor-collection,
.jodit-container > .jodit-toolbar__box > .jodit-toolbar-collection {
    margin-left: variables.$default-spacing * 0.5 !important; // Align with attachments container and extra options below, we have to use !important here since Jodit does as well
    margin-right: variables.$default-spacing * 0.5 !important; // Align with attachments container and extra options below, we have to use !important here since Jodit does as well

    // Styling for custom text buttons
    .jodit-toolbar-button_Preview,
    .jodit-toolbar-button_Send_test,
    .jodit-toolbar-button_source {
        padding-right: variables.$default-spacing * 0.5; // add spacing after drop down triangle so it doesn't hit the divider

        > button {
            padding-left: variables.$default-spacing * 0.5; // add some spacing on the left, right is not needed for preview and add on parent for merge field due to the drop down triangle
        }
    }

    .jodit-toolbar-button_Merge_field > button {
        padding-right: math.div(variables.$default-spacing, 3); // add some spacing between text and drop down triangle
    }

    .jodit-toolbar-button > button {
        background-color: variables.$gray-color; // without this rule, it uses the background color specified by the user agent which may vary
    }

    // don't show the triangle for list buttons (e.g. our custom merge field button)
    .jodit-toolbar-button__trigger {
        display: none;
    }

    // Use same coloring for text on buttons as the icons
    .jodit-toolbar-button__button {
        color: rgb(0 0 0 / 87%);
    }
}

// Fix hover styling
.jodit-container,
.jodit-popup-container {
    .jodit-toolbar-collection,
    .jodit-toolbar-editor-collection {
        .jodit-toolbar-button:not(.jodit-toolbar-input, [disabled]):hover {
            // reset background on button and a tag, a tag is more for toolbar lists
            background-color: initial;
            border-color: transparent;

            > button {
                background-color: initial;
            }

            // Still indicate hover by coloring the icon or text (and drop down triangle) blue
            > button > span,
            > button > span > svg,
            > button,
            > button > md-icon,
            > button > span > md-icon {
                color: variables.$blue-color;
                fill: variables.$blue-color;
            }

            > .jodit-toolbar-button__trigger { // the drop down triangle
                background-color: transparent;
                opacity: 0.4;
            }
        }
    }
}

// Jodit forced specificity by doubling down on :not, so we'll just do the same.
// Removing causes their grey background to show up.
.jodit-toolbar__box:not(:empty, :empty),
.jodit-statusbar {
    font-family: "Source Sans 3", sans-serif;
    background-color: variables.$gray-color; // reset background color to our default
    border: none; // get rid of ugly border
    box-shadow: initial; // disable shadow box
}

.jodit-ui-input,
.jodit-ui-input .jodit-ui-input__input,
.jodit-ui-button,
.jodit-ui-button .jodit-ui-button__text,
.jodit-ui-select,
.jodit-ui-textarea {
    font-family: "Source Sans 3", sans-serif;
}

.jodit-popup-container > .jodit-popup {
    // Set bottom and top so toolbar popouts (like list) are going up instead of down
    background-color: variables.$gray-color; // reset bg color
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);

    .jodit-popup__content {
        padding: 0;
    }

    // Make subheaders in toolbar button lists non clickable
    span.jodit-toolbar-button {
        margin: 0;
    }

    // Make subheaders in toolbar button lists non clickable
    span.jodit-toolbar-button[class*="jodit-toolbar-button_subheader_"] {
        pointer-events: none;
        cursor: default;
        background-color: variables.$gray-color;
    }
}

.jodit-toolbar-popup {
    // Set rules so toolbar popups are displayed above instead of below
    bottom: 100%;
    top: initial;
    margin-bottom: 10px;
    margin-top: 0;

    // Triangle should point down now
    .jodit-popup-triangle {
        top: initial;
        bottom: -5px;
        transform: rotate(225deg);
    }
}

.jodit-attachments-container {
    display: inline-block; // otherwise it (this container) doesn't get the height of the contents which is not handy when trying to set margins and stuff
    clear: both; // This makes sure that the attachments stay below everything in the editor. More specifically when you float an image left or right on the last line.
    // Make sure it doesn't touch the sides
    margin-left: variables.$default-spacing;
    margin-right: variables.$default-spacing;
    margin-bottom: variables.$default-spacing;

    md-icon:not(.sf-icon-close) {
        margin-right: variables.$default-spacing * 0.25;
    }
}

.jodit-source__mode {
    .jodit-attachments-container {
        display: none; // don't show attachments in html view because it's not really necessary and it messes with scrollbars
    }
}

.jodit-source {
    background-color: variables.$gray-color;
    overflow: hidden; // avoid double scrollbar, scrolling is done by ace_editor

    .ace_editor .ace_scroller {
        margin: 0 variables.$default-spacing;
    }

    // jodit only allows passing certain options to the ace editor and showPrintMargin isn't one of those options so we fix it this way
    .ace_print-margin {
        display: none;
    }
}

// Make the boxes in the table inserter look better
.jodit-form-inserter .jodit-form-table-creator-box .jodit-form-container > div.hovered:after {
    background-color: variables.$salesflare-color;
}

// Align the checkbox input in the link button popup with the text
.jodit-form input[type="checkbox"] {
    top: initial;
}

// Fix buttons looks to look more like our buttons
.jodit-ui-form .jodit-ui-button,
.jodit-dialog-box .jodit-dialog .jodit-dialog-footer .jodit-button {
    background-color: variables.$gray-color;
    color: variables.$blue-color;
    text-transform: uppercase;
    min-height: 0;
    min-width: 0;
    margin: 0;
    padding: 0;
    border: none;

    &:hover {
        background-color: variables.$button-bg-color;
        color: variables.$blue-color;
    }

    > span {
        @include utils.font-size(16);
    }

    > svg {
        @include utils.hide;
    }
}

.jodit-tabs .jodit-ui-button {
    &:hover:not([disabled]) {
        background-color: variables.$button-bg-color;
    }

    &:focus:not([disabled]) {
        outline: none;
    }

    &[aria-pressed="true"] {
        color: variables.$blue-color;
        background-color: variables.$gray-color;
    }
}

// Fix our custom button's style
.jodit-toolbar-button {
    border: none;

    md-icon {
        color: variables.$reset-text-color;
    }
}

// Color insert table cells more in Salesflare app theme
.jodit-form__inserter .jodit-form__table-creator-box .jodit-form__container > div > span.jodit_hovered {
    background: rgb(240 248 255);
    border-color: variables.$blue-color;
}

// Don't show arrow for ordered list popup menu. It contains unnecessary options and is buggy
.jodit-toolbar-button_ol .jodit-toolbar-button__trigger {
    display: none;
}

.jodit-popup {
    .jodit-toolbar-editor-collection {
        // Remove unwanted options from the edit hyperlink toolbar
        // jodit configuration allows us to modify these buttons but not to completely remove them
        .jodit-toolbar-button_file,
        .jodit-toolbar-button_brush {
            display: none;
        }
    }
}

.jodit-add-new-line {
    display: none;
}

// Override new Jodit colour
.jodit-toolbar__box:not(:empty) .jodit-toolbar-editor-collection {
    &,
    &:after {
        background-color: variables.$gray-color;
    }
}
