@use "sass/core/variables";

.md-panel #template-selection-panel {
    width: calc(100vw - 32px);
    max-width: 480px;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

#template-selection-panel {
    background: variables.$white-color;

    .panel-header {
        font-size: variables.$default-spacing;
        font-weight: 700;
    }

    .md-toolbar-tools {
        height: 48px;
    }

    .no-templates-found {
        text-align: center;
        padding-top: 20px;
    }

    .template-panel-content {
        height: 264px;

        h1 {
            font-size: variables.$default-spacing;
        }

        .center {
            padding: variables.$default-spacing;
            margin: auto;
        }
    }

    form {
        padding: (variables.$default-spacing * 0.5) variables.$default-spacing;

        md-input-container {
            margin: 0;

            .md-errors-spacer {
                display: none;
            }
        }

        .search-input {
            background-image: url("../../../images/sf-icon-search.png");
            background-repeat: no-repeat;
            background-position-x: 2px;
            background-position-y: (variables.$default-spacing * 0.5);
            text-indent: 20px;
            font-size: 14px;

            button {
                display: none;
            }

            md-icon {
                margin-left: auto;
                margin-top: 5px;
                color: variables.$text-color;
            }
        }
    }
}
