@use "sass/core/variables";
@use "sass/core/utils";

#user-benefits {
    height: 100%; // because safari/ie
    max-height: 600px; // so safari/ie doesn't stretch the whole thing
    background-color: variables.$salesflare-color;
    color: variables.$white-color;

    .benefit-image {
        max-width: 400px;
        width: 100%;
        margin: 30px 50px;

        @media screen and (max-width: variables.$layout-breakpoint-sm) {
            width: 90%;
            margin: 30px auto;
        }
    }

    .benefit-text {
        max-width: 400px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;

        @media screen and (max-width: variables.$layout-breakpoint-sm) {
            width: 90%;
        }

        h1,
        h2 {
            text-shadow: none;
            font-weight: 500;
        }

        h1 {
            @include utils.font-size(30);

            font-weight: 700;

            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                margin-bottom: 6px;
            }
        }

        h2 {
            @include utils.font-size(variables.$font-size-19);

            margin-top: 0;
            line-height: normal;

            @media screen and (max-width: variables.$layout-breakpoint-md) {
                @include utils.font-size(21);
            }
        }
    }

    .benefit-buttons {
        padding-left: 60px;
        padding-right: 60px;

        @media screen and (max-width: variables.$layout-breakpoint-xs) {
            padding-left: 0;
            padding-right: 0;
        }

        .md-button {
            margin-top: 20px;
            margin-bottom: 30px;
            width: 128px;
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-xs-max) {
        .self-select-container {
            max-width: 500px;

            .self-select-content .progress-container {
                min-height: 100%;
            }

            md-dialog-content {
                max-height: 496px; // so total height works (height user benefit dialog - top toolbar height - actions height)
            }
        }
    }
}
