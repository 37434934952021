@use "sass/core/variables";

#workflowComposeDialog {
    md-dialog-actions {
        padding-left: variables.$default-spacing;
        padding-right: variables.$default-spacing;

        button {
            &#add-steps-button {
                margin-left: 0;
            }
        }
    }
}

#workflowConfigForm {
    padding: 0;
}

sf-workflow-compose {
    background-color: variables.$gray-color;

    .workflow-config {
        margin: (variables.$default-spacing * 0.5) (variables.$default-spacing * 0.5) 0 (variables.$default-spacing * 0.5);
    }

    #email-compose {
        margin: -(variables.$default-spacing) variables.$default-spacing * 0.5 0;
    }
}
