@use '@angular/material' as mat;

$sf-form-field-typography: mat.define-typography-config(
        $font-family: var(--default-font),
        $body-1:  mat.define-typography-level(14px, 24px, 400),
);

.mat-mdc-form-field {

    // Some hacks to make 'fill' form field look like legacy.
    .mat-mdc-text-field-wrapper, .mat-mdc-form-field-focus-overlay {
        background-color: rgb(255, 255, 255, 0) !important;
    }

    /*
    .mat-mdc-form-field-infix {
        padding-top: 0;
        padding-bottom: 0;
        height: 16px;
        min-height: 16px;
    }

     */

    .mdc-text-field {
        padding: 0;
    }

    .mdc-text-field.mat-mdc-text-field-wrapper.mdc-text-field--no-label {

        .mat-mdc-form-field-infix {
            padding-top: 0;
            padding-bottom: 0;

            min-height: 32px;
        }
    }

    .mdc-text-field.mat-mdc-text-field-wrapper:not(.mdc-text-field--no-label) {

        .mat-mdc-form-field-infix {
            padding-top: 24px;
            padding-bottom: 0;
        }
    }

    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
        padding: 0;
    }

    .mdc-floating-label:not(.mdc-floating-label--float-above) {
        top: unset !important;
        bottom: -4px;
    }

    .mat-mdc-input-element {
        padding-top: 4px;
    }

    .mat-mdc-form-field-error {
        margin-left: -2px;
        letter-spacing: normal;
    }

    .mdc-text-field:not(.mdc-text-field--disabled, .mdc-text-field--invalid) .mdc-line-ripple {
        &::before {
            border-bottom-color: rgb(0 0 0 / 12%) !important;
        }
    }

    input.mat-mdc-input-element {
        color: var(--sf-black);
    }
}
