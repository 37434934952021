@use '@angular/material' as mat;
@use 'palettes';

$sf-checkbox-primary: mat.define-palette(palettes.$sf-checkbox-palette, 500);
$sf-checkbox-accent: mat.define-palette(palettes.$sf-checkbox-palette, 500);

$sf-checkbox-theme: mat.define-light-theme(
                (
                        color: (
                                primary: $sf-checkbox-primary,
                                accent: $sf-checkbox-accent
                        )
                )
);

.mat-mdc-checkbox, .mdc-checkbox {

    --mdc-checkbox-selected-checkmark-color: var(--sf-transparant-white);

    &.toolbar-checkbox {

        --mdc-theme-text-primary-on-background: var(--sf-light-blue);

        --mdc-typography-body2-text-transform: uppercase;
        --mdc-typography-body2-font-weight: 500;
    }

    &[disableripple] {

        .mdc-checkbox__ripple {
            display: none;
        }
    }
}
