@use "sass:math";
@use "src/sass/core/variables";

sf-workflow-step-action {
    .action-container {
        padding: 0 variables.$default-spacing;
        margin-top: -(variables.$default-spacing);
        min-height: 380px;

        &.no-padding {
            padding: 0;
        }

        &.fullscreen {
            // This is related to the max height of the joditEditor in emailActionCompose.js
            max-height: 500px;
            min-height: 380px;
        }

        .display-container {
            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                max-height: 400px;
            }
        }

        sf-email-action-compose {
            // We want the scroll bar to have the same spacing as the display view
            .jodit_container:not(.jodit_inline) {
                padding: variables.$default-spacing;

                .jodit_wysiwyg {
                    padding: 0;
                }

                // And the placeholder in the correct spot
                .jodit_placeholder {
                    padding: 0;
                }
            }
        }

        #email-compose {
            border-radius: 0 0 4px 4px;
            background-color: variables.$white-color;

            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                max-height: 500px;
            }
        }

        .message-view-top-section {
            .message-view-top-section__item {
                margin: 16px 0;

                .no-subject {
                    color: rgb(0 0 0 / 38%);
                }

                .label {
                    margin-right: 8px;
                    color: rgb(0 0 0 / 38%);
                }

                .reply-container {
                    md-checkbox {
                        margin-bottom: 0;

                        .md-label {
                            line-height: 1; // Attempt to vertically align the label with the checkbox
                        }
                    }

                    md-icon {
                        margin-left: math.div(variables.$default-spacing, 3);
                    }
                }
            }

            .message-view-top-section__error {
                color: variables.$error-color;
                margin-bottom: variables.$default-spacing * 0.5;
            }

            md-list#attachments-list {
                md-list-item {
                    padding-left: 16px;
                    height: auto;

                    md-chip {
                        .file-text {
                            md-icon {
                                margin-right: 4px;
                                margin-top: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
}
