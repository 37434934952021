@use "sass/core/variables";

sf-chart {
    height: 100%;

    // Wrapper so we dynamically set the height of the chart's canvas
    .responsive-chart-wrapper {
        padding: 0;

        // Prevent score cards from taking up the whole height which makes them look to low in the card
        // This impacts the view as we center the card anyway in the chart builder
        &:not(.score-card) {
            height: 100%;
        }
    }

    .no-data-container {
        height: 100%;

        .no-data {
            font-size: 22px;
            opacity: 0.7;
            color: variables.$text-color;
            text-align: center;
        }
    }
}
