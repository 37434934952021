@use "sass/core/variables";

sf-task-settings {
    .settingInput {
        max-width: 50px; // otherwise looks massive

        .md-errors-spacer {
            display: none; // hide for now
        }
    }

    .settingInputDescription {
        margin-top: variables.$default-spacing * 2;
    }
}
