@use "sass/core/variables";

/*
 * This class is for account/contact/company info list items (e.g. website, description)
 * The goal is to have smaller (in height) line items
 * We do this mainly by ressting the min-height requirement
 * The other stuff is mainly for aligning the icon
 */
md-list-item.sf-list-item-info,
md-list-item.sf-list-item-info .md-list-item-inner {
    height: 100%;
    min-height: 0;
    line-height: normal;
    background-color: variables.$gray-color;

    // layout-align-start-start
    -ms-flex-align: start;
    align-items: flex-start;
    align-content: flex-start;
    -ms-flex-line-pack: start;

    // reset IE min-height fix
    &:before {
        min-height: 0;
    }

    button {
        line-height: normal;
    }

    > md-icon:first-child {
        margin: 7px 16px 9px 0;
        color: variables.$text-color;

        // Fix for IE: need to set this because otherwise margin-right won't work if next element (div or span) has multiple lines
        display: table;
    }

    > div,
    > span {
        margin-top: 6px;
        margin-bottom: 6px;
    }
}
