@use '@angular/material' as mat;
@use 'palettes';

/* Set angular material toolbar theme */
$sf-progress-bar-primary: mat.define-palette(palettes.$sf-custom-palette, 100);
$sf-progress-bar-accent: mat.define-palette(palettes.$sf-custom-palette, 200);
$sf-progress-bar-warn: mat.define-palette(palettes.$sf-custom-palette, 300);

$sf-progress-bar-theme: mat.define-light-theme(
                (
                        color: (
                                primary: $sf-progress-bar-primary,
                                accent: $sf-progress-bar-accent,
                                warn: $sf-progress-bar-warn
                        )
                )
);
