@use "sass/core/variables";
@use "sass/core/utils";

.sf-pricing-switch {
    @media screen and (max-width: variables.$layout-breakpoint-xs) {
        margin-left: auto;
        margin-right: auto;
        width: 85%;
    }

    color: variables.$salesflare-color;
    text-align: center;
    max-height: 100%;
    -webkit-box-flex: 1;
    box-sizing: border-box;

    &.edit-plan {
        flex: 1 1 100%;
    }

    &.selected {
        border: black 2px solid;
    }

    &.left,
    &.right {
        width: 100%;
        border-radius: 4px;
        background-color: variables.$switch-bg-color;
        margin-bottom: variables.$default-spacing;

        &:not(.edit-plan) {
            width: 200px;
            height: 160px;
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        &.right {
            margin-left: 4px;
            margin-bottom: 0;
        }

        &.left {
            margin-right: 4px;
            margin-bottom: 0;
        }
    }

    .button-content {
        height: 100%;
        width: 100%;
    }

    .features {
        height: 100%;

        ul.feature-list {
            padding: 0 16px;
            list-style: none;
            color: variables.$salesflare-color;
            line-height: variables.$default-spacing * 1.5;
            margin: variables.$default-spacing 0;
            column-count: 1;
            white-space: normal;

            li {
                display: flex;
                align-items: center;
                text-align: left;
                height: 100%;
                text-transform: none;

                @include utils.font-size(12);

                md-icon {
                    color: variables.$text-color;
                }
            }

            li:before {
                font-family: salesflare;/* stylelint-disable-line font-family-no-missing-generic-family-keyword */
                content: "\e039";
                font-size: 23px;
                margin: 0 6px 0 0;
                color: green;
            }

            li:last-child:before {
                content: "...";
                font-size: 14px;
                font-weight: bold;
                margin: 0 12px 0 6px;
                color: initial;
            }
        }
    }

    .strikethrough:before,
    .strikethrough_1:before {
        color: variables.$error-color;
    }

    .pricing-info {
        margin: variables.$default-spacing;
    }

    .plan-pricing-text {
        @include utils.font-size(10);

        margin: 0;
    }

    .plan-pricing-amount {
        @include utils.font-size(22);

        margin: 0;
    }

    .plan {
        margin: 0;
    }
}
