@charset "UTF-8";

@use "sass/core/variables";
@use "sass/core/utils";

.material-icons {
    font-weight: normal;
    font-style: normal;
    font-size: variables.$font-size-24 + px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

.icon-12,
md-icon[md-font-icon].icon-12 {
    @include utils.font-size(12);

    width: 12px;
    min-width: 12px;
    min-height: 12px;
    height: 12px;
    line-height: 12px;
}

.icon-14,
md-icon[md-font-icon].icon-14 {
    @include utils.font-size(14);

    width: 14px;
    min-width: 14px;
    min-height: 14px;
    height: 14px;
    line-height: 14px;
}

.icon-16,
md-icon[md-font-icon].icon-16 {
    @include utils.font-size(16);

    width: 16px;
    min-width: 16px;
    min-height: 16px;
    height: 16px;
    line-height: 16px;
}

.icon-18,
md-list-item > md-icon:first-child:not(.md-avatar-icon).icon-18,
md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon).icon-18,
md-icon[md-font-icon].icon-18 {
    @include utils.font-size(18);

    width: 18px;
    min-width: 18px;
    min-height: 18px;
    height: 18px;
    line-height: 18px;
}

.icon-20,
md-icon[md-font-icon].icon-20 {
    @include utils.font-size(20);

    width: 20px;
    min-width: 20px;
    min-height: 20px;
    height: 20px;
    line-height: 20px;
}

.icon-22,
.md-list-item-inner > md-icon:first-child:not(.md-avatar-icon).icon-22,
md-icon[md-font-icon].icon-22 {
    @include utils.font-size(22);

    width: 22px;
    min-width: 22px;
    min-height: 22px;
    height: 22px;
    line-height: 22px;
}

.icon-21,
.md-list-item-inner > md-icon:first-child:not(.md-avatar-icon).icon-21,
md-icon[md-font-icon].icon-21 {
    @include utils.font-size(21);

    width: 21px;
    min-width: 21px;
    min-height: 21px;
    height: 21px;
    line-height: 21px;
}

.icon-26,
md-icon[md-font-icon].icon-26 {
    @include utils.font-size(26);

    width: 26px;
    min-width: 26px;
    min-height: 26px;
    height: 26px;
    line-height: 26px;
}

.icon-30,
md-icon[md-font-icon].icon-30 {
    @include utils.font-size(30);

    width: 30px;
    min-width: 30px;
    min-height: 30px;
    height: 30px;
    line-height: 30px;
}

.icon-34,
md-list-item > md-icon:first-child:not(.md-avatar-icon).icon-34,
md-icon[md-font-icon].icon-34 {
    @include utils.font-size(34);

    width: 34px;
    min-width: 34px;
    min-height: 34px;
    height: 34px;
    line-height: 34px;
}

.icon-36,
md-icon[md-font-icon].icon-36 {
    @include utils.font-size(36);

    width: 36px;
    min-width: 36px;
    min-height: 36px;
    height: 36px;
    line-height: 36px;
}

.icon-logo {
    @include utils.font-size(64);

    margin-top: 3px;
    width: 64px;
    height: 64px;
}

.icon-logo-login {
    @include utils.font-size(500);

    width: 500px;
    height: 500px;
}

.icon-logo-intro-screen {
    @include utils.font-size(300);

    width: 300px;
    height: 300px;
    margin: -48px;

    &:before {
        color: variables.$salesflare-color;
    }
}

.icon-retweet {
    font-size: 1.2em;
    width: 18px;
    min-width: 18px;
    min-height: 16px;
    height: 16px;
}

.icon-levelup {
    @include utils.font-size(22);

    height: 21px;
    min-height: 21px;
    min-width: 12px;
    width: 12px !important;
    margin-top: 4px;
}
