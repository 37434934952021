@use "sass/core/variables";
@use "sass/core/utils";

#set-targets .select-container {
    width: 70%;
    margin: 13px auto 0;
}

#set-targets {
    .target-input {
        padding-bottom: 10px;
        max-width: 120px;
        margin-top: 15px;

        span {
            font-size: variables.$font-size-18 + px;
            line-height: 30px;
            margin-right: 5px;
        }

        input {
            text-align: right;

            // since we right align the number we force show the spinner so it doesn't look weird
            // since the spinner only shows on hover and when not hovering shows a weird blank space in the input field
            &::-webkit-inner-spin-button {
                opacity: 1;
            }
        }
    }
}

.login-input > input {
    width: 100%;
}

.md-select-label {
    width: 100%;
    display: inline-flex !important;
}

.md-select-label span:first-child {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

md-select {
    width: 100%;
}

.md-select-icon {
    max-width: 18px;
}

md-select.message {
    padding-bottom: 0;
}

span.message {
    color: variables.$salesflare-light-color;
    font-size: 0.8em;
    display: block;
    padding-top: 4px;
    flex: 1;
    order: 3;
}

span.message > span {
    vertical-align: middle;
}

form,
[ng-form] {
    @extend %content-padding;

    padding-top: 16px;

    &.white-background {
        background-color: variables.$white-color;
    }

    // add extra space beneath md-chips
    & .form-section:last-child md-chips {
        margin-bottom: 50px;
    }

    .form-section {
        margin-bottom: 16px;
    }

    .form-section.avatar-section {
        margin-bottom: 24px;
    }

    .input-group {
        md-select {
            span {
                color: variables.$light-green-color;
            }
        }
    }

    .button-add {
        @include utils.font-size(14);

        text-transform: none;
        margin-top: -36px;
        margin-left: -2px;
        font-weight: 500;
    }

    md-input-container {
        input,
        md-select-value > span:not(.md-select-icon) {
            flex: 1;
        }

        input,
        md-select-value {
            color: variables.$reset-text-color;
        }

        md-checkbox .md-label {
            margin-top: -1px; // For done checkbox label on opps, not sure why it is needed but it is

            .info-label {
                color: rgb(0 0 0 / 38%);
                font-size: 12px;
            }
        }
    }

    // Color fixes for inputs, a sane person would use md-themes properly
    md-chips.md-default-theme .md-chips.md-focused,
    md-chips .md-chips.md-focused {
        box-shadow: 0 1px variables.$blue-color;
    }

    md-input-container.md-input-focused {
        label {
            color: variables.$blue-color !important;
        }

        input,
        md-select-value {
            border-color: variables.$blue-color !important;
            border-width: 0 0 1px !important;
        }
    }

    md-icon.sf-icon-close.button {
        color: variables.$red-color;
        margin: 24px 0;

        :before {
            vertical-align: bottom;
        }
    }
}

/* eslint-disable selector-type-no-unknown */
ui-view-ng-upgrade,
.cdk-overlay-container {
    form,
    [ng-form] {
        padding: 0;

        .form-section {
            margin: 0;
        }

        .form-section.avatar-section {
            margin: 0;
        }
    }
}
