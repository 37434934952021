@use "sass/core/variables";

#merge-accounts-dialog {
    md-dialog-content {
        padding: 0 variables.$default-spacing;
        max-width: 500px;

        #select-container {
            margin-top: variables.$default-spacing * 2;

            md-select {
                margin-top: variables.$default-spacing;
            }
        }
    }
}
