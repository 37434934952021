@use "sass/core/variables";

#support {
    #content {
        md-icon {
            margin-top: -2px;
            margin-right: 4px;
            color: variables.$blue-color;
        }
    }
}
