#manage_users md-list md-list-item {
    &.user-disabled {
        sf-avatar,
        p {
            opacity: 0.25;
        }
    }

    p {
        line-height: 20px;
    }

    .md-list-item-inner {
        &.md-no-style {
            padding: 0 16px;
        }

        md-checkbox {
            margin-left: 6px;
            margin-right: 22px;
        }
    }

    .md-toolbar-tools > .md-button:last-child {
        margin-right: 0;
    }
}
