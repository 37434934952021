@use 'sass/core/variables';


.shepherd-element {
    z-index: 81; // To prevent the step from being hidden by an md-backdrop
    max-width: 440px;
    background: transparent; // This is needed so the wiggle animation doesn't reveal the default white background color of the outer shepherd-element. The wiggle effect can't be put on this item directly because animations break Popper.

    &.small {
        max-width: 300px;
    }

    .shepherd-content, .shepherd-content .shepherd-header, &.shepherd-has-title[data-popper-placement] >.shepherd-arrow:before {
        background-color: variables.$walkthrough-step-element-color;
    }

    .shepherd-content {
        .shepherd-header h3, .shepherd-text, .shepherd-text a, .shepherd-footer .shepherd-button {
            color: variables.$white-color;
        }

        // Wiggle animation
        animation: wiggle 5s 5s linear infinite;
        -webkit-animation: wiggle 5s 5s linear infinite;
        -o-animation: wiggle 5s 5s linear infinite;
        -moz-animation: wiggle 5s 5s linear infinite;

        .shepherd-header {
            padding: variables.$default-spacing variables.$default-spacing (variables.$default-spacing * 0.5) variables.$default-spacing;

            h3 {
                font-family: variables.$default-font;
                font-size: 17px;
                font-weight: 800;
            }
        }

        .shepherd-text {
            white-space: pre-line; /* collapse WS, preserve LB */
            border-radius: 16px;
            font-family: variables.$default-font;
            padding: 0 variables.$default-spacing variables.$default-spacing variables.$default-spacing;

            a {
                text-decoration: underline;
                font-weight: 700;
            }
        }

        .shepherd-footer {
            padding: 0 variables.$default-spacing variables.$default-spacing variables.$default-spacing;

            .shepherd-button {
                background-color: transparent;
                padding: 0;
                font-weight: 800;
            }
        }
    }
}
