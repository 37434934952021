@use "sass/core/variables";
@use "sass/core/utils";

// Mobile menu buttons are generated outside the sf-workflow-details element
.mobile-workflow-menu-item {
    display: flex;
    align-items: center;
}

sf-workflow-details {
    md-toolbar {
        .md-toolbar-tools {
            .workflow-toolbar-button {
                margin-right: variables.$default-spacing * 0.25;

                &.blue:not([disabled]) {
                    color: variables.$blue-color;
                }

                &[disabled] {
                    color: variables.$disabled-color;

                    md-icon:before {
                        color: variables.$disabled-color;
                    }
                }

                md-icon:before {
                    color: variables.$blue-color;
                }

                div {
                    display: flex;
                    align-items: center;

                    span {
                        margin-left: variables.$default-spacing * 0.25;
                    }

                    // Duplicate icon visually sticks more to the corners so we offset it with a bit more spacing to make it visually align more with the other icons
                    &.duplicate-button-container span {
                        margin-left: variables.$default-spacing * 0.5;
                    }
                }
            }
        }
    }

    .sf-workflow-live-flag {
        margin: auto (variables.$default-spacing * 0.5);
        background-color: variables.$green-color;
        padding: 0 (variables.$default-spacing * 0.25);
        color: variables.$white-color;
        height: 24px;
        line-height: 24px;

        @include utils.font-size(12);

        &.inline {
            // inline-flex + align-items to vertically center the text
            display: inline-flex;
            align-items: center;
            float: unset; // unset float from material style so we can properly position the chip, usually on the right (material has a `float: left` which obv breaks that)
            height: 22px;

            &.dialog-chip {
                vertical-align: text-bottom;
                cursor: default;
            }
        }
    }

    .table-of-content {
        max-width: 360px;

        md-list-item {
            border: 1px solid #cad3df;
            border-radius: 4px;
            margin: 8px;
        }

        .table-item-title {
            md-icon {
                margin: auto 4px auto 0;
            }
        }

        .add-workflow-step-button {
            margin: 6px 8px;

            .sf-icon-add {
                margin-bottom: 3px;
            }

            &:not([disabled]) {
                .sf-icon-add {
                    color: variables.$blue-color;
                }
            }

            &[disabled] {
                .sf-icon-add {
                    color: variables.$disabled-color;
                }
            }
        }

        md-chip.sf-plan-flag {
            line-height: 1; // Aligns the pro chip with the text
        }
    }

    md-content {
        padding: variables.$default-spacing 0;

        .workflow {
            padding: 0 8px;
            margin: auto;
            max-width: 759px;

            @media screen and (max-width: variables.$layout-breakpoint-sm) {
                max-width: 100%;
            }

            h1 {
                font-size: 1.1429rem;
                letter-spacing: 0.03rem;
                font-weight: 400;
                line-height: 24px;

                > md-icon {
                    color: #3d4d65;
                }
            }

            .workflow-start-title {
                width: 100%;
                padding-left: variables.$default-spacing;
                background-color: white;

                @media screen and (max-width: variables.$layout-breakpoint-sm - 1) {
                    border-top: 1px solid variables.$light-gray-color;
                    border-bottom: 1px solid variables.$light-gray-color;
                    color: #3d4d65;
                }

                @media screen and (min-width: variables.$layout-breakpoint-sm) {
                    color: variables.$white-color;
                    background-color: variables.$salesflare-color;
                    flex: none;
                    border-radius: 4px 4px 0 0;
                    margin: 0;

                    md-icon {
                        margin: 0 (variables.$default-spacing * 0.5) 0 0;
                        color: variables.$white-color;
                    }

                    div {
                        color: variables.$white-color;
                    }
                }

                md-icon {
                    margin: 0 (variables.$default-spacing * 0.5) 0 0;
                }
            }

            .add-step-button {
                width: 200px;
                font-size: 17px;

                .sf-icon-add {
                    margin-bottom: 3px;
                }

                &:not([disabled]) {
                    .sf-icon-add {
                        color: variables.$blue-color;
                    }
                }

                &[disabled] {
                    .sf-icon-add {
                        color: variables.$disabled-color;
                    }
                }
            }

            .invalid {
                color: variables.$error-color;
            }

            md-divider.invalid {
                border-top-color: variables.$error-color;
            }

            sf-form-error-indicator {
                width: 100%;

                #formErrorIndicator {
                    border-radius: 0 0 4px 4px;

                    border-bottom: {
                        width: 1px;
                        style: solid;
                        color: variables.$light-gray-color;
                    }
                }

                .md-whiteframe-2dp {
                    box-shadow: none;
                }
            }

            .workflow-input-container,
            .workflow-config-step {
                md-input-container.inline-input {
                    margin: 0;
                    max-width: 48px; // Width to fit 3 digits
                    min-width: 40px;

                    input[type="number"] {
                        text-align: center;
                    }

                    input {
                        margin: auto 0;
                        padding: 0;
                    }

                    .md-errors-spacer {
                        display: none;
                    }
                }
            }

            .workflow-step-container {
                background-color: variables.$gray-color;
                padding: 0;

                sf-workflow-step {
                    padding-left: 0;
                    padding-right: 0;
                    margin-bottom: variables.$default-spacing;

                    @media screen and (min-width: variables.$layout-breakpoint-sm) {
                        margin-top: variables.$default-spacing * 2;
                    }
                }
            }
        }

        form {
            padding: 0;

            .md-datepicker-input-container {
                border: none;
            }
        }
    }

    // Also used in workflow steps
    .step-analytics-bar {
        display: flex;
        flex-wrap: wrap;
        background: variables.$white-color;
        min-height: 80px;

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            border-left: 1px solid variables.$light-gray-color;
            border-right: 1px solid variables.$light-gray-color;
        }

        // Top border is not needed as the toolbar is already a visual border
        border-bottom: 1px solid variables.$light-gray-color;

        .step-analytics-column {
            text-align: center;
            font-weight: 400;
            font-size: 18px;
            flex: 1 0;
            padding: (variables.$default-spacing * 0.5) 0;
            min-width: min-content;

            .analytic-text-wrapper {
                margin: auto variables.$default-spacing * 0.25;
            }

            &:not(.editing) {
                cursor: pointer;
                color: variables.$salesflare-color;
            }

            &:not(.editing):hover {
                color: variables.$blue-color;
            }

            &.editing {
                cursor: default;
            }
        }
    }
}
