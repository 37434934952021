:root {
    --default-font: "Source Sans 3", sans-serif;

    --font-size-11: 11;
    --font-size-12: 12;
    --font-size-14: 14;
    --font-size-16: 16;
    --font-size-18: 18;
    --font-size-19: 19;
    --font-size-22: 22;
    --font-size-24: 24;
    --font-size-36: 36;
}

@font-face {
    font-family: "salesflare";
    src:
            url('/fonts/salesflare.woff2?v=3') format('woff2'),
            url('/fonts/salesflare.woff?v=3') format('woff'),
            url('/fonts/salesflare.ttf?v=3') format('truetype'),
            url('/fonts/salesflare.svg#salesflare?v=3') format('svg');
    font-weight: normal;
    font-style: normal;
}

.text-black {
    color: var(--sf-black);

    .mdc-list-item.mdc-list-item {
        color: var(--sf-black);
    }

    .mat-mdc-menu-item.mat-mdc-menu-item {
        color: var(--sf-black);
    }
}

.text-transparant-black {
    color: var(--sf-transparant-black);

    .mdc-list-item.mdc-list-item {
        color: var(--sf-transparant-black);
    }

    .mat-mdc-menu-item.mat-mdc-menu-item {
        color: var(--sf-transparant-black);
    }
}

.text-red {
    color: var(--sf-red);

    .mdc-list-item.mdc-list-item {
        color: var(--sf-red);
    }

    .mat-mdc-menu-item.mat-mdc-menu-item {
        color: var(--sf-red);
    }
}

.text-green {
    color: var(--sf-green);

    .mdc-list-item.mdc-list-item {
        color: var(--sf-green);
    }

    .mat-mdc-menu-item.mat-mdc-menu-item {
        color: var(--sf-green);
    }
}

.text-dark-blue {
    color: var(--sf-dark-blue);

    .mdc-list-item.mdc-list-item {
        color: var(--sf-dark-blue);
    }

    .mat-mdc-menu-item.mat-mdc-menu-item {
        color: var(--sf-dark-blue);
    }
}

.text-light-blue {
    color: var(--sf-light-blue);

    .mdc-list-item.mdc-list-item {
        color: var(--sf-light-blue);
    }

    .mat-mdc-menu-item.mat-mdc-menu-item {
        color: var(--sf-light-blue);
        --mdc-list-list-item-label-text-color: var(--sf-light-blue);
    }
}

.text-dark-grey {
    color: var(--sf-dark-grey);

    .mdc-list-item.mdc-list-item {
        color: var(--sf-dark-grey);
    }

    .mat-mdc-menu-item.mat-mdc-menu-item {
        color: var(--sf-dark-grey);
    }
}

.text-muted-grey {
    color: var(--sf-muted-grey);

    .mdc-list-item.mdc-list-item {
        color: var(--sf-muted-grey);
    }

    .mat-mdc-menu-item.mat-mdc-menu-item {
        color: var(--sf-muted-grey);
    }
}

.text-light-grey {
    color: var(--sf-light-grey);

    .mdc-list-item.mdc-list-item {
        color: var(--sf-light-grey);
    }

    .mat-mdc-menu-item.mat-mdc-menu-item {
        color: var(--sf-light-grey);
    }
}

.text-white {
    color: var(--sf-white);

    .mdc-list-item.mdc-list-item {
        color: var(--sf-white);
    }

    .mat-mdc-menu-item.mat-mdc-menu-item {
        color: var(--sf-white);
    }
}
