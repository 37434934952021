@use "sass/core/variables";
@use "sass/components/salesflare/predefined-reports/common";

#insights-team {
    background-color: variables.$gray-color;

    .quota-per-rep {
        margin: 32px 0;

        .quota-met {
            background-color: variables.$insights-positive-color;
        }

        .quota-not-met {
            background-color: variables.$red-color;
        }

        .quota-bar {
            height: 12px;
        }

        .quota-target {
            height: 52px;
            position: absolute;
            top: -20px;
            background-color: transparent;
            border-right: 2px solid variables.$salesflare-color;
            border-bottom: none;
            border-top: none;
            text-align: end;
            color: variables.$salesflare-color;
            font-size: 12px;

            .target-number {
                margin-right: 4px;
            }

            > span {
                border-top: 0;
                border-bottom: 0;
                background-color: variables.$white-color;
            }
        }
    }

    .average {
        height: 40px;
        position: absolute;
        top: -14px;
        background-color: transparent;
        border-right: 2px solid variables.$salesflare-color;
        border-bottom: none;
        border-top: none;
        z-index: 1;
    }

    .closing-percentage-per-rep {
        margin: 16px 0;

        @include common.css-chart-border-reset;

        .closing-percentage-bar {
            background-color: variables.$insights-positive-color;
        }

        .positive {
            color: variables.$insights-positive-color;
        }

        .negative {
            color: variables.$red-color;
        }

        .difference-indicator {
            position: absolute;
            right: 0;
            top: -20px;
        }
    }

    .closing-percentage-per-rep-card {
        md-card-content {
            padding-top: 3px;
        }
    }

    .closing-percentage-chart {
        height: 60px;
        margin-bottom: 16px;

        .closing-percentage-bar {
            margin-right: 3px;
            margin-left: 3px;
            margin-top: 8px;

            &.low-range {
                background-color: variables.$red-color;
            }

            &.average-range {
                background-color: variables.$yellow-color;
            }

            &.high-range {
                background-color: variables.$insights-positive-color;
            }
        }

        .average-header {
            margin-bottom: 8px;
        }
    }

    .caption {
        height: 14px;
        width: 14px;
        margin-right: 16px;
        margin-top: auto;
        margin-bottom: auto;

        &.low-range {
            background-color: variables.$red-color;
        }

        &.average-range {
            background-color: variables.$yellow-color;
        }

        &.high-range {
            background-color: variables.$insights-positive-color;
        }
    }

    .top-lost-reasons-chart {
        padding: 0;
        margin: 16px 0;

        .lost-reason-bar {
            background-color: variables.$red-color;
            height: 12px;
        }
    }

    .activity-per-rep {
        .team-header {
            margin: 8px;
            height: 24px;
        }

        .table-row {
            padding: 8px;
        }

        md-card-title {
            .header-icon {
                margin: auto;
            }
        }

        md-card-content {
            padding-top: 3px;
        }
    }

    .slipping-deals {
        .md-icon {
            margin-right: 8px;
        }

        .slipping-deal {
            margin-bottom: 16px;
        }
    }
}
