@use "sass/core/variables";
@use "sass/core/utils";

#contacts {
    md-list {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            margin: 0 20px 20px;

            // Set a fixed width so layout wise the container always has the width of when it has 2 buttons
            md-list-item .md-list-item-inner .md-secondary-container {
                width: 92px;
            }
        }

        md-list-item {
            button.md-button:not([disabled]):focus {
                outline: none;
            }

            h2 {
                margin-top: 0;
            }

            .md-secondary {
                width: auto;
            }

            .account-link {
                width: 100%;
                text-transform: none;
                text-align: left;
                padding-left: 0;
            }
        }
    }

    md-toolbar .md-toolbar-tools {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            #tabs-filler {
                height: 100%;
                width: calc(100% - 180px - 24px - 24px - 24px - 24px); // fix for offsetting against the tabs, the tabs are not made to put something next to them (180 are the tabs, 24 for every button on the right)
            }

            .search-mode {
                margin-left: 11px;
                width: 100%;
            }

            .sf-icon-close {
                margin: 0;
            }
        }
    }

    .md-subheader {
        @include utils.font-size(16);

        &.top-header {
            .md-subheader-inner {
                margin-top: -20px;
            }
        }
    }
}

.new-contacts {
    .md-subheader-inner {
        padding-left: 0;
    }
}

span.send-email-wrapper {
    display: inline-block;
}

// To nullify some padding in a higher level
#mail-icon {
    padding-right: 0;
}

span.microcopy {
    float: left;
    clear: left;
    height: 10%;
}

span.inner {
    display: block;
}
