@use "sass/core/variables";
@use "sass/core/utils";

#personInfo {
    md-list-item.sf-list-item-info {
        min-height: 36px;

        button.md-icon-button.hover-button {
            display: none;
            height: 18px;
            width: 18px;
            margin: 0;
        }

        :hover {
            button.md-icon-button.hover-button {
                display: inline-block;
            }
        }
    }

    md-list-item {
        background-color: variables.$gray-color;
    }

    md-list .md-secondary-container {
        md-icon:last-child {
            margin-left: 8px;
        }

        button:not([disabled]) md-icon {
            color: variables.$blue-color;
        }
    }

    button.md-button {
        text-transform: none;
    }

    .workflow-list-item {
        // Set width to 0 so the content doesn't overflow outside of the list item when the name is too long
        // Without this it would start the ellipsis at the end of the list item which pushed the menu away
        .workflow-name {
            width: 0;
        }

        .workflow-date-container {
            width: 120px;
            text-align: end;

            md-icon {
                display: inline-block;
                margin-bottom: 4px;
            }

            .date {
                display: inline-block;
            }
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-md) {
        md-sidenav & md-toolbar md-icon.sf-icon-close {
            @include utils.hide;
        }

        .sf-icon-close {
            margin-right: 0;
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        md-toolbar {
            h1 {
                margin-right: 8px;
            }

            h2 {
                max-width: 170px;
            }
        }
    }

    md-icon.sf-icon-enriched:before {
        color: variables.$yellow-color;
    }

    // also used on person info
    .info-entry-empty-state {
        color: variables.$secondary-color;
        padding: 0 variables.$default-spacing variables.$default-spacing;

        &.without-items {
            margin-bottom: 36px; // height of a button
        }

        &.without-actions {
            margin-bottom: 52px; // height of button that would be there
        }
    }
}

md-dialog #personInfo {
    .sf-avatar_large {
        right: 50px;
    }
}
