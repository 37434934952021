@use 'sass/core/variables';


.column-chart, .bar-chart, .scatter-plot {
    min-height: 0;
}

.column-chart .chart-column, .bar-chart .chart-row, .column-chart [data-cp-size], .bar-chart [data-cp-size], .plot-container > * > * {
    border: none;
    border-top: 1px solid variables.$chart-border-color;
    border-bottom: 1px solid variables.$chart-border-color;
}

.left-radius {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.left-border {
    border-left: 1px solid variables.$chart-border-color !important;
}

.right-radius {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.right-border {
    border-right: 1px solid variables.$chart-border-color !important;
}

.left-radius_small {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.right-radius_small {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.bar-chart {
    background-color: variables.$gray-color;
}
