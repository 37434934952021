@use "sass/core/variables";
@use "sass/core/utils";

#reminder {
    background: variables.$white-color;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);

    .date {
        @include utils.font-size(12);

        color: variables.$semi-accent-color;
    }

    .custom-date-selector {
        background-color: variables.$disabled-bg-color;

        md-icon {
            margin-right: variables.$default-spacing;
        }
    }
}
