@use "sass/core/variables";
@use "shared";

$import-map-padding: 0;
$import-map-actions-width: 120px;

sf-import-map {
    // Full screen component
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 61; // z-index of sidenav is 60

    .import-map {
        padding: $import-map-padding;
        border-bottom: 1px;

        .infobox {
            padding: 16px;
            border: 1px solid #3d4d65;
            margin: 16px;
            background-color: #f1f2f6;
            border-radius: 4px;
            color: rgb(0 0 0 / 87%);
        }

        md-table-container {
            margin-bottom: 16px;
        }

        .md-button.md-raised {
            min-width: shared.$button-min-width; // reset to material since it looks better

            &:not([disabled]) {
                background-color: variables.$blue-color;
                color: variables.$white-color;
            }
        }

        .import-table-column {
            padding: 0 !important;
            min-width: 50px;

            .empty-select {
                color: variables.$blue-color;
            }
        }

        table.md-table td.md-cell {
            color: variables.$text-color;
            font-size: 12px;
        }

        table.md-table thead tr th.import-table-header {
            color: variables.$accent-color;
            font-weight: bold;
            font-size: 14px;
            width: 95%;
            border-color: rgb(0 0 0 / 24%);
        }

        .import-table-select {
            padding: 0;
        }

        table.md-table tbody tr td {
            min-width: 125px;
            max-width: 125px;
            white-space: nowrap;
        }

        table.md-table:not(.md-row-select) td.md-cell,
        table.md-table:not(.md-row-select) th.md-column {
            padding: 0 56px 0 0;
        }

        table.md-table td.md-cell:first-child,
        table.md-table th.md-column:first-child {
            padding-left: 12px !important;
            padding-right: 0;
        }

        table.md-table td.md-cell:first-child {
            border-right: 1px solid rgb(0 0 0 / 24%);
        }

        table.md-table th.md-column:first-child {
            font-size: 14px;
        }

        table.md-table td.md-cell:nth-child(2),
        table.md-table th.md-column:nth-child(2) {
            padding-left: 12px !important;
        }

        md-select {
            margin-top: 0;
            margin-bottom: 4px;
        }

        md-select-value {
            font-size: 14px;
            margin-right: 16px;
        }
    }
}
