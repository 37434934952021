@use "sass/core/variables";
@use "sass/core/utils";

#billingSettingsOverview {
    .md-padding {
        padding: variables.$default-spacing;
    }

    .billingInfoText {
        @include utils.font-size(16);

        ul {
            margin-top: 0;
        }

        p {
            margin: 0;
        }
    }

    .billingDiscountText,
    .billingPadding {
        padding-top: variables.$default-spacing;
    }

    .billingPadding_bottom_half {
        padding-bottom: variables.$default-spacing * 0.5;
    }

    .billingInfoSubheader {
        @include utils.font-size(16);

        font-weight: 500;
        padding: variables.$default-spacing 0;
    }

    .md-button.md-raised {
        margin-top: 8px;
        color: variables.$white-color;
        background-color: variables.$blue-color;
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        .sf-plans {
            padding-top: variables.$default-spacing * 1.5;
        }
    }

    .billingInfoList {
        margin-bottom: 0;
    }
}

.discount-warning-spacer {
    float: right;
    min-height: 34px;
    padding: 0;

    // Ensure the element always takes up space, even if empty
    width: 1px;

    @media (min-width: variables.$layout-breakpoint-xs) {
        min-height: variables.$default-spacing;
    }
}
