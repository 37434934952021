/* sf-badge */

@use "sass/core/variables";

sf-badge {
    position: absolute;

    &:not(.inline) {
        left: 2px;

        &.large {
            left: -5px;
        }
    }
}

sf-badge.wifi-background {
    color: variables.$wifi-background-color;
    z-index: 1;

    &:not(.inline) {
        left: 1px;
        top: -2px;

        &.large {
            left: -5px;
            top: -5px;
        }
    }
}

sf-badge.wifi-white-background {
    color: variables.$white-color;
    z-index: 0;

    &:not(.inline) {
        left: -1px;
        top: -2px;

        &.large {
            left: -8px;
            top: -5px;
        }
    }
}

sf-badge.wifi-front {
    color: variables.$salesflare-color;
    z-index: 2;

    &:not(.inline) {
        left: 1px;
        top: -2px;

        &.large {
            left: -5px;
            top: -5px;
        }
    }
}

.badge-border {
    display: inline-block;
    background-color: transparent;
    border-radius: 50%;
    height: 20px;
    position: relative;
    top: 12px;
    left: -30px;

    &:not(.no-margin-right) {
        margin-right: -20px;
    }
}

.md-button:hover {
    sf-badge.wifi-white-background {
        color: variables.$load-more-color;
    }
}
