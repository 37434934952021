@use '@angular/material' as mat;
@use 'utils';

// Uses subtitle 1 - but has issue with MDC not supporting typography tokens yet. (_list-theme.scss#109)
$sf-subheader-typography: mat.define-typography-config(
        $font-family: var(--default-font),
        $subtitle-1:  mat.define-typography-level(16px, normal, 400),
);

.mat-mdc-subheader {
    // For some reason, margin was hard-coded into subheaders. Only option is to use !important.
    margin: 0 !important;

    @extend %no-select;

    background-color: var(--sf-light-grey);
    color: var(--sf-black);

    padding: 16px;
}
