@use '@angular/material' as mat;

$sf-tooltip-typography: mat.define-typography-config(
        $font-family: var(--default-font),
        $caption: mat.define-typography-level(14px, 24px, 400),
);

.mdc-tooltip__surface {
        max-width: unset !important;
        padding: 8px !important;

        --mdc-plain-tooltip-container-shape: 16px;
        --mdc-plain-tooltip-container-color: var(--sf-dark-grey);
        --mdc-plain-tooltip-supporting-text-color: var(--sf-black);
}
