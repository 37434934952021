@use "sass/core/variables";

sf-you-can-book-me-form {
    .skip-button {
        height: 36px;
        margin: auto;
    }

    .ycb-remove-top-line {
        top: 2px;
        z-index: 1;
        border-top: 2px solid white;
        position: relative;
    }

    .ycb-frame-wrapper {
        height: 1255px;
        margin-bottom: variables.$default-spacing * 2;
    }

    // It takes a while for the booker to assign it's own height
    // Give it a min-height if it's not full screen

    .ycb-frame {
        width: 100%;
        border: 0;
        background-color: transparent;
        height: 100%;
    }
}
