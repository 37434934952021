@use "sass/core/variables";

sf-tasks {
    #tabs-filler {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            width: calc(100% - (90px * 3) - 24px - 24px - 24px); // fix for offsetting against the tabs, the tabs are not made to put something next to them (90 for each tab, 24 for every button on the right)
        }
    }

    // Add padding-bottom to the last task list on each task page to prevent the FAB button from covering some of the task action icons
    sf-tasks-upcoming,
    sf-tasks-today,
    sf-tasks-completed {
        md-list.list:last-of-type {
            padding-bottom: 80px;
        }
    }
}

.tasks .list {
    margin: variables.$default-spacing variables.$default-spacing (variables.$default-spacing * 2) variables.$default-spacing;

    @media screen and (max-width: variables.$layout-breakpoint-sm) {
        margin: 0;
    }
}
