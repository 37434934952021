@use "sass/core/variables";

md-menu-item {
    button,
    a {
        .md-secondary-container md-icon,
        md-icon,
        md-icon[class^="sf-icon"],
        md-icon[class*="sf-icon"] {
            color: variables.$blue-color;
        }
    }

    sf-avatar {
        margin: auto;
    }

    md-radio-button {
        margin-left: variables.$default-spacing;
        padding-left: 0;
        color: variables.$text-color;
    }

    button md-checkbox {
        margin-bottom: 0; // reset bottom margin to properly vertical center the checkbox in the menu item
    }

    // Due to https://github.com/angular/material/issues/8464
    // Reported at https://github.com/angular/material/issues/11519
    > .md-button {
        display: flex;
    }

    &.select-all-menu-item {
        min-width: 180px;
    }
}

md-menu-content {
    &.button-dropdown-menu {
        md-menu-item {
            min-height: 36px;
            height: 36px;

            .md-button {
                span,
                md-icon:before {
                    color: variables.$salesflare-color;
                    font-size: 14px;
                }
            }
        }
    }
}

md-menu {
    &.submenu {
        padding: 0;
        margin: 0;

        button {
            text-align: left;
            display: inline-block;
            border-radius: 0;
            margin: auto 0;
            font-size: 15px;
            text-transform: none;
            font-weight: 400;
            height: 100%;
            padding-left: 16px;
            padding-right: 16px;
            width: 100%;

            md-icon:first-child {
                margin: auto 16px auto 0;
            }
        }
    }

    &[disabled] {
        pointer-events: none;
        background-color: transparent;
        cursor: default;
        color: variables.$disabled-color;

        md-icon {
            color: variables.$disabled-color;
        }
    }
}
