@use "sass/core/variables";

md-icon {
    line-height: 1;
    flex: none;
}

md-icon[md-font-icon] {
    line-height: 1;
}

md-icon[disabled] {
    color: variables.$secondary-color !important;
    cursor: default !important;
}

md-icon.md-default-theme,
md-icon {
    color: variables.$text-color;
}
