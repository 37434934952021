@use "sass/core/variables";

sf-workflows {
    .toolbar-title {
        height: 100%;
        padding: 16px;

        h1 {
            line-height: 30px;
        }
    }

    .sub-toolbar {
        height: 48px; // This reserves space for the entity count so the bar doesn't jump while loading
    }

    .create-workflow {
        margin: 0 variables.$default-spacing 0 auto;
    }

    .workflow {
        border-bottom: 1px solid variables.$light-gray-color;

        &.workflow__header {
            height: 64px;

            .md-list-item-inner {
                width: 100%;
            }

            .md-secondary-container {
                width: 110px;
            }
        }

        button.md-button.md-icon-button {
            color: inherit;
            font-weight: 400;
            width: 55px;
            margin: 0;
        }
    }

    .md-button.md-icon-button {
        border-radius: 0;
    }

    .list {
        margin: 0 variables.$default-spacing (variables.$default-spacing * 2) variables.$default-spacing;
        padding-bottom: 80px;

        div {
            &.text {
                text-align: left;
            }
        }

        .md-list-item-inner div:not(:last-child, .md-secondary-container, [md-ink-ripple-checkbox]) {
            margin-right: 0;
        }

        .table-header {
            font-weight: 500;
        }
    }

    .failed {
        color: variables.$error-color;
    }

    .md-list-item-inner span.failed:not(:last-child) {
        margin-right: 0;
        vertical-align: middle;
    }
}
