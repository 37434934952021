@use "sass/core/variables";
@use "common";

sf-closing-percentage-per-rep {
    @include common.report-card-reset;

    .closing-percentage-per-rep {
        margin: variables.$default-spacing 0;

        @include common.css-chart-border-reset;
        @include common.common-report-card-classes;

        .closing-percentage-bar {
            background-color: variables.$dashboard-palette-green;
            border: none;
        }

        .difference-indicator {
            position: absolute;
            right: 0;
            top: -20px;
        }

        .average {
            height: 40px;
            position: absolute;
            top: -14px;
            background-color: transparent;
            border-right: 2px solid variables.$salesflare-color;
            border-bottom: none;
            border-top: none;
            z-index: 1;
        }
    }
}
