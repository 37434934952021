@use "sass/core/variables";

#template-manager-dialog {
    sf-email-display {
        max-height: 90%;
        overflow: scroll;
    }

    .empty-state {
        margin: auto;
    }

    .templates-list-section {
        .md-virtual-repeat-container {
            border-right: 1px solid variables.$light-gray-color;
        }
    }

    .template-view {
        padding: variables.$default-spacing variables.$default-spacing 0 variables.$default-spacing;

        .template-view-name,
        .template-view-subject {
            line-height: 30px;
            margin: 0 0 variables.$default-spacing * 0.5;
        }

        .template-view-name {
            font-size: 20px;
            font-weight: 500;
        }

        .template-view-subject {
            font-size: variables.$default-spacing;
            font-weight: 400;
        }

        .template-privacy {
            height: 20px;
            margin: 16px 0;

            md-icon {
                margin: 0 8px 0 0;
            }
        }
    }

    .template-section {
        md-checkbox {
            margin: variables.$default-spacing;
        }
    }

    form[name="vm.templateForm"] {
        padding: 16px 14px 0;

        .hidden-form-element,
        .md-errors-spacer {
            display: none;
        }
    }

    .sticky-form {
        border-right: 1px solid variables.$light-gray-color;
        padding: variables.$default-spacing * 0.5 variables.$default-spacing;

        md-input-container {
            margin: 0;

            .md-errors-spacer {
                display: none;
            }
        }

        .search-input {
            background-image: url("../../../images/sf-icon-search.png");
            background-repeat: no-repeat;
            background-position-x: 2px;
            background-position-y: variables.$default-spacing * 0.5;
            text-indent: 20px;
            font-size: 14px;

            button {
                display: none;
            }

            md-icon {
                margin-left: auto;
                margin-top: 5px;
                color: variables.$text-color;
            }
        }
    }
}
