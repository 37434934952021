@use "sass/core/variables";

$message-box-height: 42px;

sf-message-box {
    height: 100%;

    &.offset-md-fab {
        padding-left: 50px; // offset for placeholder to stay clear of event button
    }

    > div {
        line-height: $message-box-height * 0.5;
        overflow-x: hidden;
        overflow-y: auto;
        color: variables.$salesflare-color;

        // offset for placeholder to stay clear of send button but on the inner div so the scroll bar is still left of the sent button
        // 48px is enough for the send button but we take 90px to also make the placeholder stay clear of the FAB (especially on screen width 1024px)

        padding-right: 90px;

        &.layout-fill {
            height: 100%;
        }
    }
}
