@use "sass/core/variables";

.md-button.md-fab,
.md-button.md-fab:hover {
    background-color: variables.$salesflare-light-color !important;
}

.fab-menu-backdrop {
    background: linear-gradient(180deg, rgb(0 0 0 / 0%), rgb(51 51 51 / 100%));
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.fab-menu {
    bottom: 2px;
    position: absolute;
    align-items: flex-start;
    z-index: 20;

    .md-button.md-fab {
        border: 1px solid variables.$salesflare-light-color !important;
        box-shadow: none;
        width: 32px;
        height: 32px;

        md-icon {
            color: variables.$salesflare-light-color;
        }
    }

    .md-button.md-fab,
    .md-button.md-fab:hover {
        background-color: #f1f6ff !important;
    }

    span {
        margin: auto auto auto 16px;
        font-size: variables.$font-size-16 + px;
        font-weight: 600;
        color: variables.$white-color;
    }

    .tooltip {
        background-color: variables.$speeddial-tooltip-color !important;
        color: variables.$salesflare-color;
        margin-top: 6px;
        padding: 8px;
        border-radius: 16px;
        height: 16px;
        line-height: 16px;
    }
}

#mega-fab {
    position: absolute;

    /* align-items: flex-start; items pinned to the left do not use with md-mini */

    // puts FAB on top of sidenav (z-index 60) on 1280px + screen width
    @media screen and (min-width: variables.$layout-breakpoint-md) {
        z-index: 70;
    }

    md-fab-actions {
        position: fixed;
        right: 28px;
        bottom: 78px;

        div {
            float: right;
        }

        .md-fab-action-item {
            margin-bottom: 8px;
        }

        md-icon:before {
            color: variables.$white-color;
        }

        .tooltip {
            background-color: variables.$speeddial-tooltip-color !important;
            color: variables.$salesflare-color;
            margin-top: 4px;
            margin-right: 16px;
            padding: 8px;
            border-radius: 16px;
            height: 16px;
            line-height: 16px;
        }
    }
}
