@use '@angular/material' as mat;

$sf-card-typography: mat.define-typography-config(
        $font-family: var(--default-font),
        $headline-6: mat.define-typography-level(14px, 24px, 500),
        $body-1: mat.define-typography-level(14px, 24px, 400),
);

.mat-mdc-card .mat-mdc-card-content:first-child {
    padding-top: 0px;
}
