@use "sass/core/variables";

.md-button md-checkbox .md-label {
    display: block; // decently vertically aligns the label with the checkbox when wrapped by a button
}

// This prevents flickering when animating the ng-hide/ng-show transition of a md-checkbox to an sf-avatar
// Only added here so it doesn't break other hide/show animations
md-checkbox.ng-hide.ng-hide-animate {
    display: none !important;
}

sf-avatar.ng-hide.ng-hide-animate {
    display: none !important;
}

md-checkbox.md-checked .md-icon {
    background-color: variables.$blue-color;
}

// Make checkbox check mark color consistent
.md-checkbox-enabled[selected] .md-icon:after {
    border-color: variables.$white-color;
}
