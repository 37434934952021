@use "sass/core/variables";
@use "common";

sf-closing-percentage {
    @include common.report-card-reset;
    @include common.common-report-card-classes;

    .closing-percentage-chart {
        height: 60px;
        margin-bottom: variables.$default-spacing;

        .closing-percentage-bar {
            margin-right: 3px;
            margin-left: 3px;
            margin-top: variables.$default-spacing * 0.5;

            &:not([data-cp-size="0"]) {
                border: none;
            }

            &.low-range {
                background-color: variables.$dashboard-palette-red;
            }

            &.average-range {
                background-color: variables.$dashboard-palette-yellow;
            }

            &.high-range {
                background-color: variables.$dashboard-palette-green;
            }
        }

        .average-header {
            margin-bottom: variables.$default-spacing * 0.5;
        }
    }
}
