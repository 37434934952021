@use "sass/core/variables";

#credits {
    .credits-header {
        font-weight: 700;
        text-align: center;
        font-size: 46px;
        margin: 0;
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        .sf-credits {
            padding-top: variables.$default-spacing * 5;
        }
    }

    // Don't add margin to the first plan when showing them below each other
    @media screen and (max-width: variables.$layout-breakpoint-sm) {
        .sf-credits {
            .plan:first-of-type {
                margin-top: 0;
            }
        }
    }

    md-content {
        @media screen and (min-width: variables.$layout-breakpoint-xs-max) {
            padding: variables.$default-spacing * 2;
        }

        .vertical-align-center {
            margin: auto 0;
        }
    }
}
