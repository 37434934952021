@use "sass:color";
@use "sass/core/variables";

sf-dashboards {
    .sf-top-toolbar .md-toolbar-tools {
        padding: 0 variables.$default-spacing;

        .blue:not([disabled]) {
            color: variables.$blue-color;
        }

        sf-dashboards-filter {
            // Vertically align select and datepicker in toolbar
            md-select,
            md-datepicker {
                margin: auto 0 auto 4px;
            }

            md-datepicker {
                // Aligns the md-datepicker with the md-select
                line-height: 29px;
            }
        }

        // Align plan chip better with button
        sf-plan-flag {
            padding-bottom: 2px;

            sf-plan-flag {
                height: 36px;
                line-height: 36px;
            }
        }
    }

    md-toolbar .md-toolbar-tools .new-count-container {
        margin-top: 6px;
    }

    .dashboard-name-input {
        @media screen and (max-width: variables.$layout-breakpoint-sm) {
            margin: 0; // reset all the defaults as they were not made for in a toolbar
            margin-bottom: 6px; // vertically align the input a bit more, this is mostly visual due to the bottom border line

            .md-input {
                color: variables.$white-color;
                border-bottom-color: color.change(variables.$white-color, $alpha: 0.5);
            }
        }

        .md-errors-spacer {
            min-height: 0; // we don't want the error spacer to take up any space as it moves the input way up and we won't use it anyway
        }
    }

    md-select.dashboard-selector {
        margin: 0;
        width: 360px;
        font-size: variables.$default-spacing;
        font-weight: 700;

        &:not([disabled]):focus .md-select-value {
            border-bottom-color: transparent;
            color: variables.$text-color;
        }

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            margin-top: auto;
            margin-bottom: auto;
        }

        md-select-value {
            border-bottom-color: transparent;
            flex: none;
            max-width: 360px;
        }

        @media screen and (max-width: variables.$layout-breakpoint-sm) {
            width: 200px;

            &:not([disabled]):focus .md-select-value {
                color: variables.$white-color;
            }

            md-select-value {
                border-bottom-color: transparent;
                flex: none;
                max-width: 200px;
            }

            .md-select-icon {
                color: variables.$white-color;
            }
        }
    }
}

.dashboard-selector-options-container {
    md-option {
        width: 360px;
    }

    md-select-menu:not(.md-overflow) md-content {
        padding-bottom: 0;
        padding-top: 0;
    }

    md-option.create-option {
        padding-left: 12px; // This is less than the default as we need to compensate for the icon whitespace

        .create-option-label {
            display: inline-block;
            vertical-align: middle;
        }

        md-icon {
            margin-right: 4px;
        }
    }
}
