@use "sass/core/variables";
@use "common";

sf-revenue-vs-previous-period {
    @include common.report-card-reset;
    @include common.common-report-card-classes;

    .revenue-vs-previous-period {
        height: 150px;

        .previous {
            background-color: variables.$dashboard-palette-light-blue;
        }

        .now {
            background-color: variables.$dashboard-palette-blue;
        }

        .bar {
            position: relative;
            border-top: 0;

            > div {
                border-bottom: 0;
            }

            .bar-trend-label {
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                top: -20px;
                border-bottom: 0;
                border-top: 0;
            }
        }
    }
}
