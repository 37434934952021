@use "sass/core/variables";

.entity-count {
    padding: variables.$default-spacing;
    color: variables.$secondary-color;
    background-color: variables.$gray-color;

    // This makes it the same as sub headers, which means the entity count bar will be the same height which is nice
    line-height: 1.2em;

    &.contact-count {
        @media screen and (width >= 960px) {
            padding: variables.$default-spacing 20px;
        }

        padding: variables.$default-spacing variables.$default-spacing;
    }

    &.opportunity-count {
        @media screen and (width >= 960px) {
            padding: variables.$default-spacing 13px;
        }

        padding: variables.$default-spacing variables.$default-spacing;
    }

    &.campaign-count {
        @media screen and (width >= 960px) {
            padding: variables.$default-spacing variables.$default-spacing variables.$default-spacing variables.$default-spacing;
        }

        @media screen and (width <= 960px) {
            padding: variables.$default-spacing variables.$default-spacing (variables.$default-spacing * 0.5) variables.$default-spacing;
        }
    }

    &.today-task-count {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            margin: 0 variables.$default-spacing (variables.$default-spacing * 2) 0;
        }

        margin: 0 variables.$default-spacing (variables.$default-spacing * 1.5) variables.$default-spacing;
        padding: 0;
    }

    &.task-count {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            margin: 0 variables.$default-spacing 0 0;
        }

        margin: variables.$default-spacing variables.$default-spacing 0 variables.$default-spacing;
        padding: 0;
    }
}

.below-entity-count {
    padding-top: 0;
    margin-top: 0;
}

.md-subheader {
    &.below-entity-count {
        padding-top: 0;
        margin-top: 0;

        // This is specifically for the 'New contacts' header
        .md-subheader-inner {
            padding-top: 0;
        }
    }
}
