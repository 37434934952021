@use "sass/core/variables";

#edit-picture {
    height: 100%;

    md-content {
        background-color: variables.$gray-color;

        /*
        * Calculate the available height of the md-content element
        * In fullscreen this equals 100% of the height of the parent dialog minus 116 px (= height of md-toolbar + height of md-dialog-actions)
        * On tablet and mobile resolutions this equals 100% of the height of the parent dialog minus 56 px (= height of md-toolbar)
        * This element needs a defined height else the canvas won't be rendered properly in Safari
        */
        height: -o-calc(100% - 56px);
        height: calc(100% - 56px);

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            height: -o-calc(100% - 116px);
            height: calc(100% - 116px);
        }

        .overlay-spinner {
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }

    .md-button > * {
        cursor: pointer;
    }

    label[for="file-input"] {
        color: variables.$white-color;
    }
}
