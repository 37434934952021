@use "sass/core/variables";
@use "shared";

#referral-settings {
    .scrollable-content {
        display: block;
        overflow-y: auto;
        position: relative;
        padding-bottom: 100px;

        .section-wrapper {
            height: 100%; // Needed to correctly show the virtual repeat
        }

        .referrals {
            height: 100%; // Needed to correctly show the virtual repeat

            .group-header {
                .referral-count {
                    margin-left: auto;
                    margin-right: 22px; // Align with delete icon
                }
            }

            .referral-program-section-content {
                height: 563px; // Virtual repeats need a set height. This height will show 10 referrals + the header
            }
        }

        .referral-program-section-content {
            &:not(.referees),
            .referees-text {
                margin: variables.$default-spacing;
            }

            &.no-margin-bottom {
                margin-bottom: 0;
            }

            sf-empty-state {
                h1 {
                    font-size: 16px; // Avoid too many different font sizes
                }
            }

            button.md-raised.md-ink-ripple {
                min-width: shared.$button-min-width; // reset to material since it looks better

                @media screen and (max-width: variables.$layout-breakpoint-xs) {
                    vertical-align: text-bottom; // get it on the same level as the input box otherwise it looks weird
                }

                &:not([disabled]),
                &:not([disabled]):hover,
                &:not([disabled]):active {
                    background-color: variables.$blue-color;
                    color: variables.$white-color;
                }
            }

            .social-media-button {
                height: 24px;
                width: 24px;
                position: relative;
                bottom: 3px; // Needed to align the icons correctly to the h3 text

                &.whatsapp {
                    height: 26px;
                    width: 26px;
                }
            }

            .personal-share-link-container {
                margin-top: variables.$default-spacing;
            }

            .refer-action {
                margin-bottom: 0;

                &.personal-share-link {
                    display: inline;
                }

                &.personal-share-code {
                    display: inline;
                }
            }

            .email-invite {
                margin-top: variables.$default-spacing * 0.5;

                md-input-container {
                    height: 53px; // Set fixed height to account for error messages

                    input {
                        width: 240px;
                    }
                }

                .md-button {
                    margin: 6px;

                    .personal-invite-button-text {
                        padding: 0 variables.$default-spacing * 0.5 0 variables.$default-spacing * 0.5;
                    }
                }
            }

            .cdk-virtual-scroll-viewport {
                .md-virtual-repeat-scroller {
                    height: 545px;  // Virtual repeats need a set height. This height will show 10 referrals + the header
                }

                .list {
                    .list-item {
                        padding-right: 0;

                        &.referral-header {
                            border-bottom: 1px solid variables.$light-gray-color;
                            font-weight: 500;
                            height: 64px;
                        }

                        div.referral-list-item-content {
                            color: variables.$accent-color;
                            margin-right: 0;

                            .md-button:not([disabled]):hover {
                                &.fake-button {
                                    background-color: transparent;

                                    p {
                                        text-decoration: underline;
                                    }
                                }
                            }

                            .fake-button {
                                font-weight: 400;
                                font-size: 14px;
                                color: variables.$text-color;
                                width: 100%;
                                text-transform: none;
                                text-align: left;
                                padding-left: 0;
                                cursor: default;

                                md-icon {
                                    &.info,
                                    &.check {
                                        position: relative;
                                        bottom: 2px;
                                    }

                                    &.info {
                                        color: variables.$text-color;
                                    }

                                    &.check {
                                        color: variables.$green-color;
                                    }
                                }
                            }
                        }

                        .received-status {
                            // mobile icons
                            md-icon {
                                &.info,
                                &.check {
                                    position: relative;
                                }

                                &.info {
                                    color: variables.$text-color;
                                    bottom: 1px;
                                }

                                &.check {
                                    color: variables.$green-color;
                                    bottom: 2px;
                                }
                            }
                        }

                        .referee-info-row {
                            h2 {
                                margin-top: 3px;
                                margin-left: 4px;
                            }
                        }

                        .md-secondary-container {
                            width: 55px;
                            margin-left: 0;
                            margin-right: 0;

                            button.delete-referral-button {
                                width: 55px;
                                margin: 0;
                                padding: 0;

                                @media screen and (max-width: variables.$layout-breakpoint-sm) {
                                    margin-top: -20px;
                                }

                                &.md-icon-button {
                                    @media screen and (min-width: variables.$layout-breakpoint-sm) {
                                        line-height: 0; // Centers icon in list item
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
