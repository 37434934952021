@use "sass/core/variables";

#applications-settings {
    padding-bottom: 30px;

    md-card {
        height: calc(100% - 16px);

        md-card-avatar {
            .logo {
                border-radius: 7px;
            }
        }

        md-card-footer {
            .md-button {
                font-size: variables.$font-size-14 + px;
            }
        }
    }

    .connected {
        color: variables.$green-color;
        opacity: 0.6;
    }

    .zapier-app-directory-container {
        padding: 0 variables.$default-spacing;

        // We use this to change the background color of the search fields
        // We can't style it with css selectors because the elements are encapsulated in a shadow root
        --zapier-bg-10: rgb(250 250 250);
    }
}
