@use "sass/core/variables";
@use "common";

sf-top-lead-sources {
    @include common.report-card-reset;

    .top-lead-sources-chart {
        padding: 0;
        margin: variables.$default-spacing 0;

        .lead-source-bar {
            background-color: variables.$dashboard-palette-light-blue;
            height: 12px;
        }

        @include common.css-chart-border-reset;
    }
}
