@use "sass/core/variables";

$task-padding: 0 (variables.$default-spacing * 0.5) 0 variables.$default-spacing;
$task-actions-width: 80px; // 3 x 40px (3 icons) -> updated to 80px

sf-task .task {
    padding: $task-padding;
    border-bottom: 1px solid variables.$light-gray-color;

    &.completed {
        .description {
            color: variables.$text-color;
        }

        .description,
        .accountContainer {
            text-decoration: line-through;
        }
    }

    // Container so that the padding of task container always get respected
    // If we don't use this, accounts won't align properly
    .reminder-date-container {
        text-align: right;
        margin-left: variables.$default-spacing * 0.5;
        margin-right: variables.$default-spacing * 0.5;
    }

    #reminder-date-container-id {
        text-align: right;
        margin-left: variables.$default-spacing * 0.5;
        margin-right: variables.$default-spacing * 0.5;
    }

    .task-assignee-0 {
        z-index: 2;
        margin-right: 0;
    }

    .task-assignee-1 {
        z-index: 1;
        margin-left: -10px;
        margin-right: 0;
    }

    .no-display-assignee {
        display: none;
    }

    .remaining-assignees {
        margin: auto auto auto 2px;
        cursor: default;
    }

    @media screen and (max-width: variables.$layout-breakpoint-xs-max) {
        button {
            max-height: 24px;
            line-height: normal;
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        button {
            min-height: 36px;
            line-height: normal;
        }
    }

    // Set width to 0 so contents does not overflow outside of the list item
    // when the description is too long
    // Without this it would start the ellipsis at the end of the list item
    // and then put the account and buttons behind it
    .md-list-item-inner {
        width: 0;
    }

    // Set a min width so width of flex stuff stays nice
    // The justify contents makes sure that the buttons stay on the right
    .md-secondary-container {
        min-width: $task-actions-width;
        justify-content: flex-end;
    }

    .conf-call-button {
        width: 24px;
        margin-right: -6px !important;
    }

    // To line out the edit icon correctly below other icons
    .sf-icon-edit {
        margin-left: 10px;
    }

    // The checkbox replaces the icon so we need to make sure it looks centered
    // The icon only has right margin
    $icon-right-margin: 16px;
    $checkbox-width: 20px;

    @function calculate-margin($icon-width) {
        @return ($icon-width - $checkbox-width) * 0.5;
    }

    md-checkbox {
        margin-left: calculate-margin(24px);
        margin-right: calculate-margin(24px) + $icon-right-margin;
    }

    &.md-2-line md-checkbox {
        margin-left: calculate-margin(34px);
        margin-right: calculate-margin(34px) + $icon-right-margin;
    }
}
