@use "sass/core/variables";

#api-key-settings {
    md-grid-list {
        padding-top: 16px;
        max-width: 430px;

        md-grid-tile {
            md-card-content {
                p {
                    margin: 0;
                }
            }

            md-card {
                md-card-footer {
                    .md-button {
                        font-size: variables.$font-size-14 + px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-xs) {
        md-grid-list {
            max-width: 860px;
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        md-grid-list {
            max-width: 430px;
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-md) {
        md-grid-list {
            max-width: 860px;
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        md-toolbar {
            .md-toolbar-tools {
                button {
                    color: variables.$blue-color;
                }
            }
        }
    }
}
