@use "sass/core/variables";

#date-time-panel {
    width: 360px;
    background: variables.$white-color;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);

    .calendar-container {
        margin: variables.$default-spacing * 0.5;
        margin-top: variables.$default-spacing + (variables.$default-spacing * 0.5);

        // since md-calendar doesn't want to be styled
        md-calendar > div:first-of-type {
            margin-top: variables.$default-spacing * 0.5;
            outline: 1px solid variables.$gray-color;

            .md-calendar-scroll-mask {
                .md-calendar-scroll-container {
                    box-shadow: none;
                }

                .md-virtual-repeat-scroller::-webkit-scrollbar {
                    display: initial;
                }
            }
        }
    }

    .time-container {
        margin-left: variables.$default-spacing * 0.5;
        margin-right: variables.$default-spacing * 0.5;
        margin-top: variables.$default-spacing * 0.5;
    }

    .action-container {
        padding-left: variables.$default-spacing * 0.5;
        padding-bottom: variables.$default-spacing * 0.5;
        padding-right: variables.$default-spacing * 0.5;
    }
}
