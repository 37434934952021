#set-targets {
    .scrollable-content {
        display: block;
        overflow-y: auto;
        position: relative;
        padding-bottom: 100px;
    }

    md-list-item {
        &.user-disabled {
            sf-avatar,
            app-avatar,
            p {
                opacity: 0.25;
            }
        }
    }
}
