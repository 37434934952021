@use "sass:math";
@use "sass/core/variables";

@mixin font-size($size, $important: null) {
    @if $important {
        font-size: $size + px + !important; /* stylelint-disable-line scss/operator-no-unspaced */
        font-size: math.div($size, variables.$font-size-14) + rem + !important; /* stylelint-disable-line scss/operator-no-unspaced */
    } @else { /* stylelint-disable-line block-closing-brace-newline-after, at-rule-empty-line-before */
        font-size: $size + px;
        font-size: math.div($size, variables.$font-size-14) + rem;
    }
}

@mixin letter-spacing($photoshop-letter-spacing) {
    letter-spacing: $photoshop-letter-spacing * 0.001 + rem;
}

@mixin hide {
    // from ng-hide angular
    display: none !important;

    // from animatons.scss
    opacity: 0;
    max-height: 0;
}

@mixin show {
    display: block !important;
    opacity: 1;
    max-height: initial;
}

@mixin flex {
    min-height: 0;
    min-width: 0;
    flex: 1;
    -webkit-box-flex: 1;
    box-sizing: border-box;
}

.no_select,
%no-select {
    -webkit-touch-callout: none;
    user-select: none;
    cursor: default;
}

// generate styles for attribute order so it gets prefixed
// use: <div order="1"></div>
[order="1"] {
    order: 1;
}

[order="2"] {
    order: 2;
}

[order="3"] {
    order: 3;
}

[order="4"] {
    order: 4;
}

[order="5"] {
    order: 5;
}

.uppercase {
    text-transform: uppercase;
}

.content-padding,
%content-padding {
    padding: 0 16px;
}

.ellipsis-2-line {
    white-space: initial;
    /* stylelint-disable-next-line value-no-vendor-prefix -- This specific display is needed for multiline ellipsis */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.ellipsis-5-line {
    overflow: hidden;
    white-space: initial;
    /* stylelint-disable-next-line value-no-vendor-prefix -- This specific display is needed for multiline ellipsis */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
}

.ellipsis,
%ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
