.mat-drawer-container {

    z-index: unset !important;

    .mat-drawer-backdrop {

        position: fixed;
        z-index: 4;
    }

    mat-drawer {

        z-index: 21;
    }
}
