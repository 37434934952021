#assign {
    overflow: visible;

    md-input-container {
        padding: 16px;
    }

    md-content {
        height: 200px;
    }

    md-list-item:last-child md-divider {
        display: block;
    }
}
