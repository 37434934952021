#manage-saved-filters-dialog {
    .sf-empty-state {
        margin: auto;
    }

    md-list {
        .md-list-item-text {
            span.name {
                font-size: 16px;
            }

            md-icon {
                margin-left: 4px;
            }
        }
    }
}
