@use "sass/core/variables";

#conference {
    .sf-conference-toolbar {
        color: variables.$white-color;
        background-color: variables.$salesflare-color;
    }

    .sf-hangup {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
