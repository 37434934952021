.minicolors-theme-bootstrap {
    .minicolors-swatch {
        top: -3px;
        border: none;
    }

    &.minicolors-position-bottom .minicolors-panel {
        top: 36px;
    }

    &.minicolors-position-right .minicolors-input {
        padding-left: 2px;
    }
}

.minicolors .minicolors-grid {
    display: none;
}

.minicolors-slider, .minicolors-opacity-slider {
    display: none;
}

.minicolors-panel {
    width: 222px;
    height: 138px;
}
