@use "sass/core/variables";

sf-scorecard {
    color: variables.$salesflare-color;

    // Reset some md-card styles
    md-card {
        box-shadow: none;
        margin: 0;
        border: 1px solid variables.$light-gray-color;
        height: 140px;
        width: 25vw;
        min-width: 200px;

        md-card-title {
            padding: 12px variables.$default-spacing; // 12 top and bottom gives the title a bit more space without starting to float
            // Don't take up unneeded space when no title is there
            // We still keep the element as it provides spacing above .data
            flex: none;
        }

        // Align padding with the .report-container .title
        md-card-content {
            padding-left: variables.$default-spacing * 0.5;
            padding-right: variables.$default-spacing * 0.5;
        }
    }

    .data {
        font-size: variables.$font-size-18 + px;
        font-weight: 900;
        margin: 0;

        .difference-indicator {
            font-size: variables.$font-size-12 + px;

            >.negative {
                color: variables.$dashboard-palette-red;
            }

            >.positive {
                color: variables.$dashboard-palette-green;
            }
        }
    }

    .label {
        font-size: variables.$font-size-14 + px;
        font-weight: 600;
        margin: 0;
    }
}
