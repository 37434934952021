@use 'sass:math';

@mixin font-size($size, $important: null) {
    @if $important {
        font-size: $size + px + !important; /* stylelint-disable-line scss/operator-no-unspaced */
        font-size: math.div($size, 14) + rem + !important; /* stylelint-disable-line scss/operator-no-unspaced */
    } @else { /* stylelint-disable-line block-closing-brace-newline-after, at-rule-empty-line-before */
        font-size: $size + px;
        font-size: math.div($size, 14) + rem;
    }
}

.no_select,
%no-select {
    -webkit-touch-callout: none;
    user-select: none;
    cursor: default;
}

.remove-gap {
    margin-right: -4px;
}
