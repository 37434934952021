@use "sass/core/variables";
@use "common";

sf-quota-per-rep {
    @include common.report-card-reset;

    .quota-per-rep {
        margin: 32px 0;

        .quota-met {
            background-color: variables.$dashboard-palette-green;
        }

        .quota-not-met {
            background-color: variables.$red-color;
        }

        .quota-bar {
            height: 12px;
            border: none;
        }

        .quota-target {
            height: 52px;
            position: absolute;
            top: -20px;
            background-color: transparent;
            border-right: 2px solid variables.$salesflare-color;
            border-bottom: none;
            border-top: none;
            text-align: end;
            color: variables.$salesflare-color;
            font-size: 12px;

            .target-number {
                margin-right: 4px;
            }

            > span {
                border-top: 0;
                border-bottom: 0;
                background-color: variables.$white-color;
            }
        }
    }
}
