@use "sass/core/variables";
@use "sass/core/utils";
@use "sass/dependencies/ng-messages";

/*
 * temporary till angular material fixes it
 * https://github.com/angular/material/issues/5672
 * -- angular material adds spacing for ngMessages AND extra margin bottom,
 * it used to to either of both depending on if the container had messages, now it does both and causes massive spacing in between inputs in forms
 */
md-input-container {
    margin-bottom: 8px;
    margin-top: 8px;

    &.no-errors-spacer {
        margin-bottom: 0;
        margin-top: 0;

        .md-errors-spacer {
            display: none;
        }
    }

    .md-input-messages-animation > .hint,
    .md-input-messages-animation.hint {
        @extend %hint;
    }

    .md-input-messages-animation.md-auto-hide.ng-active .warning.md-input-message-animation {
        opacity: 1;
        margin-top: 0;
    }
}

md-toolbar md-input-container {
    margin-bottom: 18px;
    margin-top: 18px;
}

md-input-container:not(.md-input-invalid).md-input-focused md-icon {
    color: variables.$white-color;

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        color: variables.$text-color;
    }
}

.sf-md-input-container-avatar {
    top: 12px;
}
