sf-intro {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 30%);
    top: 0;
    left: 0;
    z-index: 100;
}

sf-intro div {
    width: 100%;
    text-align: center;
    margin: auto;
    font-size: 10em;
}
