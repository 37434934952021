#editContacts,
#selectIndividualContacts {
    height: 100%;

    .accent {
        line-height: 48px;
    }

    md-list-item {
        .md-list-item-inner {
            &.md-no-style {
                padding: 0 16px;
            }
        }

        md-checkbox {
            margin-left: 6px;
            margin-right: 22px;
        }
    }

    .md-toolbar-tools > .md-button:last-child {
        margin-right: 0;
    }
}
