@use "sass/core/variables";

.plan-tooltip {
    max-width: 800px;
    white-space: pre-line;
    transform: translate(100%, -50%) scale(1);
}

.plans-container,
.credits-container {
    background-color: variables.$list-bg-color;
    margin: auto;
    width: 100%;

    .sf-plans,
    .sf-credits {
        background-color: variables.$list-bg-color;
        margin: auto;

        .layout {
            @media screen and (max-width: variables.$layout-breakpoint-md) {
                width: 80%;
                min-width: 320px;
                max-width: 510px;
                margin: auto;
            }

            @media screen and (max-width: variables.$layout-breakpoint-sm) {
                width: 100%;
            }

            width: fit-content;
            margin: 0 auto;
            flex-wrap: wrap;
        }

        .plan {
            margin: (variables.$default-spacing * 2) 0;
            border: 1px solid #eee;
            background-color: white;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-basis: 0;

            @media screen and (min-width: variables.$layout-breakpoint-md) {
                margin: 16px;
                max-width: 305px;

                // We want the plan boxes to flex evenly. The spans in the upgrade buttons have a min width because of the text.
                // This would result in differing min-widths of plan boxes

                min-width: 275px;
            }

            .header {
                text-align: center;
                padding: 34px;
                background-color: #f9f9f9;
                border-bottom: 1px solid #eee;
                position: relative; // for the positioning of the popular-bar

                .credit-amount {
                    font-weight: 500;
                    margin-bottom: 34px;
                }

                &:not(.pro-plan, .medium-plan) {
                    border-top-left-radius: inherit;
                    border-top-right-radius: inherit;
                }

                .monthly-price-span {
                    margin-top: 4px;
                }

                .popular-bar {
                    background-color: variables.$orange-color;
                    color: variables.$white-color;
                    position: absolute;
                    top: -20px;
                    left: 0;
                    width: 100%;
                    height: 20px;
                    display: flex;
                    font-weight: 700;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;

                    span {
                        margin: auto;
                        position: relative;
                        top: 1px;
                    }
                }

                h3 {
                    color: #3c3d41;
                    font-size: 26px;
                    font-weight: 700;
                    margin-bottom: 10px;
                    line-height: 26px;
                    margin-top: 0;
                }

                span:not(.currency) {
                    display: block;
                }
            }

            .header.credit-plan-header {
                background-color: white;
                border-bottom: none;
            }

            ul.feature-list {
                padding: 0 26px;
                list-style: none;
                color: variables.$salesflare-color;

                .summarization {
                    margin-top: 15px;

                    @media screen and (max-width: variables.$layout-breakpoint-md) {
                        margin-top: 24px;
                    }
                }

                li {
                    display: flex;
                    align-items: center;
                    margin-top: 15px;

                    @media screen and (max-width: variables.$layout-breakpoint-md) {
                        margin-top: 24px;
                    }

                    .icon-group {
                        white-space: nowrap;
                    }

                    md-icon {
                        &.info {
                            // color: variables.$text-color;
                            margin-left: 4px;
                            color: lightgrey;
                            position: relative;
                            bottom: 1px;

                            .md-tooltip {
                                height: auto;
                            }
                        }

                        &.check {
                            color: green;
                            margin: 0 variables.$default-spacing auto 0;

                            @media screen and (max-width: variables.$layout-breakpoint-md) {
                                font-size: 23.2px;
                            }
                        }
                    }
                }
            }

            .button-container {
                margin-top: auto;

                .md-button {
                    margin: 16px;
                    padding: 0 32px;

                    // &:disabled {
                    //     // background-color: rgba(0,0,0,0.38);
                    //     // opacity: .5;
                    // }
                }

                .md-button:not([disabled]) {
                    background-color: variables.$salesflare-color;
                    color: white;
                }
            }
        }
    }
}

#edit-plans-container {
    div.plan > p {
        font-size: 28px;
        margin: 0;
        margin-top: variables.$default-spacing * 2;
        text-align: center;

        > span {
            font-size: 48px;
        }

        + p {
            text-align: center;
            margin: 0;
            margin-bottom: 32px;
        }

        div.upgrade-button {
            text-align: center;
        }
    }

    .downgrade {
        margin: 0 variables.$default-spacing;

        @media screen and (max-width: variables.$layout-breakpoint-md) {
            margin: 0 variables.$default-spacing variables.$default-spacing variables.$default-spacing;
        }
    }
}
