#editStageDialog {
    max-width: 410px;

    // to make sure the color picker pops out
    // this does break vertical responsiveness but color picker is deemed more important

    overflow: visible;

    md-dialog-content {
        // see above
        overflow: visible;
    }
}
