@use "sass/core/variables";

#credit-package-confirmation-dialog {
    max-height: 85%;

    .text {
        padding: 0 variables.$default-spacing;
        max-width: 500px;
    }
}
