#email-tracking-dialog {
    max-width: 1020px;
    margin: 50px;

    md-dialog-content > div {
        margin: 16px;

        > div {
            margin-top: 20px;

            div:nth-child(2) {
                margin-left: 20px;
                word-break: break-all;
            }
        }
    }
}
