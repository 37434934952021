@use "sass/core/variables";

.join-existing-team-dialog {
    height: auto;
    width: 550px;
    min-width: 350px;
}

#join-existing-team-dialog {
    md-dialog-content {
        p.text {
            margin: variables.$default-spacing;
        }

        md-content {
            max-height: 48px * 5.5; // Make scrollable starting from 6 list items. Show the last one 50% to make it clear to the user that there are more items.
        }
    }
}
