@use "sass/core/variables";

.button-group {
    padding: 8px 10px;
    justify-content: space-around;

    &.right {
        text-align: right;
    }
}

// color should be transparent because button is on top of .md-list-item-inner
.md-button,
.md-button.md-default-theme {
    &:not([disabled]) {
        &.md-focused,
        &:hover {
            background-color: rgb(195 190 215 / 20%);
        }
    }
}

.md-button,
a.md-button.md-default-theme.md-primary,
a.md-button.md-primary,
.md-button.md-default-theme.md-primary,
.md-button.md-primary {
    margin: 0;
    color: variables.$blue-color;
    text-transform: uppercase;
    min-height: 0;
    min-width: 0;

    &.md-icon-button:not(.md-datepicker-triangle-button) {
        padding: 0;
        margin-right: 0;
    }

    &.md-no-style {
        font-weight: 400;
        padding: 0 16px;
    }

    &[md-no-ink]:hover {
        background-color: transparent;

        p,
        span,
        h1,
        h2 {
            text-decoration: underline;
        }
    }
}

.md-button.small {
    font-size: variables.$font-size-11 + px;
    font-weight: 700;
}

.md-button.md-raised:not([disabled]) md-icon {
    color: variables.$white-color;
}

.md-button.md-icon-button.copy-to-clipboard-button {
    height: 18px;
    width: 18px;
    margin: 0;
}
