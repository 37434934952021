@use "sass/core/variables";

#edit-signature-dialog {
    .extra-options-container {
        background-color: variables.$white-color;
        padding-bottom: variables.$default-spacing * 0.5;

        md-switch.footer-switch {
            margin: 0 variables.$default-spacing;

            .md-label {
                color: variables.$black-color;
            }
        }
    }

    .editor {
        border: none;
        resize: none;
    }
}
