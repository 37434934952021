@use "sass/core/variables";

$chart-height: 420px;

sf-chart-builder {
    .chart-builder-toolbar {
        padding: (variables.$default-spacing * 0.5) 12px;
        z-index: 1; // Make sure the buttons are clickable when it overlaps with the axis selector divs

        .md-button.md-icon-button {
            height: 24px;
            width: 24px;
            margin: 0 6px 0 0;

            &.blue {
                md-icon {
                    color: variables.$blue-color;
                }
            }
        }

        .compare-to-previous-period {
            margin-bottom: 0;
        }
    }

    .chart-builder-workspace {
        padding: variables.$default-spacing variables.$default-spacing (variables.$default-spacing * 1.5) variables.$default-spacing; // Add bottom spacing to give a bit more breathing room to the bottom dimension autocomplete
        position: relative;

        md-select {
            margin: 0;

            md-select-value {
                border-bottom-color: transparent;
            }
        }

        .chart-builder-toolbar {
            padding-left: 6px;
        }

        // Wraps the chart + x-axis dropdown
        .chart-height-container {
            padding: variables.$default-spacing 0 0 0;
            height: $chart-height + 30px; // add 30px for bottom dimension selector
            position: relative;

            // Wraps the chart + y-axis dropdown
            .chart-width-container {
                margin-left: 80px; // This more than the 30px needed but this to make sure we visually don't stick against the chart.
                height: 100%;

                .sf-chart-container {
                    width: 100%;
                    height: $chart-height;
                }
            }
        }

        .chart-dimension-autocomplete-container {
            margin: 0; // Not needed since we're not in a form and the normal margin makes the input and the thus also the type buttons higher than needed and to high to look good.
            padding: 0 0 0 (variables.$default-spacing * 0.25); // Removes unneeded padding, see margin, but makes sure we have some spacing between the input and the label.

            md-autocomplete.chart-dimension-autocomplete {
                z-index: 3; // Show over chart builder workspace content. First child table rows have a z-index of 2
                min-width: 240px;

                md-autocomplete-wrap {
                    box-shadow: none;
                    border-bottom: 1px rgb(0 0 0 / 12%) solid;

                    input {
                        padding-right: variables.$default-spacing; // To make up for the arrow-down, so we ellipsis in time.
                        height: 40px; // Aligns the placeholder with the "label" better.
                        order: initial;
                        border: none;
                    }

                    // Using not(.ng-not-empty) here because ng-empty sometimes gets wrongly used
                    input:not(.ng-not-empty) {
                        background-image: url("../../../images/arrow_down_gray.png");
                        background-repeat: no-repeat;
                        background-position-x: right;
                        background-position-y: 8px;
                    }
                }
            }
        }

        .chart-dimension {
            &.bottom {
                position: absolute;
                width: 100%;
                bottom: -10px; // Position slightly lower so bottom dimension autocomplete doesn't overlap
            }

            &.left {
                transform: rotate(-90deg);
                transform-origin: 0 0;
                width: 480px; // Specific width to make 'aggregate function md-select' align better after rotation
                min-height: 40px; // min-height to keep the label from re-aligning when rotating the select
                left: 0;
                padding: 0 (variables.$default-spacing * 1.5) 0 (variables.$default-spacing * 1.5);

                .dimension-input-container {
                    min-width: 240px;
                }

                // Apply rotation to the wrapper instead of the autocomplete element, to rotate the close button as well
                .rotatable-autocomplete {
                    transform: rotate(0deg);
                    position: relative;
                    top: 0;
                    left: 0;
                    width: inherit;
                    box-shadow: none;

                    &.rotate {
                        transform: rotate(90deg);
                        position: absolute;
                        top: 140px;
                        left: -140px;
                        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
                        width: 330px;

                        input {
                            text-indent: variables.$default-spacing * 0.5;
                        }
                    }
                }
            }

            .secondary-select-container {
                margin-left: (variables.$default-spacing * 0.5);

                &.left {
                    // Keeps secondary container in place when autocomplete is rotated
                    margin-left: auto;
                }
            }
        }

        .empty-chart-dimension-autocomplete.sf-icon-close {
            padding-top: 2px; // Align better with the dropdown arrow
            // override autocomplete form icon
            margin: auto;
            color: rgb(0 0 0 / 54%);
        }
    }
}
