// sass-lint:disable hex-notation, variable-for-property
// This file represents the cssplot library over at https://github.com/asciimoo/cssplot, it should always be the same
// Changes to the cssplot dependency should NOT be made in this file but in the cssplot.scss dependency file also in this folder
.column-chart,
.bar-chart,
.scatter-plot {
    position: relative;
    min-width: 100px;
    min-height: 100px;
    padding: 0;
    margin: 0;
}
.column-chart .chart-column,
.bar-chart .chart-row,
.column-chart [data-cp-size],
.bar-chart [data-cp-size],
.plot-container > * > * {
    list-style: none;
    border: 1px solid #ffffff;
}
.column-chart .plot-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    min-height: 60px;
}
.column-chart.stacked > * > * {
    height: 100%;
    -webkit-transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
}
.column-chart.stacked .group-by-number [data-cp-size] {
    -webkit-transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
}
.column-chart .chart-column,
.column-chart [data-cp-size],
.column-chart.stacked > * > * {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-align-self: flex-end;
    align-self: flex-end;
}
.column-chart .chart-column,
.bar-chart .chart-row,
.column-chart [data-cp-size],
.bar-chart [data-cp-size] {
    background: #3498db;
    color: #ffffff;
    box-sizing: border-box;
}
.bar-chart {
    clear: both;
}
.bar-chart .plot-container {
    flex-direction: row;
    padding: 0;
    margin: 0;
    width: 100%;
}
.bar-chart .chart-row,
.bar-chart [data-cp-size] {
    min-height: 8px;
}
.bar-chart.stacked > * > * {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.bar-chart.stacked .group-by-number [data-cp-size] {
    display: inline-block;
}
.scatter-plot .plot-container {
    position: absolute;
    top: 12px;
    left: 0;
    right: 12px;
    bottom: 0;
    margin: 0;
    padding: 0;
}
.scatter-plot .chart-dot,
.scatter-plot .chart-dot,
.scatter-plot [data-cp-x],
.scatter-plot [data-cp-y] {
    position: absolute;
    height: 0;
    width: 0;
    list-style: none;
    overflow: hidden;
    border: 6px solid #3498db;
    border-radius: 100%;
}
.column-chart .plot-container [data-cp-size="0"] {
    height: 0%;
}
.column-chart .plot-container [data-cp-size="1"] {
    height: 1%;
}
.column-chart .plot-container [data-cp-size="2"] {
    height: 2%;
}
.column-chart .plot-container [data-cp-size="3"] {
    height: 3%;
}
.column-chart .plot-container [data-cp-size="4"] {
    height: 4%;
}
.column-chart .plot-container [data-cp-size="5"] {
    height: 5%;
}
.column-chart .plot-container [data-cp-size="6"] {
    height: 6%;
}
.column-chart .plot-container [data-cp-size="7"] {
    height: 7%;
}
.column-chart .plot-container [data-cp-size="8"] {
    height: 8%;
}
.column-chart .plot-container [data-cp-size="9"] {
    height: 9%;
}
.column-chart .plot-container [data-cp-size="10"] {
    height: 10%;
}
.column-chart .plot-container [data-cp-size="11"] {
    height: 11%;
}
.column-chart .plot-container [data-cp-size="12"] {
    height: 12%;
}
.column-chart .plot-container [data-cp-size="13"] {
    height: 13%;
}
.column-chart .plot-container [data-cp-size="14"] {
    height: 14%;
}
.column-chart .plot-container [data-cp-size="15"] {
    height: 15%;
}
.column-chart .plot-container [data-cp-size="16"] {
    height: 16%;
}
.column-chart .plot-container [data-cp-size="17"] {
    height: 17%;
}
.column-chart .plot-container [data-cp-size="18"] {
    height: 18%;
}
.column-chart .plot-container [data-cp-size="19"] {
    height: 19%;
}
.column-chart .plot-container [data-cp-size="20"] {
    height: 20%;
}
.column-chart .plot-container [data-cp-size="21"] {
    height: 21%;
}
.column-chart .plot-container [data-cp-size="22"] {
    height: 22%;
}
.column-chart .plot-container [data-cp-size="23"] {
    height: 23%;
}
.column-chart .plot-container [data-cp-size="24"] {
    height: 24%;
}
.column-chart .plot-container [data-cp-size="25"] {
    height: 25%;
}
.column-chart .plot-container [data-cp-size="26"] {
    height: 26%;
}
.column-chart .plot-container [data-cp-size="27"] {
    height: 27%;
}
.column-chart .plot-container [data-cp-size="28"] {
    height: 28%;
}
.column-chart .plot-container [data-cp-size="29"] {
    height: 29%;
}
.column-chart .plot-container [data-cp-size="30"] {
    height: 30%;
}
.column-chart .plot-container [data-cp-size="31"] {
    height: 31%;
}
.column-chart .plot-container [data-cp-size="32"] {
    height: 32%;
}
.column-chart .plot-container [data-cp-size="33"] {
    height: 33%;
}
.column-chart .plot-container [data-cp-size="34"] {
    height: 34%;
}
.column-chart .plot-container [data-cp-size="35"] {
    height: 35%;
}
.column-chart .plot-container [data-cp-size="36"] {
    height: 36%;
}
.column-chart .plot-container [data-cp-size="37"] {
    height: 37%;
}
.column-chart .plot-container [data-cp-size="38"] {
    height: 38%;
}
.column-chart .plot-container [data-cp-size="39"] {
    height: 39%;
}
.column-chart .plot-container [data-cp-size="40"] {
    height: 40%;
}
.column-chart .plot-container [data-cp-size="41"] {
    height: 41%;
}
.column-chart .plot-container [data-cp-size="42"] {
    height: 42%;
}
.column-chart .plot-container [data-cp-size="43"] {
    height: 43%;
}
.column-chart .plot-container [data-cp-size="44"] {
    height: 44%;
}
.column-chart .plot-container [data-cp-size="45"] {
    height: 45%;
}
.column-chart .plot-container [data-cp-size="46"] {
    height: 46%;
}
.column-chart .plot-container [data-cp-size="47"] {
    height: 47%;
}
.column-chart .plot-container [data-cp-size="48"] {
    height: 48%;
}
.column-chart .plot-container [data-cp-size="49"] {
    height: 49%;
}
.column-chart .plot-container [data-cp-size="50"] {
    height: 50%;
}
.column-chart .plot-container [data-cp-size="51"] {
    height: 51%;
}
.column-chart .plot-container [data-cp-size="52"] {
    height: 52%;
}
.column-chart .plot-container [data-cp-size="53"] {
    height: 53%;
}
.column-chart .plot-container [data-cp-size="54"] {
    height: 54%;
}
.column-chart .plot-container [data-cp-size="55"] {
    height: 55%;
}
.column-chart .plot-container [data-cp-size="56"] {
    height: 56%;
}
.column-chart .plot-container [data-cp-size="57"] {
    height: 57%;
}
.column-chart .plot-container [data-cp-size="58"] {
    height: 58%;
}
.column-chart .plot-container [data-cp-size="59"] {
    height: 59%;
}
.column-chart .plot-container [data-cp-size="60"] {
    height: 60%;
}
.column-chart .plot-container [data-cp-size="61"] {
    height: 61%;
}
.column-chart .plot-container [data-cp-size="62"] {
    height: 62%;
}
.column-chart .plot-container [data-cp-size="63"] {
    height: 63%;
}
.column-chart .plot-container [data-cp-size="64"] {
    height: 64%;
}
.column-chart .plot-container [data-cp-size="65"] {
    height: 65%;
}
.column-chart .plot-container [data-cp-size="66"] {
    height: 66%;
}
.column-chart .plot-container [data-cp-size="67"] {
    height: 67%;
}
.column-chart .plot-container [data-cp-size="68"] {
    height: 68%;
}
.column-chart .plot-container [data-cp-size="69"] {
    height: 69%;
}
.column-chart .plot-container [data-cp-size="70"] {
    height: 70%;
}
.column-chart .plot-container [data-cp-size="71"] {
    height: 71%;
}
.column-chart .plot-container [data-cp-size="72"] {
    height: 72%;
}
.column-chart .plot-container [data-cp-size="73"] {
    height: 73%;
}
.column-chart .plot-container [data-cp-size="74"] {
    height: 74%;
}
.column-chart .plot-container [data-cp-size="75"] {
    height: 75%;
}
.column-chart .plot-container [data-cp-size="76"] {
    height: 76%;
}
.column-chart .plot-container [data-cp-size="77"] {
    height: 77%;
}
.column-chart .plot-container [data-cp-size="78"] {
    height: 78%;
}
.column-chart .plot-container [data-cp-size="79"] {
    height: 79%;
}
.column-chart .plot-container [data-cp-size="80"] {
    height: 80%;
}
.column-chart .plot-container [data-cp-size="81"] {
    height: 81%;
}
.column-chart .plot-container [data-cp-size="82"] {
    height: 82%;
}
.column-chart .plot-container [data-cp-size="83"] {
    height: 83%;
}
.column-chart .plot-container [data-cp-size="84"] {
    height: 84%;
}
.column-chart .plot-container [data-cp-size="85"] {
    height: 85%;
}
.column-chart .plot-container [data-cp-size="86"] {
    height: 86%;
}
.column-chart .plot-container [data-cp-size="87"] {
    height: 87%;
}
.column-chart .plot-container [data-cp-size="88"] {
    height: 88%;
}
.column-chart .plot-container [data-cp-size="89"] {
    height: 89%;
}
.column-chart .plot-container [data-cp-size="90"] {
    height: 90%;
}
.column-chart .plot-container [data-cp-size="91"] {
    height: 91%;
}
.column-chart .plot-container [data-cp-size="92"] {
    height: 92%;
}
.column-chart .plot-container [data-cp-size="93"] {
    height: 93%;
}
.column-chart .plot-container [data-cp-size="94"] {
    height: 94%;
}
.column-chart .plot-container [data-cp-size="95"] {
    height: 95%;
}
.column-chart .plot-container [data-cp-size="96"] {
    height: 96%;
}
.column-chart .plot-container [data-cp-size="97"] {
    height: 97%;
}
.column-chart .plot-container [data-cp-size="98"] {
    height: 98%;
}
.column-chart .plot-container [data-cp-size="99"] {
    height: 99%;
}
.column-chart .plot-container [data-cp-size="100"] {
    height: 100%;
}
.bar-chart .plot-container [data-cp-size="0"] {
    width: 0%;
}
.bar-chart .plot-container [data-cp-size="1"] {
    width: 1%;
}
.bar-chart .plot-container [data-cp-size="2"] {
    width: 2%;
}
.bar-chart .plot-container [data-cp-size="3"] {
    width: 3%;
}
.bar-chart .plot-container [data-cp-size="4"] {
    width: 4%;
}
.bar-chart .plot-container [data-cp-size="5"] {
    width: 5%;
}
.bar-chart .plot-container [data-cp-size="6"] {
    width: 6%;
}
.bar-chart .plot-container [data-cp-size="7"] {
    width: 7%;
}
.bar-chart .plot-container [data-cp-size="8"] {
    width: 8%;
}
.bar-chart .plot-container [data-cp-size="9"] {
    width: 9%;
}
.bar-chart .plot-container [data-cp-size="10"] {
    width: 10%;
}
.bar-chart .plot-container [data-cp-size="11"] {
    width: 11%;
}
.bar-chart .plot-container [data-cp-size="12"] {
    width: 12%;
}
.bar-chart .plot-container [data-cp-size="13"] {
    width: 13%;
}
.bar-chart .plot-container [data-cp-size="14"] {
    width: 14%;
}
.bar-chart .plot-container [data-cp-size="15"] {
    width: 15%;
}
.bar-chart .plot-container [data-cp-size="16"] {
    width: 16%;
}
.bar-chart .plot-container [data-cp-size="17"] {
    width: 17%;
}
.bar-chart .plot-container [data-cp-size="18"] {
    width: 18%;
}
.bar-chart .plot-container [data-cp-size="19"] {
    width: 19%;
}
.bar-chart .plot-container [data-cp-size="20"] {
    width: 20%;
}
.bar-chart .plot-container [data-cp-size="21"] {
    width: 21%;
}
.bar-chart .plot-container [data-cp-size="22"] {
    width: 22%;
}
.bar-chart .plot-container [data-cp-size="23"] {
    width: 23%;
}
.bar-chart .plot-container [data-cp-size="24"] {
    width: 24%;
}
.bar-chart .plot-container [data-cp-size="25"] {
    width: 25%;
}
.bar-chart .plot-container [data-cp-size="26"] {
    width: 26%;
}
.bar-chart .plot-container [data-cp-size="27"] {
    width: 27%;
}
.bar-chart .plot-container [data-cp-size="28"] {
    width: 28%;
}
.bar-chart .plot-container [data-cp-size="29"] {
    width: 29%;
}
.bar-chart .plot-container [data-cp-size="30"] {
    width: 30%;
}
.bar-chart .plot-container [data-cp-size="31"] {
    width: 31%;
}
.bar-chart .plot-container [data-cp-size="32"] {
    width: 32%;
}
.bar-chart .plot-container [data-cp-size="33"] {
    width: 33%;
}
.bar-chart .plot-container [data-cp-size="34"] {
    width: 34%;
}
.bar-chart .plot-container [data-cp-size="35"] {
    width: 35%;
}
.bar-chart .plot-container [data-cp-size="36"] {
    width: 36%;
}
.bar-chart .plot-container [data-cp-size="37"] {
    width: 37%;
}
.bar-chart .plot-container [data-cp-size="38"] {
    width: 38%;
}
.bar-chart .plot-container [data-cp-size="39"] {
    width: 39%;
}
.bar-chart .plot-container [data-cp-size="40"] {
    width: 40%;
}
.bar-chart .plot-container [data-cp-size="41"] {
    width: 41%;
}
.bar-chart .plot-container [data-cp-size="42"] {
    width: 42%;
}
.bar-chart .plot-container [data-cp-size="43"] {
    width: 43%;
}
.bar-chart .plot-container [data-cp-size="44"] {
    width: 44%;
}
.bar-chart .plot-container [data-cp-size="45"] {
    width: 45%;
}
.bar-chart .plot-container [data-cp-size="46"] {
    width: 46%;
}
.bar-chart .plot-container [data-cp-size="47"] {
    width: 47%;
}
.bar-chart .plot-container [data-cp-size="48"] {
    width: 48%;
}
.bar-chart .plot-container [data-cp-size="49"] {
    width: 49%;
}
.bar-chart .plot-container [data-cp-size="50"] {
    width: 50%;
}
.bar-chart .plot-container [data-cp-size="51"] {
    width: 51%;
}
.bar-chart .plot-container [data-cp-size="52"] {
    width: 52%;
}
.bar-chart .plot-container [data-cp-size="53"] {
    width: 53%;
}
.bar-chart .plot-container [data-cp-size="54"] {
    width: 54%;
}
.bar-chart .plot-container [data-cp-size="55"] {
    width: 55%;
}
.bar-chart .plot-container [data-cp-size="56"] {
    width: 56%;
}
.bar-chart .plot-container [data-cp-size="57"] {
    width: 57%;
}
.bar-chart .plot-container [data-cp-size="58"] {
    width: 58%;
}
.bar-chart .plot-container [data-cp-size="59"] {
    width: 59%;
}
.bar-chart .plot-container [data-cp-size="60"] {
    width: 60%;
}
.bar-chart .plot-container [data-cp-size="61"] {
    width: 61%;
}
.bar-chart .plot-container [data-cp-size="62"] {
    width: 62%;
}
.bar-chart .plot-container [data-cp-size="63"] {
    width: 63%;
}
.bar-chart .plot-container [data-cp-size="64"] {
    width: 64%;
}
.bar-chart .plot-container [data-cp-size="65"] {
    width: 65%;
}
.bar-chart .plot-container [data-cp-size="66"] {
    width: 66%;
}
.bar-chart .plot-container [data-cp-size="67"] {
    width: 67%;
}
.bar-chart .plot-container [data-cp-size="68"] {
    width: 68%;
}
.bar-chart .plot-container [data-cp-size="69"] {
    width: 69%;
}
.bar-chart .plot-container [data-cp-size="70"] {
    width: 70%;
}
.bar-chart .plot-container [data-cp-size="71"] {
    width: 71%;
}
.bar-chart .plot-container [data-cp-size="72"] {
    width: 72%;
}
.bar-chart .plot-container [data-cp-size="73"] {
    width: 73%;
}
.bar-chart .plot-container [data-cp-size="74"] {
    width: 74%;
}
.bar-chart .plot-container [data-cp-size="75"] {
    width: 75%;
}
.bar-chart .plot-container [data-cp-size="76"] {
    width: 76%;
}
.bar-chart .plot-container [data-cp-size="77"] {
    width: 77%;
}
.bar-chart .plot-container [data-cp-size="78"] {
    width: 78%;
}
.bar-chart .plot-container [data-cp-size="79"] {
    width: 79%;
}
.bar-chart .plot-container [data-cp-size="80"] {
    width: 80%;
}
.bar-chart .plot-container [data-cp-size="81"] {
    width: 81%;
}
.bar-chart .plot-container [data-cp-size="82"] {
    width: 82%;
}
.bar-chart .plot-container [data-cp-size="83"] {
    width: 83%;
}
.bar-chart .plot-container [data-cp-size="84"] {
    width: 84%;
}
.bar-chart .plot-container [data-cp-size="85"] {
    width: 85%;
}
.bar-chart .plot-container [data-cp-size="86"] {
    width: 86%;
}
.bar-chart .plot-container [data-cp-size="87"] {
    width: 87%;
}
.bar-chart .plot-container [data-cp-size="88"] {
    width: 88%;
}
.bar-chart .plot-container [data-cp-size="89"] {
    width: 89%;
}
.bar-chart .plot-container [data-cp-size="90"] {
    width: 90%;
}
.bar-chart .plot-container [data-cp-size="91"] {
    width: 91%;
}
.bar-chart .plot-container [data-cp-size="92"] {
    width: 92%;
}
.bar-chart .plot-container [data-cp-size="93"] {
    width: 93%;
}
.bar-chart .plot-container [data-cp-size="94"] {
    width: 94%;
}
.bar-chart .plot-container [data-cp-size="95"] {
    width: 95%;
}
.bar-chart .plot-container [data-cp-size="96"] {
    width: 96%;
}
.bar-chart .plot-container [data-cp-size="97"] {
    width: 97%;
}
.bar-chart .plot-container [data-cp-size="98"] {
    width: 98%;
}
.bar-chart .plot-container [data-cp-size="99"] {
    width: 99%;
}
.bar-chart .plot-container [data-cp-size="100"] {
    width: 100%;
}
.scatter-plot .plot-container [data-cp-x="0"] {
    left: 0%;
}
.scatter-plot .plot-container [data-cp-x="1"] {
    left: 1%;
}
.scatter-plot .plot-container [data-cp-x="2"] {
    left: 2%;
}
.scatter-plot .plot-container [data-cp-x="3"] {
    left: 3%;
}
.scatter-plot .plot-container [data-cp-x="4"] {
    left: 4%;
}
.scatter-plot .plot-container [data-cp-x="5"] {
    left: 5%;
}
.scatter-plot .plot-container [data-cp-x="6"] {
    left: 6%;
}
.scatter-plot .plot-container [data-cp-x="7"] {
    left: 7%;
}
.scatter-plot .plot-container [data-cp-x="8"] {
    left: 8%;
}
.scatter-plot .plot-container [data-cp-x="9"] {
    left: 9%;
}
.scatter-plot .plot-container [data-cp-x="10"] {
    left: 10%;
}
.scatter-plot .plot-container [data-cp-x="11"] {
    left: 11%;
}
.scatter-plot .plot-container [data-cp-x="12"] {
    left: 12%;
}
.scatter-plot .plot-container [data-cp-x="13"] {
    left: 13%;
}
.scatter-plot .plot-container [data-cp-x="14"] {
    left: 14%;
}
.scatter-plot .plot-container [data-cp-x="15"] {
    left: 15%;
}
.scatter-plot .plot-container [data-cp-x="16"] {
    left: 16%;
}
.scatter-plot .plot-container [data-cp-x="17"] {
    left: 17%;
}
.scatter-plot .plot-container [data-cp-x="18"] {
    left: 18%;
}
.scatter-plot .plot-container [data-cp-x="19"] {
    left: 19%;
}
.scatter-plot .plot-container [data-cp-x="20"] {
    left: 20%;
}
.scatter-plot .plot-container [data-cp-x="21"] {
    left: 21%;
}
.scatter-plot .plot-container [data-cp-x="22"] {
    left: 22%;
}
.scatter-plot .plot-container [data-cp-x="23"] {
    left: 23%;
}
.scatter-plot .plot-container [data-cp-x="24"] {
    left: 24%;
}
.scatter-plot .plot-container [data-cp-x="25"] {
    left: 25%;
}
.scatter-plot .plot-container [data-cp-x="26"] {
    left: 26%;
}
.scatter-plot .plot-container [data-cp-x="27"] {
    left: 27%;
}
.scatter-plot .plot-container [data-cp-x="28"] {
    left: 28%;
}
.scatter-plot .plot-container [data-cp-x="29"] {
    left: 29%;
}
.scatter-plot .plot-container [data-cp-x="30"] {
    left: 30%;
}
.scatter-plot .plot-container [data-cp-x="31"] {
    left: 31%;
}
.scatter-plot .plot-container [data-cp-x="32"] {
    left: 32%;
}
.scatter-plot .plot-container [data-cp-x="33"] {
    left: 33%;
}
.scatter-plot .plot-container [data-cp-x="34"] {
    left: 34%;
}
.scatter-plot .plot-container [data-cp-x="35"] {
    left: 35%;
}
.scatter-plot .plot-container [data-cp-x="36"] {
    left: 36%;
}
.scatter-plot .plot-container [data-cp-x="37"] {
    left: 37%;
}
.scatter-plot .plot-container [data-cp-x="38"] {
    left: 38%;
}
.scatter-plot .plot-container [data-cp-x="39"] {
    left: 39%;
}
.scatter-plot .plot-container [data-cp-x="40"] {
    left: 40%;
}
.scatter-plot .plot-container [data-cp-x="41"] {
    left: 41%;
}
.scatter-plot .plot-container [data-cp-x="42"] {
    left: 42%;
}
.scatter-plot .plot-container [data-cp-x="43"] {
    left: 43%;
}
.scatter-plot .plot-container [data-cp-x="44"] {
    left: 44%;
}
.scatter-plot .plot-container [data-cp-x="45"] {
    left: 45%;
}
.scatter-plot .plot-container [data-cp-x="46"] {
    left: 46%;
}
.scatter-plot .plot-container [data-cp-x="47"] {
    left: 47%;
}
.scatter-plot .plot-container [data-cp-x="48"] {
    left: 48%;
}
.scatter-plot .plot-container [data-cp-x="49"] {
    left: 49%;
}
.scatter-plot .plot-container [data-cp-x="50"] {
    left: 50%;
}
.scatter-plot .plot-container [data-cp-x="51"] {
    left: 51%;
}
.scatter-plot .plot-container [data-cp-x="52"] {
    left: 52%;
}
.scatter-plot .plot-container [data-cp-x="53"] {
    left: 53%;
}
.scatter-plot .plot-container [data-cp-x="54"] {
    left: 54%;
}
.scatter-plot .plot-container [data-cp-x="55"] {
    left: 55%;
}
.scatter-plot .plot-container [data-cp-x="56"] {
    left: 56%;
}
.scatter-plot .plot-container [data-cp-x="57"] {
    left: 57%;
}
.scatter-plot .plot-container [data-cp-x="58"] {
    left: 58%;
}
.scatter-plot .plot-container [data-cp-x="59"] {
    left: 59%;
}
.scatter-plot .plot-container [data-cp-x="60"] {
    left: 60%;
}
.scatter-plot .plot-container [data-cp-x="61"] {
    left: 61%;
}
.scatter-plot .plot-container [data-cp-x="62"] {
    left: 62%;
}
.scatter-plot .plot-container [data-cp-x="63"] {
    left: 63%;
}
.scatter-plot .plot-container [data-cp-x="64"] {
    left: 64%;
}
.scatter-plot .plot-container [data-cp-x="65"] {
    left: 65%;
}
.scatter-plot .plot-container [data-cp-x="66"] {
    left: 66%;
}
.scatter-plot .plot-container [data-cp-x="67"] {
    left: 67%;
}
.scatter-plot .plot-container [data-cp-x="68"] {
    left: 68%;
}
.scatter-plot .plot-container [data-cp-x="69"] {
    left: 69%;
}
.scatter-plot .plot-container [data-cp-x="70"] {
    left: 70%;
}
.scatter-plot .plot-container [data-cp-x="71"] {
    left: 71%;
}
.scatter-plot .plot-container [data-cp-x="72"] {
    left: 72%;
}
.scatter-plot .plot-container [data-cp-x="73"] {
    left: 73%;
}
.scatter-plot .plot-container [data-cp-x="74"] {
    left: 74%;
}
.scatter-plot .plot-container [data-cp-x="75"] {
    left: 75%;
}
.scatter-plot .plot-container [data-cp-x="76"] {
    left: 76%;
}
.scatter-plot .plot-container [data-cp-x="77"] {
    left: 77%;
}
.scatter-plot .plot-container [data-cp-x="78"] {
    left: 78%;
}
.scatter-plot .plot-container [data-cp-x="79"] {
    left: 79%;
}
.scatter-plot .plot-container [data-cp-x="80"] {
    left: 80%;
}
.scatter-plot .plot-container [data-cp-x="81"] {
    left: 81%;
}
.scatter-plot .plot-container [data-cp-x="82"] {
    left: 82%;
}
.scatter-plot .plot-container [data-cp-x="83"] {
    left: 83%;
}
.scatter-plot .plot-container [data-cp-x="84"] {
    left: 84%;
}
.scatter-plot .plot-container [data-cp-x="85"] {
    left: 85%;
}
.scatter-plot .plot-container [data-cp-x="86"] {
    left: 86%;
}
.scatter-plot .plot-container [data-cp-x="87"] {
    left: 87%;
}
.scatter-plot .plot-container [data-cp-x="88"] {
    left: 88%;
}
.scatter-plot .plot-container [data-cp-x="89"] {
    left: 89%;
}
.scatter-plot .plot-container [data-cp-x="90"] {
    left: 90%;
}
.scatter-plot .plot-container [data-cp-x="91"] {
    left: 91%;
}
.scatter-plot .plot-container [data-cp-x="92"] {
    left: 92%;
}
.scatter-plot .plot-container [data-cp-x="93"] {
    left: 93%;
}
.scatter-plot .plot-container [data-cp-x="94"] {
    left: 94%;
}
.scatter-plot .plot-container [data-cp-x="95"] {
    left: 95%;
}
.scatter-plot .plot-container [data-cp-x="96"] {
    left: 96%;
}
.scatter-plot .plot-container [data-cp-x="97"] {
    left: 97%;
}
.scatter-plot .plot-container [data-cp-x="98"] {
    left: 98%;
}
.scatter-plot .plot-container [data-cp-x="99"] {
    left: 99%;
}
.scatter-plot .plot-container [data-cp-x="100"] {
    left: 100%;
}
.scatter-plot .plot-container [data-cp-y="0"] {
    bottom: 0%;
}
.scatter-plot .plot-container [data-cp-y="1"] {
    bottom: 1%;
}
.scatter-plot .plot-container [data-cp-y="2"] {
    bottom: 2%;
}
.scatter-plot .plot-container [data-cp-y="3"] {
    bottom: 3%;
}
.scatter-plot .plot-container [data-cp-y="4"] {
    bottom: 4%;
}
.scatter-plot .plot-container [data-cp-y="5"] {
    bottom: 5%;
}
.scatter-plot .plot-container [data-cp-y="6"] {
    bottom: 6%;
}
.scatter-plot .plot-container [data-cp-y="7"] {
    bottom: 7%;
}
.scatter-plot .plot-container [data-cp-y="8"] {
    bottom: 8%;
}
.scatter-plot .plot-container [data-cp-y="9"] {
    bottom: 9%;
}
.scatter-plot .plot-container [data-cp-y="10"] {
    bottom: 10%;
}
.scatter-plot .plot-container [data-cp-y="11"] {
    bottom: 11%;
}
.scatter-plot .plot-container [data-cp-y="12"] {
    bottom: 12%;
}
.scatter-plot .plot-container [data-cp-y="13"] {
    bottom: 13%;
}
.scatter-plot .plot-container [data-cp-y="14"] {
    bottom: 14%;
}
.scatter-plot .plot-container [data-cp-y="15"] {
    bottom: 15%;
}
.scatter-plot .plot-container [data-cp-y="16"] {
    bottom: 16%;
}
.scatter-plot .plot-container [data-cp-y="17"] {
    bottom: 17%;
}
.scatter-plot .plot-container [data-cp-y="18"] {
    bottom: 18%;
}
.scatter-plot .plot-container [data-cp-y="19"] {
    bottom: 19%;
}
.scatter-plot .plot-container [data-cp-y="20"] {
    bottom: 20%;
}
.scatter-plot .plot-container [data-cp-y="21"] {
    bottom: 21%;
}
.scatter-plot .plot-container [data-cp-y="22"] {
    bottom: 22%;
}
.scatter-plot .plot-container [data-cp-y="23"] {
    bottom: 23%;
}
.scatter-plot .plot-container [data-cp-y="24"] {
    bottom: 24%;
}
.scatter-plot .plot-container [data-cp-y="25"] {
    bottom: 25%;
}
.scatter-plot .plot-container [data-cp-y="26"] {
    bottom: 26%;
}
.scatter-plot .plot-container [data-cp-y="27"] {
    bottom: 27%;
}
.scatter-plot .plot-container [data-cp-y="28"] {
    bottom: 28%;
}
.scatter-plot .plot-container [data-cp-y="29"] {
    bottom: 29%;
}
.scatter-plot .plot-container [data-cp-y="30"] {
    bottom: 30%;
}
.scatter-plot .plot-container [data-cp-y="31"] {
    bottom: 31%;
}
.scatter-plot .plot-container [data-cp-y="32"] {
    bottom: 32%;
}
.scatter-plot .plot-container [data-cp-y="33"] {
    bottom: 33%;
}
.scatter-plot .plot-container [data-cp-y="34"] {
    bottom: 34%;
}
.scatter-plot .plot-container [data-cp-y="35"] {
    bottom: 35%;
}
.scatter-plot .plot-container [data-cp-y="36"] {
    bottom: 36%;
}
.scatter-plot .plot-container [data-cp-y="37"] {
    bottom: 37%;
}
.scatter-plot .plot-container [data-cp-y="38"] {
    bottom: 38%;
}
.scatter-plot .plot-container [data-cp-y="39"] {
    bottom: 39%;
}
.scatter-plot .plot-container [data-cp-y="40"] {
    bottom: 40%;
}
.scatter-plot .plot-container [data-cp-y="41"] {
    bottom: 41%;
}
.scatter-plot .plot-container [data-cp-y="42"] {
    bottom: 42%;
}
.scatter-plot .plot-container [data-cp-y="43"] {
    bottom: 43%;
}
.scatter-plot .plot-container [data-cp-y="44"] {
    bottom: 44%;
}
.scatter-plot .plot-container [data-cp-y="45"] {
    bottom: 45%;
}
.scatter-plot .plot-container [data-cp-y="46"] {
    bottom: 46%;
}
.scatter-plot .plot-container [data-cp-y="47"] {
    bottom: 47%;
}
.scatter-plot .plot-container [data-cp-y="48"] {
    bottom: 48%;
}
.scatter-plot .plot-container [data-cp-y="49"] {
    bottom: 49%;
}
.scatter-plot .plot-container [data-cp-y="50"] {
    bottom: 50%;
}
.scatter-plot .plot-container [data-cp-y="51"] {
    bottom: 51%;
}
.scatter-plot .plot-container [data-cp-y="52"] {
    bottom: 52%;
}
.scatter-plot .plot-container [data-cp-y="53"] {
    bottom: 53%;
}
.scatter-plot .plot-container [data-cp-y="54"] {
    bottom: 54%;
}
.scatter-plot .plot-container [data-cp-y="55"] {
    bottom: 55%;
}
.scatter-plot .plot-container [data-cp-y="56"] {
    bottom: 56%;
}
.scatter-plot .plot-container [data-cp-y="57"] {
    bottom: 57%;
}
.scatter-plot .plot-container [data-cp-y="58"] {
    bottom: 58%;
}
.scatter-plot .plot-container [data-cp-y="59"] {
    bottom: 59%;
}
.scatter-plot .plot-container [data-cp-y="60"] {
    bottom: 60%;
}
.scatter-plot .plot-container [data-cp-y="61"] {
    bottom: 61%;
}
.scatter-plot .plot-container [data-cp-y="62"] {
    bottom: 62%;
}
.scatter-plot .plot-container [data-cp-y="63"] {
    bottom: 63%;
}
.scatter-plot .plot-container [data-cp-y="64"] {
    bottom: 64%;
}
.scatter-plot .plot-container [data-cp-y="65"] {
    bottom: 65%;
}
.scatter-plot .plot-container [data-cp-y="66"] {
    bottom: 66%;
}
.scatter-plot .plot-container [data-cp-y="67"] {
    bottom: 67%;
}
.scatter-plot .plot-container [data-cp-y="68"] {
    bottom: 68%;
}
.scatter-plot .plot-container [data-cp-y="69"] {
    bottom: 69%;
}
.scatter-plot .plot-container [data-cp-y="70"] {
    bottom: 70%;
}
.scatter-plot .plot-container [data-cp-y="71"] {
    bottom: 71%;
}
.scatter-plot .plot-container [data-cp-y="72"] {
    bottom: 72%;
}
.scatter-plot .plot-container [data-cp-y="73"] {
    bottom: 73%;
}
.scatter-plot .plot-container [data-cp-y="74"] {
    bottom: 74%;
}
.scatter-plot .plot-container [data-cp-y="75"] {
    bottom: 75%;
}
.scatter-plot .plot-container [data-cp-y="76"] {
    bottom: 76%;
}
.scatter-plot .plot-container [data-cp-y="77"] {
    bottom: 77%;
}
.scatter-plot .plot-container [data-cp-y="78"] {
    bottom: 78%;
}
.scatter-plot .plot-container [data-cp-y="79"] {
    bottom: 79%;
}
.scatter-plot .plot-container [data-cp-y="80"] {
    bottom: 80%;
}
.scatter-plot .plot-container [data-cp-y="81"] {
    bottom: 81%;
}
.scatter-plot .plot-container [data-cp-y="82"] {
    bottom: 82%;
}
.scatter-plot .plot-container [data-cp-y="83"] {
    bottom: 83%;
}
.scatter-plot .plot-container [data-cp-y="84"] {
    bottom: 84%;
}
.scatter-plot .plot-container [data-cp-y="85"] {
    bottom: 85%;
}
.scatter-plot .plot-container [data-cp-y="86"] {
    bottom: 86%;
}
.scatter-plot .plot-container [data-cp-y="87"] {
    bottom: 87%;
}
.scatter-plot .plot-container [data-cp-y="88"] {
    bottom: 88%;
}
.scatter-plot .plot-container [data-cp-y="89"] {
    bottom: 89%;
}
.scatter-plot .plot-container [data-cp-y="90"] {
    bottom: 90%;
}
.scatter-plot .plot-container [data-cp-y="91"] {
    bottom: 91%;
}
.scatter-plot .plot-container [data-cp-y="92"] {
    bottom: 92%;
}
.scatter-plot .plot-container [data-cp-y="93"] {
    bottom: 93%;
}
.scatter-plot .plot-container [data-cp-y="94"] {
    bottom: 94%;
}
.scatter-plot .plot-container [data-cp-y="95"] {
    bottom: 95%;
}
.scatter-plot .plot-container [data-cp-y="96"] {
    bottom: 96%;
}
.scatter-plot .plot-container [data-cp-y="97"] {
    bottom: 97%;
}
.scatter-plot .plot-container [data-cp-y="98"] {
    bottom: 98%;
}
.scatter-plot .plot-container [data-cp-y="99"] {
    bottom: 99%;
}
.scatter-plot .plot-container [data-cp-y="100"] {
    bottom: 100%;
}
