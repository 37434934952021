@use "sass/core/variables";
@use "sass/core/utils";

md-tabs.social-profile-tabs {
    min-height: 0 !important; // bug fixing to angular material 0.10.1 (https://github.com/angular/material/issues/4120)
    height: 43px;

    & > md-tabs-wrapper,
    md-tabs-canvas,
    md-pagination-wrapper {
        height: 43px;
    }

    &.short-tabs {
        margin-right: 100px;
    }

    md-ink-bar {
        @include utils.hide;
    }

    .md-tab {
        padding: 9px 2px;
    }

    md-tabs-canvas:not(.md-paginated) {
        .md-tab:first-child {
            padding-left: 16px;
        }
    }

    md-tabs-canvas.md-paginated {
        .md-tab:first-child {
            padding-left: 0;
        }
    }
}

md-tabs:not(.social-profile-tabs) {
    background-color: variables.$toolbar-background-color;
    height: 64px;
    border-radius: 0;

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        background-color: variables.$white-color !important;
        color: variables.$text-color !important;
        margin-left: 4px;
    }

    & > md-tabs-wrapper,
    md-tabs-canvas,
    md-pagination-wrapper {
        background-color: transparent !important;
        min-height: 64px;
    }

    md-tabs-canvas md-pagination-wrapper {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            #account &,
            #company & {
                // Negate material dynamically calculating the width
                // which is equal to the width of all the tab items
                // but we want to stretch the wrapper
                width: 100%;
            }
        }
    }

    &.insights {
        @include utils.font-size(16, true);
    }

    .md-tab {
        color: variables.$tab-color;
        text-transform: none;
        padding: 20px 0;

        .md-ripple-container {
            display: none;
        }

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            @include utils.font-size(12, true);

            color: variables.$text-color;
            padding: 9px 12px 6px; // This was '8px 0' but to remove the random 1px that came above the icons in tabs we set padding-top to 9px

            md-icon {
                padding: 2px 0;
                width: 36px;
                height: 28px;
            }
        }

        &.md-active {
            background-color: variables.$tab-active-bg-color;
            color: variables.$white-color;

            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                background-color: variables.$white-color;
                color: variables.$text-color;

                md-icon,
                md-icon:before {
                    color: variables.$text-color;
                }

                md-icon {
                    background-color: variables.$highlight-color;
                    border-radius: 4px;
                }
            }
        }

        &:hover {
            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                md-icon {
                    background-color: variables.$hover-color;
                    border-radius: 4px;
                }
            }
        }

        &.md-disabled {
            opacity: 1;
        }
    }

    md-ink-bar {
        @include utils.hide;
    }

    md-tabs-wrapper {
        md-prev-button,
        md-next-button {
            @include utils.hide;
        }
    }
}

md-tabs.avatar > md-tabs-wrapper {
    @media screen and (max-width: variables.$layout-breakpoint-xs-max) {
        padding-right: 100px;
    }
}

md-toolbar > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) {
    md-icon {
        color: variables.$tab-color;
    }

    &.md-active md-icon {
        color: variables.$white-color;
    }
}

@media screen and (max-width: variables.$layout-breakpoint-xs-max) {
    #account md-toolbar md-tabs .md-tab,
    #company md-toolbar md-tabs .md-tab {
        line-height: 1;
    }
}
