@use "sass/core/variables";

#team-settings {
    .scrollable-content {
        display: block;
        overflow-y: auto;
        position: relative;
    }

    .mat-form-field-prefix {
        display: flex;
        justify-content: flex-end;
    }

    md-content,
    .scrollable-content {
        padding-bottom: 100px;
    }

    .team-settings {
        md-switch {
            padding: 0 16px;
        }
    }

    md-select.inline-role-dropdown {
        width: auto;
        border: none;
        top: 2px;

        md-select-value {
            color: rgb(0 0 0 / 87%);
            top: 3px;
            border-style: none;
            min-width: 0;
            padding: 2px 0;
            min-height: 16px;
        }

        .md-select-value .md-select-icon {
            text-align: start;
            margin: 0 2px;
        }
    }

    .no-outstanding-invites-text {
        margin: 16px;
    }

    md-list-item {
        span {
            margin-right: 0;
        }

        &.user-disabled {
            sf-avatar,
            h1 {
                opacity: 0.25;
            }

            .md-secondary-container {
                .md-button {
                    color: variables.$blue-color;
                }
            }
        }

        .md-secondary-container {
            .disable-btn {
                color: variables.$red-color;
            }
        }

        .md-menu {
            .md-button {
                margin-bottom: 2px;
            }
        }
    }

    .md-subheader {
        .pipelines-text {
            margin-left: 4px;
        }

        .md-subheader-inner {
            min-width: 0; // https://makandracards.com/makandra/66994-css-flex-and-min-width

            .md-secondary-container md-icon:last-child {
                margin-right: -3px;
            }

            .group-count {
                margin-left: 6px;
            }

            md-icon {
                color: variables.$salesflare-color;
            }
        }
    }
}
