@use "sass/core/variables";

.custom-fields {
    width: 100%;

    md-input-container {
        width: 100%;

        // For some inputs like md-chips, md-required doesn't get applied to the label, so we need to do it manually.
        label:not(.md-container-ignore).manual-required-class:after {
            content: " *";
            font-size: 13px;
            vertical-align: top;
        }

        // For some inputs like md-chips, md-required doesn't get applied to the label, so we need to do it manually.
        label:not(.md-container-ignore).manual-error-class.form-submitted {
            color: rgb(213 0 0);
        }

        .manual-error-message {
            color: rgb(213 0 0);

            div {
                font-size: 12px;
                line-height: 14px;
                overflow: hidden;
                transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
                opacity: 1;
                margin-top: 0;
                padding-top: 5px;
                padding-right: 5px;
                padding-left: 0;
            }
        }
    }

    md-datepicker {
        padding-right: 0;
    }

    md-chips.ng-invalid.form-submitted {
        md-chips-wrap {
            box-shadow: 0 1px rgb(213 0 0);
        }
    }

    .entity-custom-autocomplete-container {
        md-autocomplete.entity-custom-autocomplete {
            z-index: 3; // Show over chart builder workspace content. First child table rows have a z-index of 2
            min-width: 240px;

            &:not(.without-search-icon) {
                background-image: url("../../images/sf-icon-search.png");
                background-repeat: no-repeat;
                background-position-x: 2px;
                background-position-y: 12px;

                md-autocomplete-wrap {
                    padding-left: 23px;
                }
            }

            md-autocomplete-wrap {
                box-shadow: none;
                border-bottom: 1px rgb(0 0 0 / 12%) solid;

                input {
                    padding-right: variables.$default-spacing; // To make up for the arrow-down, so we ellipsis in time.
                    height: 40px; // Aligns the placeholder with the "label" better.
                    order: initial;
                    border: none;
                }

                // Using not(.ng-not-empty) here because ng-empty sometimes gets wrongly used
                input:not(.ng-not-empty) {
                    background-image: url("../../images/arrow_down_gray.png");
                    background-repeat: no-repeat;
                    background-position-x: right;
                    background-position-y: 8px;
                }
            }
        }
    }

    .entity-custom-autocomplete-container.md-input-invalid {
        md-autocomplete.entity-custom-autocomplete {
            md-autocomplete-wrap {
                border-bottom: 1px rgb(213 0 0) solid;
            }
        }
    }
}

.custom-field-row {
    margin: 8px 0 16px 16px;

    md-icon {
        color: variables.$text-color;
    }

    md-icon.extendable {
        margin-top: -6px;
    }

    span.multi-line {
        word-wrap: break-word;
    }

    span {
        &.accent.ellipsis {
            margin-right: 2px;
        }
    }
}
