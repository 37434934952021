@use '@angular/material' as mat;
@use 'palettes';

$sf-toolbar-typography: mat.define-typography-config(
        $font-family: var(--default-font),
        $headline-6:  mat.define-typography-level(16px, 24px, 400, $letter-spacing: .03rem),
);

/* Set angular material toolbar theme */
$sf-toolbar-primary: mat.define-palette(palettes.$sf-white-palette, 500);
$sf-toolbar-accent: mat.define-palette(palettes.$sf-darkblue-palette, 500);
$sf-toolbar-warn: mat.define-palette(palettes.$sf-blue-palette, 0);
$sf-toolbar-theme: mat.define-light-theme(
                (
                        color: (
                                primary: $sf-toolbar-primary,
                                accent: $sf-toolbar-accent,
                                warn: $sf-toolbar-warn
                        ),
                        typography: $sf-toolbar-typography
                )
);