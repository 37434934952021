@use "sass/core/variables";

#upgrade-confirmation-dialog {
    max-height: 85%;

    .text {
        padding: 0 variables.$default-spacing;
        max-width: 500px;

        li {
            margin-top: 8px;
        }
    }
}
