.mat-mdc-menu-panel {
    .mat-mdc-menu-item {
        --mdc-typography-body1-font-size: 15px;

        .mat-mdc-menu-item-accent {
            color: var(--sf-light-blue);

            &.mat-icon {
                margin: auto 8px auto 0;
            }

            --mdc-theme-text-primary-on-background: var(--sf-light-blue);
            --mdc-typography-body1-font-size: 15px;
            --mdc-typography-body1-line-height: 36px;
            --mdc-typography-body1-letter-spacing: 0.01em;
        }
    }
}

.mat-mdc-select-panel {
    .mat-mdc-option, .mdc-list-item {
        font-size: 14px;
        font-weight: 400;
        line-height: 42px;
    }
}


.cdk-overlay-pane {

    .inline-panel {
        min-width: fit-content;

        .mdc-list-item__primary-text {
            white-space: nowrap;
        }
    }
}
