@use "sass/core/variables";

#accounts {
    // The min height is here for a iOS issue: https://github.com/Salesflare/Client/issues/917
    min-height: 100%;

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        width: 360px;
        flex: none;

        md-list-item {
            background-color: variables.$gray-color;

            div.md-button {
                padding: 0;
                margin: 0 16px;

                .md-ripple-container {
                    display: none;
                }

                button {
                    background-color: transparent;
                }

                .md-list-item-inner {
                    min-height: initial;
                    padding: 8px;
                    width: 95%;

                    .list-item-detail {
                        md-icon {
                            margin-right: 6px;
                        }
                    }
                }
            }
        }

        md-list-item:hover {
            div.md-button {
                .md-list-item-inner {
                    background-color: variables.$hover-color;
                }
            }
        }

        md-list-item.active {
            div.md-button {
                .md-list-item-inner {
                    background-color: variables.$highlight-color;
                }
            }
        }

        md-list-item:hover,
        md-list-item.active {
            div.md-button {
                background-color: transparent;

                .md-list-item-inner {
                    border-radius: 8px;
                    flex: 1;
                }
            }
        }
    }

    &.full-screen {
        width: 100%;
    }

    md-toolbar .md-toolbar-tools {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            #tabs-filler {
                height: 100%;
                width: calc(100% - 180px - 24px - 24px - 24px - 24px); // fix for offsetting against the tabs, the tabs are not made to put something next to them (180 are the tabs, 24 for every button on the right)
            }
        }

        .new-count-container.suggestions .new-count-badge {
            right: -12px;
        }
    }

    .view-new-suggestions-button-container {
        padding: variables.$default-spacing * 0.5 0;

        button.new-suggestions-button {
            text-transform: none;
        }
    }

    .suggested-account {
        .md-secondary-container {
            button.md-icon-button.md-button {
                height: 32px;
                width: 32px;
            }

            #accept-account-suggestion {
                color: green;
            }

            #reject-account-suggestion:not(.rejected) {
                color: red;
            }
        }
    }
}
