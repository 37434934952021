@use "sass/core/variables";
@use "sass/core/utils";

md-toolbar {
    flex: none;
    z-index: 4; // make sure the avatar that is a child of this on account info shows above the subheader

    &:not(.md-menu-toolbar) {
        color: variables.$white-color;
        background-color: variables.$salesflare-color;

        button {
            color: variables.$white-color;
        }

        button[disabled] {
            color: rgb(255 255 255 / 70%);
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        font-size: variables.$font-size-14 + px;

        &:not(.md-menu-toolbar) {
            background-color: variables.$white-color;
            color: variables.$text-color;
        }

        md-select,
        md-datepicker {
            // custom margins so the elements appear vertically in the middle
            margin-top: 25px;
            margin-left: 4px;
            width: auto;
        }

        md-datepicker {
            // even more custom :D
            margin-bottom: 16px;
        }

        md-input-container .md-input {
            width: auto;
        }
    }

    .md-toolbar-tools {
        font-size: variables.$font-size-14 + px;

        &.right {
            margin-left: auto;
        }

        h1 {
            @include utils.font-size(16);

            margin-right: 8px;
            line-height: 24px;
        }

        h2 {
            @include utils.font-size(16, true);
            @include utils.letter-spacing(30);

            color: variables.$toolbar-h2-color;
            font-weight: 400 !important;
        }

        md-menu > .md-button {
            margin-left: 0; // Normally this is like 6px but in the md-menu it causes the button the be 46 width instead of the 40 we want
        }

        > md-icon:first-child {
            margin: 0 16px 0 0;
        }

        div.search-mode {
            @include utils.font-size(16);
            @include utils.letter-spacing(30);

            margin: 0 11px 0 0;
            font-weight: 700;

            .search-input {
                background-color: variables.$search-input-color;
                border-color: transparent !important;
                border-radius: 5px;

                &.md-has-icon {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                & > input {
                    width: 100%;
                    color: variables.$white-color !important;
                    border: 0;
                    padding: 0 0 0 1px;

                    @media screen and (min-width: variables.$layout-breakpoint-sm) {
                        color: variables.$text-color !important;
                    }
                }

                & > md-icon {
                    top: 4px;
                }
            }
        }
    }
}

// Because angular material overrides the color of md-icon I made it more specific to let it work
md-toolbar:not(.md-menu-toolbar) { /* stylelint-disable-line no-duplicate-selectors */
    md-icon {
        text-align: center;
        color: variables.$white-color;
        padding: 0 2px;

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            color: variables.$text-color;
        }

        &.sf-icon-save {
            color: variables.$green-color;
        }
    }

    md-tab-item {
        md-icon {
            color: variables.$text-color;
        }
    }
}

md-toolbar.avatar sf-avatar {
    margin: 0;
    z-index: 2000;
}

md-sidenav md-toolbar .md-toolbar-tools h2 {
    color: variables.$salesflare-color;
}

.lower-toolbar {
    @media screen and (max-width: variables.$layout-breakpoint-xs-max) {
        background-color: variables.$toolbar-background-color;
    }
}

md-dialog .lower-toolbar {
    background-color: variables.$toolbar-background-color;
}
