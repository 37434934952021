@use "sass/core/variables";

#onlineOffline {
    p {
        color: variables.$text-color;
    }

    md-progress-circular {
        margin: 20px;
    }
}
