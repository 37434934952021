$import-upload-padding: 0;
$import-upload-actions-width: 120px;

sf-import-upload .button {
    appearance: button;

    /* Safari and Chrome */
    padding: 10px;
    margin: 10px;
    width: 70px;
}

.drop-box {
    background: #f8f8f8;
    border: 5px dashed #ddd;
    width: 200px;
    height: 65px;
    text-align: center;
    padding-top: 25px;
    margin: 10px;
}
