/* sf-avatar */

@use "sass/core/variables";
@use "sass/core/utils";

sf-avatar,
app-avatar {
    margin: auto 16px auto 0;

    &.sf-avatar_tiny {
        margin-right: 2px;

        .sf-avatar_face {
            width: 12px;
            height: 12px;
        }
    }

    &.sf-avatar_mini .sf-avatar_face {
        width: 24px;
        height: 24px;
    }

    &.sf-avatar_small .sf-avatar_face {
        width: 32px;
        height: 32px;
    }

    &.sf-avatar_large .sf-avatar_face {
        width: 48px;
        height: 48px;
    }

    .sf-avatar_face {
        background-color: variables.$white-color;
        background-repeat: repeat-x;
        border-style: hidden;
        background-size: contain;
        background-position: center;
        border-radius: 50%;
        align-self: center;
        position: relative;

        h2 {
            color: variables.$white-color;
            font-size: 12px;
            text-transform: uppercase;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 80px;
            text-align: center;
            transform: translate(-50%, -50%);

            md-icon {
                margin-left: -6px;
                margin-bottom: 2px;
                margin-right: 4px;
                color: variables.$white-color;
            }
        }
    }
}

.icon.avatar {
    @include utils.font-size(34);

    text-align: center;
    width: 48px;
    height: 48px;
}

.sf-avatar_face {
    width: 32px;
    height: 32px;
}

sf-avatar[ng-click]:not([disabled="disabled"]),
sf-avatar[ui-sref]:not([disabled="disabled"]) {
    -webkit-touch-callout: none;
    user-select: none;
    cursor: pointer;
}
