@use 'css-fx-layout' as fx;

@forward 'angular-material/main';

@forward 'components/main';

//Done because of some weird behaviour when using md-dialog with Angular. Can probably be removed when we would use mat-dialogs
html {
    overflow-y: auto !important;
}

// If you want to include the responsive API selectors
$includeResponsiveApi: true;

// If you choose to use attribute selectors:
@include fx.attribute-selectors($includeResponsiveApi);
@include fx.gap-attribute-selectors-from-list(4 8 16, px em, $includeResponsiveApi);
@include fx.flex-size-attribute-selectors(5, 100, 5, $includeResponsiveApi);
@include fx.flex-size-attribute-selectors-from-list(33 66, $includeResponsiveApi);

// Fix for flexbox gap on iOS < 14.5
@supports (-webkit-touch-callout: none) and (not (translate: none)) {
    @for $i from 1 through 4 {
        [data-layout-gap="#{$i * 4}px"] > *:not(:last-child) {
            margin-right: #{$i * 4}px;
          }
    }
}

[hidden] {
    display: none !important;
}
