@use '@angular/material' as mat;
@use 'palettes';

$sf-button-typography: mat.define-typography-config(
        $font-family: var(--default-font),
        $button:  mat.define-typography-level(14px, 36px, 500),
);

/* Set angular material button theme */
$sf-button-primary: mat.define-palette(palettes.$sf-button-palette, 500);
$sf-button-accent: mat.define-palette(palettes.$sf-button-palette, 500);
$sf-button-warn: mat.define-palette(palettes.$sf-button-palette, 600);

$sf_button-theme: mat.define-light-theme(
                (
                        color: (
                                primary: $sf-button-primary,
                                accent: $sf-button-accent,
                                warn: $sf-button-warn
                        ),
                        typography: $sf-button-typography
                )
);

@for $i from 1 through 50 {

    .button-#{$i}.mat-mdc-button-base.mat-mdc-button-base {

        width: $i + px;
        height: $i + px;
        line-height: $i + px;
        padding: 0;
    }
}

.mdc-button.mat-mdc-button-base {
    .mdc-button__label {
        width: 100%;
        text-transform: uppercase;
    }

}

.mdc-icon-button.mat-mdc-icon-button.mat-mdc-icon-button {

    display: flex;
    justify-content: center;
    align-items: center;

    &[disableripple] {

        .mat-mdc-button-persistent-ripple.mdc-icon-button__ripple,
        .mat-mdc-button-persistent-ripple.mdc-button__ripple,
        .mat-mdc-focus-indicator
        {
            display: none;
        }

    }
}

// Fallback for unthemed buttons.
.mat-unthemed.mat-mdc-button.mat-mdc-button, .mat-unthemed.mat-mdc-icon-button.mat-mdc-icon-button  {

    &.mat-mdc-button-base {
        --mdc-text-button-label-text-color: var(--sf-light-blue);
        --mdc-text-button-disabled-label-text-color: var(--sf-disabled-grey);
        --mdc-icon-button-disabled-icon-color: var(--sf-disabled-grey);
    }

    &.mat-mdc-unelevated-button, .mat-mdc-raised-button {
        background-color: var(--sf-light-blue);
        --mdc-text-button-label-text-color: var(--sf-white);
        --mdc-text-button-disabled-label-text-color: var(--sf-disabled-grey);
        --mdc-icon-button-disabled-icon-color: var(--sf-disabled-grey);
    }

    &.mat-mdc-raised-button.mat-primary {
        --mdc-protected-button-label-text-color: var(--sf-white);
        --mdc-text-button-disabled-label-text-color: var(--sf-disabled-grey);
        --mdc-icon-button-disabled-icon-color: var(--sf-disabled-grey);
    }
}

.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
    background-color: rgb(195, 190, 215, 0.2);
    opacity: 1 !important;
}