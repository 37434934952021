@use "sass/core/variables";

sf-dashboards-filter {
    .filter-container {
        padding: 0 variables.$default-spacing;
    }

    .label.dashboards-filter-label {
        padding: variables.$default-spacing variables.$default-spacing 0 variables.$default-spacing;
    }

    md-select {
        &.range-selector,
        &.quarter-selector,
        &.year-selector,
        &.owner-selector {
            width: 90px;
            margin-right: variables.$default-spacing * 0.25;
            margin-left: 0; // override default angular spacing that is applied on dashboard view
        }

        &.month-selector {
            width: 120px;
        }
    }
}
