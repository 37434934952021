@use "sass/core/variables";
@use "sass/core/utils";

// Shared styling to style a workflows config form (both edit and view)
// This to reduce duplication and pain keeping workflow and workflow compose dialog in sync

// If you edit this check:
// - workflow create from FAB
// - workflow create from workflow list
// - workflow create from bulk actions (contacts list)

.workflow-config {
    margin-bottom: variables.$default-spacing;
    padding: 0;
    border-radius: 0 0 4px 4px;
    border-bottom: 1px solid variables.$light-gray-color;

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        border-right: 1px solid variables.$light-gray-color;
        border-left: 1px solid variables.$light-gray-color;
    }

    // Used to style an input container for workflows
    // They are special due to being visually more inline
    md-input-container {
        margin: 0;
        padding: 0;
    }

    .padding-div {
        padding: 0 variables.$default-spacing;
    }

    .hidden-form-element {
        display: none;
    }

    .send-schedule_inline-span {
        // Provide some spacing left and right, otherwise inputs stick to the text
        @media screen and (min-width: variables.$layout-breakpoint-xs) {
            margin-left: variables.$default-spacing * 0.5;
        }

        margin-right: variables.$default-spacing * 0.5;
    }
}

#workflowDetailsContainer .workflow-config {
    background-color: variables.$white-color;
}

.workflow-input-container {
    margin: 16px 0;
    padding: 0;

    // Used to style an input container for workflows
    // They are special due to being visually more inline
    md-input-container {
        margin: 0;
        padding: 0;
    }

    md-switch {
        height: fit-content;
        margin: 0 0 (variables.$default-spacing * 0.5) 0;

        > .md-container {
            min-width: 44px;
        }
    }

    &.date-and-time-container {
        display: flex;
        flex-wrap: wrap;

        .date-and-time {
            margin-right: variables.$default-spacing * 0.5;
        }
    }

    .div-with-margin {
        margin: auto 0;
    }

    input {
        line-height: 16px;
        height: 16px;
        padding: 0;
        margin-top: 1px;
    }

    md-select {
        line-height: 20px;
        height: 20px;
        padding: 0;
        background-size: 0;

        &:not(#daysToSendOn) {
            max-width: 200px;
            width: fit-content;
        }

        &#workflowGoal {
            max-width: 95%;
            width: fit-content;
        }

        .md-select-value {
            color: variables.$reset-text-color;
            padding: 0;
            min-height: 0;

            > span:not(.md-select-icon) {
                @include utils.font-size(14);
            }
        }
    }

    .md-errors-spacer {
        display: none;
    }

    md-select,
    md-checkbox {
        margin: 0;

        .md-label {
            line-height: 1; // Attempt to vertically align the label with the checkbox
        }
    }

    .label {
        // This is terrible contrast wise but since the text is also not there with the contrast we have to do this to make the label less important visually
        color: rgb(0 0 0 / 38%);
        margin: auto (variables.$default-spacing * 0.5) auto 0;
        white-space: nowrap;

        &.no-wrap {
            white-space: nowrap;
        }

        md-icon.not-editing {
            color: rgb(0 0 0 / 38%);
        }

        + div {
            &.with-margin {
                margin: auto (variables.$default-spacing * 0.5) auto 0;
            }
        }
    }

    @media screen and (max-width: variables.$layout-breakpoint-md) {
        .content {
            margin-top: 8px;
        }
    }

    & > div {
        margin: 0 (variables.$default-spacing * 0.5) 0 0;
    }

    md-datepicker.inline-datepicker {
        padding-left: 8px;
        line-height: 20px;
        height: 19px;

        .md-datepicker-input-container {
            height: 100%; // Vertically align input
        }

        .md-datepicker-input {
            height: 100%; // Vertically align input
        }

        .md-button.md-icon-button {
            // Move triangle a bit closer to input text
            // This makes it so spacing between date picker text and icon vs icon and next element is more consistent

            margin-right: 10px;
        }

        .md-datepicker-triangle-button {
            bottom: -10px;
        }
    }
}
