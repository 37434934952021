@use "sass/core/variables";
@use "sass/core/utils";

.md-sidenav-right {
    display: flex;
    background-color: variables.$gray-color;
    max-width: 360px;
    width: 360px;

    md-list {
        background-color: variables.$gray-color;
    }

    md-list-item {
        margin-bottom: 0;
    }

    md-divider {
        border-top-color: variables.$light-gray-color !important;
    }

    &.shadow {
        box-shadow: -1px 0 4px 1px rgb(0 0 0 / 15%);
    }
}

md-sidenav {
    background-color: variables.$sidenav-bg-color;
    color: variables.$sidenav-color;
    max-width: 248px;

    & > md-content {
        background-color: variables.$sidenav-bg-color;
        color: variables.$sidenav-content-color;
    }

    md-toolbar {
        span {
            margin: 0 19px;
        }
    }

    md-divider {
        border-top-color: variables.$sidenav-divider-color !important;
    }

    md-list {
        background-color: variables.$dark-blue-color;

        md-list-item {
            h3 {
                @include utils.font-size(14, true);
            }

            p {
                @include utils.font-size(14, true);
            }

            &.active {
                md-icon:before {
                    color: variables.$white-color !important;
                }

                p {
                    color: variables.$white-color !important;
                }
            }

            &.sf-profile {
                padding: 0 11px;
                background-color: variables.$toolbar-background-color;

                h1 {
                    color: variables.$white-color !important;
                }

                h2 {
                    color: variables.$sidenav-profile-h2-color !important;
                }

                sf-avatar {
                    margin-right: 11px;
                }
            }
        }
    }
}
