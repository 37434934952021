@use "sass/core/variables";

.medium-hot {
    color: variables.$yellow-color;
    margin-left: 2px;
}

.very-hot {
    color: variables.$orange-color;
}
