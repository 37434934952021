@use "sass/core/variables";
@use "sass/core/utils";

sf-card {
    div.stripe-error {
        color: variables.$error-color;

        // copy from md's ng-message
        font-size: 12px;
        line-height: 14px;
        overflow: hidden;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        margin-top: 0;
        padding-top: 5px;
        clear: left;
    }

    label {
        pointer-events: none;
    }

    .sf-card-form .vat-info {
        @include utils.font-size(14);

        color: variables.$text-color;
        margin-top: 0;
        margin-bottom: variables.$default-spacing;
    }
}
