@use "sass/core/variables";
@use "sass/core/utils";

#deals {
    & > div {
        margin: auto;
        text-align: center;
    }

    md-toolbar {
        h1 {
            @include utils.font-size(16);
        }
    }

    h1 {
        @include utils.font-size(32);
    }

    .md-button {
        margin: 16px;
    }

    .md-button:not([disabled]) {
        color: #fff;
        background-color: variables.$blue-color;
    }

    div.buttons {
        margin: auto;
        max-width: 400px;
    }
}
