@use "sass/core/variables";

md-bottom-sheet {
    md-list md-list-item {
        background-color: rgb(250 250 250);
    }

    button,
    a {
        .md-secondary-container md-icon,
        md-icon {
            margin-right: 8px;
            color: variables.$blue-color;
        }

        span {
            vertical-align: middle;
        }
    }
}
