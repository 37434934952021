@use "sass:math";
@use "sass/core/variables";

sf-workflow-step {
    .workflow-step-title {
        padding-left: variables.$default-spacing;
        color: #3d4d65;
        background-color: white;

        @media screen and (max-width: variables.$layout-breakpoint-sm) {
            border-top: 1px solid variables.$light-gray-color;
            border-bottom: 1px solid variables.$light-gray-color;
        }

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            color: variables.$white-color;
            background-color: variables.$salesflare-color;
            flex: none;
            border-radius: 4px 4px 0 0;

            .md-icon-button {
                .sf-icon-delete {
                    color: variables.$white-color;
                }
            }
        }

        .md-icon-button {
            margin: auto 0 auto auto;
        }
    }

    .workflow-info-container {
        min-height: variables.$default-spacing * 2;

        @media screen and (max-width: variables.$layout-breakpoint-xs) {
            padding: 6px variables.$default-spacing;
        }

        .workflow-info-item {
            margin-right: variables.$default-spacing;

            @media screen and (max-width: variables.$layout-breakpoint-xs) {
                padding: 2px 0;
                margin-right: 0;
            }

            md-icon {
                color: variables.$blue-color;
                margin-right: 4px;
            }

            span {
                font-size: 12px;
                color: variables.$salesflare-color;
                vertical-align: middle;
            }
        }
    }

    .workflow-input-container {
        .number-of-days {
            margin: auto 4px auto 0;
        }

        .trigger-step {
            margin: 0 variables.$default-spacing * 0.5;
        }

        .no-edit-action-string {
            margin: auto variables.$default-spacing * 0.25 auto 0;
        }
    }

    .step-configuration {
        background: variables.$white-color;
        border-radius: 0 0 4px 4px;
        border-bottom: 1px solid variables.$light-gray-color;

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            border-right: 1px solid variables.$light-gray-color;
            border-left: 1px solid variables.$light-gray-color;
        }

        .workflow-step-config-form {
            padding-left: 0;
            padding-right: 0;

            /*
            input {
                margin: auto 0;
                padding: 0;
            }
*/

            .jodit-container:not(.jodit_inline) .jodit-workplace {
                background-color: variables.$white-color;
            }

            .jodit-container {
                .jodit-toolbar__box {
                    &:not(:empty) {
                        background-color: variables.$white-color;

                        .jodit-toolbar-editor-collection,
                        .jodit-toolbar-editor-collection:after {
                            background-color: variables.$white-color;
                        }

                        .jodit-statusbar {
                            background-color: variables.$white-color;
                        }
                    }

                    .jodit-toolbar-editor-collection,
                    .jodit-toolbar-collection {
                        .jodit-toolbar-button > button {
                            background-color: variables.$white-color;
                        }
                    }
                }
            }
        }

        .step-standard-configuration {
            padding: 0 variables.$default-spacing;
        }

        form {
            padding: 0 variables.$default-spacing;
            padding-bottom: 0;
        }

        #trigger-step {
            margin-left: variables.$default-spacing * 0.25;
        }
    }
}
