@use "sass/core/variables";

#suggested-accounts {
    // The min height is here for a iOS issue: https://github.com/Salesflare/Client/issues/917
    min-height: 100%;

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        width: 360px;
        flex: none;

        md-list-item {
            background-color: variables.$gray-color;

            div.md-button {
                padding: 0;
                margin: 0 16px;

                .md-ripple-container {
                    display: none;
                }

                button {
                    background-color: transparent;
                }

                .md-list-item-inner {
                    min-height: initial;
                    padding: 4px 8px;
                    width: 95%;
                }
            }
        }

        md-list-item:hover,
        md-list-item.active {
            div.md-button {
                background-color: transparent;

                .md-list-item-inner {
                    border-radius: 4px;
                    background-color: variables.$highlight-color;
                    flex: 1;
                }
            }
        }
    }

    &.full-screen {
        width: 100%;
    }

    .new-count-container.suggestions .new-count-badge {
        right: -12px;
    }

    // Add padding-bottom to the list to prevent the FAB buttom from covering some of suggested account list items
    @media screen and (max-width: variables.$layout-breakpoint-sm) {
        md-list.list {
            padding-bottom: 96px;
        }
    }

    .suggested-account {
        .md-secondary-container {
            button.md-icon-button.md-button {
                height: 32px;
                width: 32px;
            }

            #accept-account-suggestion {
                color: green;
            }

            #reject-account-suggestion:not(.rejected) {
                color: red;
            }
        }
    }
}
