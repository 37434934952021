@use "sass/core/variables";

.switch-button {
    background-color: variables.$switch-bg-color;
    margin-bottom: 30px;

    &.selected {
        color: variables.$white-color;
        background-color: variables.$blue-color !important;
    }

    &.left {
        border-radius: 3px 0 0 3px;
    }

    &.right {
        border-radius: 0 3px 3px 0;
    }
}
