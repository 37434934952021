@use "sass/core/variables";

sf-permissions {
    md-card-content {
        h3 {
            margin-top: 0;
        }
    }

    .role-permission,
    .pipeline-permissions {
        padding: 0;
        margin: 2px 0;

        .label {
            margin: auto variables.$default-spacing auto 0;
            width: 140px;
        }

        &.pipeline-permissions > .label {
            width: 180px;
        }

        &.pipeline-permissions > md-input-container > md-select {
            max-width: 360px;
        }

        @media screen and (max-width: variables.$layout-breakpoint-xs) {
            &.pipeline-permissions > md-input-container > md-select {
                max-width: 200px;
            }

            &.pipeline-permissions > .label {
                width: 100px;
            }
        }
    }

    .explanation {
        margin-top: 16px;
        padding: 0;
    }
}
