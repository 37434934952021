@use "sass/core/variables";

.dropdown-menu {
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
    border: 1px solid variables.$load-more-color;
    background-color: variables.$white-color;
    list-style: none;
    padding: 0;
    margin: 0;

    // min width to make sure the menu doesn't become very small
    min-width: 400px;

    // do it % based on mobile so it doesn't overflow the screen
    @media screen and (max-width: variables.$layout-breakpoint-xs) {
        min-width: 80%;
    }

    .textcomplete-item {
        padding: 0 8px;

        sf-avatar {
            margin-right: 8px;
        }

        span {
            line-height: 14px;
        }
    }

    li {
        border-top: 1px solid variables.$load-more-color;
        padding: 2px 5px;

        &:first-child {
            border-top: none;
        }
    }

    a:hover {
        cursor: pointer;
    }

    & li:hover,
    & .active {
        background-color: variables.$textcomplete-color;
    }

    & md-list-item:hover,
    & .active md-list-item {
        background-color: transparent;
    }
}
