@use "sass/core/variables";
@use "sass/core/utils";

#send-test-email-form {
    width: 400px;

    .send-test-email-form {
        padding: 0;
        margin: 0 variables.$default-spacing 0 0;

        input,
        md-select {
            border: none;
            padding: 0;
            height: 38px;

            @include utils.font-size(14);
        }

        &.sf-md-chips-container {
            // Remove the borders
            .label {
                margin-top: 10px;
                margin-bottom: auto;
            }

            // Remove the borders
            :after {
                content: none;
                display: table;
                clear: both;
            }

            md-chips-wrap {
                margin-top: 6px;
            }

            md-chip {
                margin: 2px 0;
                padding: 0 variables.$default-spacing 0 (variables.$default-spacing * 0.5);
                line-height: 20px;
                height: 20px;
                font-size: 12px;

                .md-chip-remove-container {
                    line-height: 12px; // Set to same height as the md-chip-remove button
                }

                button.md-chip-remove {
                    height: 12px;
                    width: 12px;
                    line-height: 12px;
                    margin-right: 4px;

                    md-icon {
                        min-height: variables.$default-spacing;
                        min-width: variables.$default-spacing;
                        height: variables.$default-spacing;
                        width: variables.$default-spacing;
                    }
                }
            }
        }

        .md-chip-input-container {
            margin-top: -6px;
        }

        .send-test-email-input {
            border-bottom: 1px solid;
            border-color: rgb(0 0 0 / 12%);
            padding: 0;
            min-height: 38px;
        }

        .to-label {
            margin: auto;
            margin-right: 8px;
            margin-left: 0;
            color: rgb(0 0 0 / 38%);
        }

        .send-test-email-input.invalid {
            &:not(.no-border) {
                border-bottom: 1px solid variables.$error-color;
            }

            .label {
                color: variables.$error-color;
            }
        }
    }
}

.test-email-auto-complete-drop-down {
    width: 400px; // Making the dropdown wider than the actual input field
}
