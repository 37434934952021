.opportunity.dragging {
    border: 1px solid lightgray;
    width: 100%;
    position: absolute !important;
}

.opportunity.dragging:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}
