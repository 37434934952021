@use "sass/core/variables";
@use "sass/core/utils";

#setup-panel-content {
    background: variables.$white-color;
    width: 480px;

    &:not(.minimized) {
        height: 480px;
    }

    md-content.overflow_hidden {
        overflow: hidden; // don't show scrollbar when animating
    }

    .md-toolbar-tools.fs-setup-panel-header {
        padding-right: 4px;

        md-icon {
            margin-right: 4px;
        }
    }

    @media screen and (max-width: variables.$layout-breakpoint-sm) {
        width: 100%;
        height: 100%;
    }

    .gamification-explanation {
        padding: 16px;
    }

    .fs-setup-panel-header {
        height: variables.$default-spacing * 3;
        padding: variables.$default-spacing;
        font-size: 16px;
        font-weight: 500;
    }

    .fs-setup-panel-arrow {
        right: 100%;
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-color: variables.$white-color;
        border-width: 16px;
        border-style: solid;
        pointer-events: none;
        border-bottom-color: transparent;
        border-bottom-style: solid;
        border-bottom-width: 12px;
        border-left-color: transparent;
        border-left-style: solid;
        border-left-width: 12px;
        border-top-color: transparent;
        border-top-style: solid;
        border-top-width: 12px;
        box-sizing: border-box;
        bottom: 64px;
    }

    .footer-button-group {
        button {
            margin: 4px;
        }
    }

    .setup-step {
        .infinity-symbol-container {
            font-size: 20px;
        }

        &.mock {
            height: 72px;
            width: 100%;
            position: absolute; // Stack below real setup step
        }

        &.completed {
            background-color: variables.$gray-highlight-color;

            .text-content,
            .md-secondary-container {
                opacity: 0.6;
            }
        }

        @media screen and (max-width: variables.$layout-breakpoint-xs) {
            .text-content {
                @include utils.font-size(13);

                h1 {
                    @include utils.font-size(13);
                }
            }
        }

        .md-secondary-container {
            .md-button {
                height: 30px;
                width: 30px;
            }
        }

        md-icon.completed {
            color: green;
        }

        md-icon.checkmark {
            &.completed {
                color: variables.$green-color;
            }

            margin: variables.$default-spacing variables.$default-spacing variables.$default-spacing 0;
        }
    }

    .earn-trial-days-chip {
        margin: auto (variables.$default-spacing * 0.5);
        background-color: variables.$green-color;
        padding: 0 (variables.$default-spacing * 0.25);
        color: variables.$white-color;
        height: 24px;
        line-height: 24px;
        cursor: pointer;

        @include utils.font-size(12);

        @media screen and (max-width: variables.$layout-breakpoint-xs) {
            @include utils.font-size(9);
        }

        &.inline {
            // inline-flex + align-items to vertically center the text
            display: inline-flex;
            align-items: center;
            float: unset; // unset float from material style so we can properly position the chip, usually on the right (material has a `float: left` which obv breaks that)
            height: 22px;
        }
    }
}
