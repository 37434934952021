@use "sass/core/variables";

.regional-settings-section {
    width: 100%;
    max-width: 300px;
    padding-top: 0;
    padding-bottom: variables.$default-spacing;
    margin-top: variables.$default-spacing;

    md-select.currency-selector {
        width: 100px;
    }

    .regional-setting {
        margin: variables.$default-spacing 0;
    }
}

.currency-iso {
    float: left;
    vertical-align: middle;
    line-height: 16px;
    margin-left: 10px;
}

.currency-flag {
    float: left;
}
