@use 'mdc-button' as sf_button;
@use 'mdc-card' as sf_card;
@use 'mdc-checkbox' as sf_checkbox;
@use 'mdc-dialog' as sf_dialog;
@use 'mdc-drawer' as sf_drawer;
@use 'mdc-form-field' as sf_form_field;
@use 'mdc-hint' as sf_hint;
@use 'mdc-menu' as sf_menu;
@use 'mdc-progress-bar' as sf_progress_bar;
@use 'mdc-select' as sf_select;
@use 'mdc-slide-toggle' as sf_slide_toggle;
@use 'mdc-subheader' as sf_subheader;
@use 'mdc-toolbar' as sf_toolbar;
@use 'mdc-tooltip' as sf_tooltip;

@use '@angular/material' as mat;

@use 'palettes';
@use 'typography';

/* Include material default styles & functionality */

@use '@angular/material/theming';

@include mat.core();

/* Set main angular material theme */
$sf-primary: mat.define-palette(palettes.$sf-blue-palette, 500);
$sf-accent: mat.define-palette(palettes.$sf-blue-palette, 200, 100, 400);
$sf-theme: mat.define-light-theme(
                (
                        color: (
                                primary: $sf-primary,
                                accent: $sf-accent
                        ),
                        typography: typography.$sf-typography
                )
);

@include mat.all-component-themes($sf-theme);
@include mat.all-legacy-component-themes($sf-theme);

@include mat.button-theme(sf_button.$sf_button-theme);
@include mat.checkbox-theme(sf_checkbox.$sf_checkbox-theme);
@include mat.pseudo-checkbox-theme(sf_checkbox.$sf-checkbox-theme);
@include mat.select-theme(sf_select.$sf_select-theme);
@include mat.slide-toggle-theme(sf_slide_toggle.$sf_slide-toggle-theme);
@include mat.toolbar-theme(sf_toolbar.$sf_toolbar-theme);

@include mat.progress-bar-color(sf_progress_bar.$sf-progress-bar-theme);

@include mat.dialog-typography(sf_dialog.$sf_dialog-typography);
@include mat.form-field-typography(sf_form_field.$sf-form-field-typography);
@include mat.list-typography(sf_subheader.$sf-subheader-typography);
@include mat.tooltip-typography(sf_tooltip.$sf-tooltip-typography);

@include mat.progress-bar-color(sf_progress_bar.$sf-progress-bar-theme);

@include mat.card-typography(sf_card.$sf_card-typography);
@include mat.dialog-typography(sf_dialog.$sf_dialog-typography);
@include mat.form-field-typography(sf_form_field.$sf-form-field-typography);
@include mat.list-typography(sf_subheader.$sf-subheader-typography);
@include mat.tooltip-typography(sf_tooltip.$sf-tooltip-typography);
