@use "sass/core/variables";

#view-credit-usage-dialog {
    .title-container {
        margin: 0 variables.$default-spacing;

        h3 {
            color: variables.$salesflare-color;
        }
    }

    .usage-chart-wrapper {
        padding: variables.$default-spacing;
        min-height: 250px;
        min-width: 500px;

        @media screen and (max-width: variables.$layout-breakpoint-sm) {
            min-width: 300px;
        }
    }

    .per-user-credit-usage-container {
        padding: 16px;

        .user {
            padding: 0;
            margin-bottom: variables.$default-spacing;
        }
    }
}
