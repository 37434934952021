@use "sass/core/variables";

#billingSettingsHistory {
    .headerRow {
        p {
            font-weight: 900;
        }
    }

    .chargeColumn {
        text-align: end;

        .credited {
            color: variables.$green-color;
        }

        .amount {
            font-weight: 900;
        }

        a > md-icon {
            margin-right: 4px;

            &.icon-14 {
                line-height: 12px;
            }
        }
    }
}
