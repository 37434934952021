@use "sass/core/variables";
@use "common";

sf-top-lost-reasons {
    @include common.report-card-reset;

    .top-lost-reasons-chart {
        padding: 0;
        margin: variables.$default-spacing 0;

        .lost-reason-bar {
            background-color: variables.$dashboard-palette-red;
            height: 12px;
        }

        @include common.css-chart-border-reset;
    }
}
