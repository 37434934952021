@use "sass/core/variables";

#onboarding-div {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    #display-onboarding {
        margin: auto;

        .onboarding-header {
            text-align: center;

            @media screen and (min-width: variables.$layout-breakpoint-md) {
                margin-top: variables.$default-spacing * 10;
            }

            .onboarding-welcome-text {
                font-size: 72px;
                font-weight: 300;
                font-family: Raleway, "Trebuchet MS", sans-serif;
            }

            .onboarding-welcome-message {
                display: block;
                font-size: 26px;
                margin: (variables.$default-spacing * 0.5) 0 variables.$default-spacing;

                @media screen and (min-width: variables.$layout-breakpoint-md) {
                    margin-bottom: variables.$default-spacing * 10;
                }
            }
        }

        .onboarding-footer {
            padding-bottom: 16px;
        }

        .explore-btn-container {
            text-align: center;

            button {
                padding: 2px 12px;
                animation: wiggle 5s 5s linear infinite;

                &:not([disabled]) {
                    background-color: variables.$blue-color;
                    color: variables.$white-color;
                }
            }

            &:last-child {
                margin-top: 10px;
                height: 160px;
            }

            .md-button.md-default-theme.md-raised:not([disabled]):hover,
            .md-button.md-raised:not([disabled]):hover {
                background-color: variables.$blue-color;
            }
        }
    }
}
