@use 'sass/core/variables';


.warning,
[ng-message].warning,
[ng-message-default].warning,
md-input-container.md-input-invalid .md-input-message-animation.warning {
    color: variables.$warning-color;

    md-icon {
        color: variables.$warning-color;
    }
}

.hint,
%hint,
[ng-message].hint,
[ng-message-default].hint {
    font-size: variables.$font-size-12 + px;
    color: variables.$blue-color;
    padding-top: variables.$default-spacing * 0.25; // make sure it doesn't stick directly against the bottom line of the input

    md-icon {
        color: variables.$blue-color;
    }
}

.valid,
[ng-message].valid,
[ng-message-default].valid {
    font-size: variables.$font-size-12 + px;
    color: variables.$green-color;

    md-icon {
        color: variables.$green-color;
    }
}

[ng-message].warning,
[ng-message-default].warning {
    font-size: variables.$font-size-12 + px;
    color: variables.$warning-color;

    md-icon {
        color: variables.$warning-color;
    }
}
