@use "sass/core/variables";
@use "sass/core/utils";

#contextualHelpDialog {
    overflow: initial;

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        position: absolute;
        bottom: 16px;
        left: 100px;
        width: 480px;
    }

    .fs-contextual-help-header {
        height: variables.$default-spacing * 3;
        padding: variables.$default-spacing;
        font-size: 16px;
        font-weight: 500;
    }

    .fs-contextual-help-dialog-arrow {
        right: 100%;
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-color: variables.$white-color;
        border-width: 16px;
        border-style: solid;
        pointer-events: none;
        border-bottom-color: transparent;
        border-bottom-style: solid;
        border-bottom-width: 12px;
        border-left-color: transparent;
        border-left-style: solid;
        border-left-width: 12px;
        border-top-color: transparent;
        border-top-style: solid;
        border-top-width: 12px;
        box-sizing: border-box;
        bottom: 64px;
    }

    md-dialog-content {
        @include utils.font-size(16);

        .title {
            padding: 0 16px;
        }

        md-list {
            md-list-item {
                md-icon {
                    margin-right: 16px;
                }

                p {
                    @include utils.font-size(14);
                }

                &.resource {
                    md-icon {
                        color: variables.$black-color;
                    }

                    h1 {
                        font-weight: 400;
                    }
                }

                &.show-more {
                    md-icon {
                        color: variables.$blue-color;
                    }

                    p {
                        color: variables.$blue-color;
                    }
                }
            }
        }
    }
}
