sf-stage-updates {
    .stage-update-entry {
        position: relative; // Relative to allow absolute positioning of the fading background elements
        margin: 16px 16px -22px 10px; // Negative bottom margin is to overlap the arrows

        &.stage-not-reached > div.stage-name {
            opacity: 0.7;
        }

        .stage-update-arrow {
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: white;
        }

        // Align with center of the squared part of the arrow
        .stage-update-text {
            vertical-align: top;
            line-height: 26px;
        }
    }
}
