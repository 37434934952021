@use "sass/core/variables";

.sf-empty-state {
    display: block;
    text-align: center;
    margin-top: 96px;
    padding: 0 8px;
}

sf-empty-state {
    display: block;
    text-align: center;
    margin-top: 96px;
    padding: 0 8px;
}

.empty-state {
    text-align: center;
    margin-top: 96px;
    padding: 0 8px;

    h1 {
        color: variables.$salesflare-color;
    }
}

.insights-empty-state {
    margin: 24px 0;
}

.dialog-empty-state {
    height: 100%;
    margin-top: 0;

    &.full-screen {
        padding-top: 96px;
        padding-bottom: 100px;
    }
}

.dialog-empty-state-image {
    margin-top: 96px;
}
