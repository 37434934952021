@use "sass/core/variables";

sf-bulk-toolbar {
    .select-all {
        margin-bottom: 0;
        color: variables.$blue-color;
    }

    md-toolbar:not(.md-menu-toolbar) {
        background-color: variables.$bulk-select-color;

        md-icon {
            color: variables.$blue-color;
        }

        .md-toolbar-tools .md-button {
            margin-left: 0;
        }
    }
}
