@use 'utils';

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.mat-mdc-list {
    padding: 0;

    .mat-mdc-button-base {
        text-transform: none;
    }
}

mat-list-item, .mdc-list-item.mat-mdc-list-item {

    .mdc-list-item__primary-text {
        font-size: 14px !important;
    }

    font-size: 14px !important;

    background-color: var(--sf-white);

    &.active {
        background-color: var(--sf-light-blue);
    }

    p,
    h1,
    h2 {
        margin: 0;
        color: var(--sf-black);
    }

    p {
        @include utils.font-size(16);
    }

    h1 {
        @include utils.font-size(16);

        line-height: 24px;
    }

    h2 {
        @include utils.font-size(13);

        margin-top: 4px;
        font-weight: normal;
        line-height: normal;
    }

    &.large,
    &.extra_large {
        min-height: 72px;

        p {
            @include utils.font-size(13, true);

            color: var(--sf-muted-grey);
        }

        h1 {
            @include utils.font-size(14);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
        .mat-list-item-text {
            margin: auto;
            flex: 1;
            overflow: hidden;
            line-height: initial;

            p {
                font-weight: 500;
                letter-spacing: 0.01em;
            }
        }
    }

    .mdc-list-item__primary-text {
        display: flex;
        align-items: center;
    }

    .mdc-list-item__secondary-text::before {
        display: none;
        content: unset;
        height: unset;
        width: unset;
    }
}
