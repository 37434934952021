@use "sass:math";
@use "sass/core/variables";
@use "sass/core/utils";

$pipeline-select-width: 180px;

#opportunities-view {
    #tabs-filler {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            width: calc(100% - 180px - 24px - 24px - 24px - 24px - #{$pipeline-select-width}); // fix for offsetting against the tabs, the tabs are not made to put something next to them (180 are the tabs, 24 for every button on the right and spacing for the pipeline selector)
        }
    }

    cursor: default;

    &.open-sidebar {
        margin-right: 360px;
        transition: margin 300ms;
    }
}

#opportunities {
    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        margin: 0 6px;

        // Some padding to show the horizontal scrollbar more clear
        padding-bottom: 2px;

        &:not([data-empty="true"]) {
            // Set height to 100% so the virtual repeat has the correct height, offset with the height of the entity count header which is also part of the parent container
            min-height: calc(100% - 48px);
        }
    }

    .opportunity-greyed-out {
        opacity: 0.65;
    }

    .stage-wrapper {
        min-height: 100%;
    }

    .opportunities-header-line {
        height: 4px;
        border-radius: 2px;
        margin-bottom: 16px;

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            margin: 0 12px 0 8px;
        }
    }

    .drag-here-indicator {
        font-size: 16px;
        background-color: #ededed;
        color: variables.$accent-color;
        border: dashed 1px;
        opacity: 0.7;

        &.dragging {
            background-color: variables.$white-color;
        }

        div {
            display: inline-block;
            text-align: center;
            font-weight: 700;
        }
    }

    .drag-arrow-container {
        position: relative;

        .drag-arrow {
            position: absolute;
            z-index: 1;
            left: 100px;
            top: 170px;
            opacity: 0.8;
        }
    }

    .drop-clone {
        background-color: white;
        position: absolute;
        z-index: 10000;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0 math.div(variables.$default-spacing, 2);
    }

    .opportunity-list {
        position: relative;
    }

    .drag-clone {
        .action-menu-dots {
            right: 0;
            position: fixed;
        }
    }

    md-virtual-repeat-container {
        min-height: 100%; // This fixes the virtual repeat sometimes only rendering three items

        // We want to set the min-height of the virtual repeat container to 100% so angularjs material knows how many list items to generate
        // Setting it to the full 100% causes the last list item to not be completely visible
        // This happens because the header is part of the containing element on full screen, so we need to offset with the height of the header
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            min-height: calc(100% - 72px);
        }
    }

    md-list-item {
        &,
        .md-list-item-inner {
            line-height: normal;
        }

        .md-menu {
            display: none;
        }

        &:hover {
            .md-menu {
                cursor: pointer;
                display: block;
            }
        }

        md-menu .md-button {
            width: 24px;
            height: auto;
            padding: 0;
            margin-right: -12px; // stick it to the right side
            border-radius: 0;

            &:hover {
                background-color: transparent;
            }
        }

        @media screen and (max-width: variables.$layout-breakpoint-xs-max) {
            .stage-name {
                margin-right: 4px;
            }

            // TODO convert to component that controls placing of mini avatar and sets the margin right (6px)
            sf-avatar {
                &.sf-avatar_mini {
                    margin-left: -26px;
                    margin-top: 14px;
                    margin-right: 8px;

                    .sf-avatar_face {
                        width: 20px;
                        height: 20px;
                        border: 3px solid variables.$white-color;
                    }
                }
            }

            md-menu {
                position: fixed;
                width: 32px;
                right: 0;
                margin-left: 4px;

                .md-button {
                    width: 32px;
                    height: 32px;
                    padding: 0;
                    margin-right: -12px; // stick it to the right side
                    border-radius: 0;

                    &:hover {
                        background-color: transparent;
                    }

                    md-icon {
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }

    .md-subheader .md-subheader-inner md-icon {
        color: variables.$salesflare-color;
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        overflow-y: hidden;
        background-color: variables.$gray-color;

        > div.layout-column {
            background-color: variables.$list-bg-color;
            min-width: 200px; // safari
            max-width: 300px;
        }

        .drag-enter {
            > .opportunities-header {
                opacity: 1;
            }
        }

        .md-virtual-repeat-scroller {
            background-color: variables.$gray-color;
            overflow-y: scroll;
        }

        // TODO convert to component that controls placing of mini avatar and sets the margin right (6px)
        sf-avatar {
            margin: 0;

            &.sf-avatar_mini {
                margin-left: -9px;
                margin-top: -4px;

                .sf-avatar_face {
                    width: 20px;
                    height: 20px;
                    border: 3px solid variables.$white-color;
                }
            }
        }

        md-list-item {
            border: 1px solid variables.$light-gray-color;
            border-radius: 4px;
            margin: 6px;

            &:last-of-type {
                margin-bottom: 6px;
            }

            .md-button {
                padding: 0 8px;
            }

            md-menu .md-button {
                margin-top: -6px; // stick it to the upper corner
            }

            h1 {
                @include utils.font-size(14);

                line-height: 32px;
                margin-left: 5px;
            }

            h2 {
                font-size: 14px;

                &:last-of-type {
                    max-width: 50%;
                }
            }
        }
    }
}

.opportunities-header {
    font-weight: normal !important;

    .md-subheader .md-subheader-inner .md-secondary-container md-icon:last-child {
        margin-right: -3px;
    }

    #opportunities & {
        .stage-name {
            padding-right: 8px;
            line-height: 16px;
            font-weight: 700;
        }

        .stage-count {
            background-color: variables.$highlight-color;
            padding: 4px 7px;
            font-size: 11px;
            border-radius: 2px;
        }
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        #opportunities & {
            font-size: variables.$font-size-14 + px;
        }

        &:not(.stage-settings-header) {
            padding: 8px; // padding - border of header
            padding-right: 12px;
        }
    }

    &.as-sortable-item-handle {
        cursor: grab;
    }

    &.md-sticky-clone {
        cursor: default;
    }
}

.mobile-pipeline-select {
    .md-label {
        width: calc(100% - 20px);
    }

    .option-row {
        span {
            margin-bottom: 1px;
            display: inline-flex;
            align-items: center;
            margin-right: 2px;
        }

        md-icon.sf-icon-recurring {
            margin-right: 4px;
            margin-left: 0;
        }

        .sf-plan-flag {
            margin-left: auto;
            margin-right: 25px;
        }
    }
}

.pipeline-select {
    width: $pipeline-select-width;

    .md-text {
        width: 100%;

        span {
            margin-top: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100px;
            margin-right: 2px;
        }

        md-icon.sf-icon-recurring {
            margin-top: 4px;
            margin-right: 4px;
            margin-left: 0;
        }

        .sf-plan-flag {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

.pipeline-select-option {
    .md-text {
        width: 100%;

        span {
            margin-top: 3px;
            text-overflow: ellipsis;
            margin-right: 2px;
        }

        md-icon.sf-icon-recurring {
            margin-top: 3px;
            margin-right: 4px;
            margin-left: 0;
            color: variables.$semi-accent-color;
        }

        .sf-plan-flag {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

.editing-pipeline-select {
    .md-text {
        width: 100%;

        span {
            margin-top: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 2px;
        }

        md-icon.sf-icon-recurring {
            margin-top: 4px;
            margin-right: 4px;
            margin-left: 0;
        }

        .sf-plan-flag {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

@media screen and (max-width: variables.$layout-breakpoint-xs) {
    .pipeline-select {
        width: 40px;
        margin-right: 30px;
    }
}

.stages-placeholder {
    width: 100%;
}

#congratulations-message,
#invite-colleagues-message {
    display: none;
}

.backdrop-text-wrapper {
    position: absolute;
    left: calc(50% + 125px); // Middle of the screen offsetted with the width of the main menu
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    color: white;
    z-index: 100; // To ensure being shown above an md-backdrop
    width: 1000px;

    > span {
        display: none;
    }
}
