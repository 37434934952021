@use "sass/core/variables";

.insights-btns {
    background-color: variables.$white-color;

    .md-button {
        width: 100%;
        margin-right: 8px;
    }

    .md-button:last-of-type {
        margin-right: 0;
    }
}

#insights {
    md-toolbar {
        z-index: auto;
    }

    .owner-selector {
        width: 120px;
    }
}
