@use "sass/core/variables";

$import-padding: 0;
$import-actions-width: 120px;

sf-import .import {
    padding: $import-padding;
    border-bottom: 1px;
    overflow: auto;

    .md-list-item-inner {
        width: 0;
    }

    .md-secondary-container {
        min-width: $import-actions-width;
        justify-content: flex-end;
    }

    .imp-button {
        min-height: 5vh;
        margin: auto;
    }

    .imp-box {
        border: 3px dashed #ddd;
    }

    .imp-type-container {
        max-width: 100%;
    }

    .imp-type {
        text-align: center;
        width: 33%;

        @media screen and (width <= 599px) {
            width: 100%;
        }
    }

    .imp-div {
        margin: auto;
        text-align: center !important;
    }

    .imp-col {
        white-space: nowrap;
    }

    .sf-table {
        display: block;
    }

    .drag-overlay {
        pointer-events: none; // Needed in Chrome to prevent flickering when the drop zone contains child elements, see: https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
        position: absolute;
        top: 64px; // Height of toolbar
        opacity: 0.7;
        width: 100%;
        height: calc(100% - 64px); // Height of toolbar
        background-color: variables.$drag-overlay-background-color;
        border: 3px solid variables.$drag-overlay-border-color;
    }
}
