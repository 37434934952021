@use "sass/core/variables";

.sf-workflow-card {
    md-card-icon-actions {
        md-icon {
            color: variables.$blue-color;
        }
    }

    md-card-content {
        padding-bottom: 0;
    }

    .no-padding {
        margin-bottom: 6px;
        min-height: 23px;
    }

    md-card-title {
        cursor: pointer;

        md-card-title-text:only-child .md-subhead {
            padding-top: 0;
        }

        .sf-plan-flag {
            // Tried a bunch of things to align it but putting it inside the title span with the vertical-align seems to do it
            vertical-align: middle;
        }
    }

    .last-button {
        max-width: 64px;
    }

    md-icon.inline-icon {
        line-height: 15px;
        margin-right: 4px;
        padding-bottom: 4px;
        padding-top: 3px;
    }
}
