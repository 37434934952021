@use "sass/core/variables";

#notification-content {
    background: variables.$white-color;
    width: 420px;
    height: 486px;

    .fs-notification-panel-header {
        height: variables.$default-spacing * 3;
        padding: variables.$default-spacing;
        font-size: 16px;
        font-weight: 500;

        .fs-notification-panel-header-arrow {
            right: 100%;
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-color: variables.$white-color;
            border-width: 16px;
            border-style: solid;
            pointer-events: none;
            border-bottom-color: transparent;
            border-bottom-style: solid;
            border-bottom-width: 12px;
            border-left-color: transparent;
            border-left-style: solid;
            border-left-width: 12px;
            border-top-color: transparent;
            border-top-style: solid;
            border-top-width: 12px;
            box-sizing: border-box;
        }
    }

    @media screen and (max-width: variables.$layout-breakpoint-sm) {
        width: 100%;
        height: 100%;
    }

    .sf-empty-state {
        margin: auto;
    }

    .earlier-subheader {
        .md-subheader-inner {
            text-align: center;
            padding: 27.5px 16px;
            font-weight: 400;
            border-bottom: 1px solid variables.$light-gray-color;
        }
    }

    .notification {
        md-menu {
            line-height: 0;
        }

        > .md-button {
            padding: 0 variables.$default-spacing * 0.5;
        }

        &.not-read {
            background-color: variables.$gray-highlight-color;
        }

        .menu-small-vertical-button {
            padding: 0;
            line-height: 24px;
            height: 24px;
            width: 16px;
        }
    }

    .footer-button-group {
        button {
            margin: 4px;
        }
    }

    .swipe-mask {
        position: absolute;
        background-color: variables.$red-color;
        height: 72px;
        width: 100%;
        border-bottom: 1px solid variables.$light-gray-color;

        > md-icon.sf-icon-delete {
            color: white;
            position: absolute;
            top: 26px;

            &.right {
                right: variables.$default-spacing;
            }

            &.left {
                left: variables.$default-spacing;
            }
        }
    }
}

.notification-panel {
    margin: variables.$default-spacing 0 0 4px;

    // overriding styling from @salesflare/angular-material (fixes issue https://github.com/Salesflare/Server/issues/10252)
    &._md-panel-position-adjusted {
        top: 0 !important;
    }
}
