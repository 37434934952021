@use 'utils';

@for $i from 1 through 50 {

    .mdc-icon-button.mat-mdc-icon-button:has(
        .icon-#{$i},
        .mat-icon.icon-#{$i},
        .mat-mdc-icon-button .mat-icon.icon-#{$i}
        ) {

        .mdc-button__label {

            width: $i + px;
            height: $i + px;
        }
    }

    .icon-#{$i},
    .mat-icon.icon-#{$i},
    .mat-mdc-icon-button .mat-icon.icon-#{$i} {
        @include utils.font-size($i);
        width: $i + px;
        min-width: $i + px;
        min-height: $i + px;
        height: $i + px;
        line-height: $i + px;
    }
}

.mat-icon {
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.mat-icon-button {

    .mat-ripple {

        display: none;
    }

    .mat-button-focus-overlay {

        display: none;
    }
}
