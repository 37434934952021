@use "sass:color";
@use "sass/core/variables";
@use "common";

sf-revenue-vs-goal {
    @include common.report-card-reset;

    $won-color: variables.$dashboard-palette-green;
    $expected-color: color.adjust(variables.$dashboard-palette-green, $lightness: 45%);

    .revenue-vs-goal {
        > div {
            height: 30px;
        }

        @include common.css-chart-border-reset;
    }

    .won {
        background-color: $won-color;
    }

    .expected {
        background-color: $expected-color;
    }

    .caption {
        height: 20px;
        width: 20px;
        margin-right: variables.$default-spacing;
    }

    .caption-row {
        padding: (variables.$default-spacing * 0.5) 0;

        > span {
            margin: auto;
        }

        .align-end {
            margin: auto;
        }
    }

    .bar-chart {
        .won {
            background-color: $won-color;
        }

        .expected {
            background-color: $expected-color;
        }

        .remaining {
            background-color: variables.$white-color;
        }

        .target {
            position: absolute;
            top: -14px;
            height: 60px;
            background-color: transparent;
            border-right: 2px solid variables.$salesflare-color;
            z-index: 2;
            border-bottom: 0;
            border-top: 0;
        }
    }
}
