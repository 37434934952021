@use "sass/core/variables";
@use "sass/core/utils";

sf-tasks-today {
    // Give the headers some spacing
    .list {
        .header-spacer {
            // on mobile we don't use spacers so need there
            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                height: variables.$default-spacing * 3; // make sure the spacer is the same height as 1 task (48px)
            }
        }

        .md-subheader-inner {
            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                padding-left: 0;
                text-align: left;

                // This spacing is to position the header together with the spacer
                // The top 6px + 48px (from the spacer) is 2X the bottom 26px
                // This means that the spacing above the header is 2X the bottom spacing
                padding-top: 26px;
                padding-bottom: 26px;
            }

            text-align: center;

            // On mobile we want to center the text vertically as well in the 72px high block
            padding-top: 28px;
            padding-bottom: 28px;

            @include utils.font-size(variables.$font-size-16);
        }
    }

    // Undo task list margin bottom
    .list.recentlyCompletedTasks {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            margin-top: -32px;
        }
    }

    .taskSettingsIcon {
        // Make it a bit more obv to the user it is a button, the md-button class messes up the layout
        cursor: pointer;
    }

    .md-subheader md-icon[md-font-icon] {
        line-height: 0.9em; // Make sure icon vertically aligns with the text
    }
}
