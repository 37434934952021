@use "sass/core/variables";
@use "sass/components/salesflare/shared";

#checkout {
    .pay-button {
        padding: 0 8px;
        margin-bottom: 16px;
    }

    .discount-apply-button {
        @media screen and (min-width: variables.$layout-breakpoint-xs) {
            vertical-align: text-bottom; // get it on the same level as the input box otherwise it looks weird due to the error spacer under the input box
        }
    }

    .frequency-switch-container {
        .left-side-label {
            margin-right: 15px;
        }

        md-switch.md-default-theme .md-thumb,
        md-switch .md-thumb {
            background-color: rgb(3 155 229);
        }

        md-switch.md-default-theme .md-bar,
        md-switch .md-bar {
            background-color: rgb(3 155 229 / 50%);
        }
    }

    button.md-raised {
        min-width: shared.$button-min-width; // reset to material since it looks better

        &:not([disabled]) {
            background-color: variables.$blue-color;
            color: variables.$white-color;
        }
    }

    #right-panel {
        .middle {
            margin-bottom: 16px;

            .referral-discount-form {
                padding: variables.$default-spacing 0 0;

                md-input-container {
                    margin: 0;
                    width: 270px;

                    input {
                        height: 32px;
                    }
                }
            }
        }
    }

    .checkout-not-available-container {
        padding: 0 variables.$default-spacing;
    }
}
