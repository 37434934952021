@use "sass/core/variables";
@use "sass/core/utils";

md-list {
    padding: 0;
}

.drag-clone {
    width: 100%;
    cursor: grabbing;

    md-list-item {
        width: 100%;
        transform: rotate(2deg);
        border: none;
        box-shadow: 0 4px 5px 0 rgb(0 0 0 / 15%);
    }
}

.md-secondary-container {
    display: inline-flex;

    md-icon {
        color: variables.$blue-color; // force blue color on icons
    }
}

.md-list-item-inner span:not(:last-child),
.list-item-detail span:not(:last-child),
.md-list-item-text :not(button) span:not(:last-child),
.md-list-item-text div:not(:last-child, .md-secondary-container, .no-margin-right),
.md-list-item-text p:not(:last-child),
.list-item-detail md-icon:not(:last-child),
.md-list-item-text md-icon:not(:last-child, .action-menu-dots),
.md-list-item-inner div:not(:last-child, .md-secondary-container, [md-ink-ripple-checkbox], .no-margin-right),
.md-list-item-inner p:not(:last-child),
.list-item-detail div:not(:last-child, .md-secondary-container),
.list-item-detail p:not(:last-child) {
    margin-right: 4px;
}

.list-item-secondary-text {
    font-size: 12px;
    padding-top: 0;
    text-align: right;

    .icon {
        color: variables.$list-item-secondary-icon-color;
        vertical-align: sub;
    }

    .icon:last-child {
        padding-left: 8px;
    }

    .icon:not(:last-child) {
        padding-right: 4px;
        color: variables.$list-item-secondary-icon-color;
        font-size: variables.$font-size-14 + px;
        vertical-align: sub;
    }

    :first-child {
        margin-bottom: 3px;
    }
}

.list-item-detail {
    white-space: nowrap;
    color: variables.$secondary-color;

    .icon {
        vertical-align: sub;
    }

    md-menu {
        .icon {
            color: variables.$blue-color;

            &:before {
                vertical-align: bottom;
            }
        }
    }

    span[ng-bind-html] {
        div {
            display: inline;
        }

        br {
            @include utils.hide;
        }
    }
}

md-list-item {
    background-color: variables.$white-color;

    &.active {
        background-color: variables.$highlight-color;
    }

    .md-list-item-inner {
        height: 48px;
        display: flex;
        color: variables.$text-color;

        &.md-no-style {
            padding: 0;
        }

        // Since we set the height of a standard `md-list` to 48px
        // we need to fix the margin for the icon in the front to vertically center it
        > md-icon:first-of-type:not(.md-avatar-icon) {
            margin-top: 12px;
            margin-bottom: 12px;
            margin-right: 16px; // same as padding left of the list item
        }

        // make sure spacing is the same as sf-avatar
        // while still centering the checkbox
        md-checkbox,
        mat-checkbox {
            width: 20px; // actual width, not sure why md makes it 24px
            margin-left: 6px; // sf-avatar is 32px, so 32 - 20 = 12, 6 for each side
            margin-right: 22px; // + 16 on the right like sf-avatar
        }

        .checkbox-avatar_opportunity md-checkbox {
            width: 32px;
            margin-bottom: 0; // reset the md's margin bottom for vertical alignment
            margin-left: 0;
            margin-right: 0;

            > div { // actual checkbox square
                left: 6px; // avatar's width is 32 and checkbox 20 so ((32 - 20) / 2) = 6
            }
        }
    }

    h1,
    h2 {
        margin: 0;
        color: variables.$salesflare-color;
    }

    p {
        @include utils.font-size(14);

        margin: 0;
        color: variables.$text-color;
    }

    h1 {
        @include utils.font-size(16);

        line-height: 24px;
    }

    h2 {
        @include utils.font-size(13);

        margin-top: 4px;
        font-weight: normal;
        line-height: normal;
    }

    > md-icon:first-of-type:not(.md-avatar-icon) {
        &.icon-34 {
            margin: auto 16px auto auto;
            padding-left: 7px;
            padding-right: 7px;
        }
    }

    &.checkbox-avatar {
        .sf-avatar_small + md-checkbox {
            margin-right: 18px;
            margin-left: 6px;
        }

        .md-list-item-inner {
            width: 89%;
        }
    }

    .no-touch &.checkbox-avatar:hover {
        md-checkbox {
            display: inline-block !important;
            opacity: 1;
            max-height: initial;

            & ~ sf-avatar {
                @include utils.hide;
            }

            & ~ md-icon {
                @include utils.hide;
            }

            & ~ .badge-border {
                @include utils.hide;
            }
        }
    }

    &.large,
    &.extra_large {
        min-height: 72px;

        p {
            @include utils.font-size(13, true);
        }

        .md-list-item-text {
            margin: auto;
            flex: 1;
            overflow: hidden;
            line-height: initial;

            p {
                font-weight: 500;
                letter-spacing: 0.01em;
            }
        }

        .md-no-style {
            min-height: 72px;
        }
    }

    &.md-2-line {
        .md-list-item-inner {
            // Since we set the height of a standard `md-list` to 48px
            // we need to fix the margin for the icon in the front to vertically center it
            > md-icon:first-of-type:not(.md-avatar-icon) {
                min-width: 34px;
                width: 34px;
                height: 34px;
                font-size: 34px;
                margin-top: 19px;
                margin-bottom: 19px;
                margin-right: 16px; // same as padding left of the list item
            }
        }
    }
}

.navigation-drawer md-list-item,
#set-targets md-list-item {
    background-color: transparent;
}

// Draws red triangle in top right corner
md-list-item.invalid:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    display: block;
    border-left: 12px solid transparent;
    border-top: 12px solid #f00;
    pointer-events: none; // The triangle is not clickable so we just pass through the events
}

/* material design fixes for ellipsis to work */
md-list-item.large .md-list-item-text h3,
md-list-item.large > .md-no-style .md-list-item-text h3,
md-list-item.extra_large .md-list-item-text h3,
md-list-item.extra_large > .md-no-style .md-list-item-text h3 {
    margin: 0;
    line-height: normal;
}

md-list-item.large .md-list-item-text h4,
md-list-item.large > .md-no-style .md-list-item-text h4,
md-list-item.extra_large .md-list-item-text h4,
md-list-item.extra_large > .md-no-style .md-list-item-text h4 {
    margin: 0;
    line-height: normal;
}

md-list md-list-item a.md-button:not([disabled]):hover {
    background-color: transparent;
}

#mutual {
    margin-bottom: 52px;
}
