@use "sass/core/variables";
@use "sass/core/utils";

md-card {
    color: inherit;
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 15%);

    &.active {
        background-color: variables.$highlight-color !important;

        md-list-item {
            background-color: transparent;
        }
    }

    md-card-header-text,
    md-card-header-text .md-subhead {
        @extend %ellipsis;
    }

    md-card-content {
        > div {
            padding: 0 16px;
        }
    }

    md-card-footer {
        padding: 0 16px;
        display: flex;
        align-items: center;
        min-height: 48px;

        .md-button {
            font-size: variables.$font-size-11 + px;
        }

        .md-button:last-child {
            margin-right: 0;
        }

        sf-avatar {
            margin: 0;
        }

        .md-menu {
            padding: 0;
        }
    }
}

md-card > img,
md-card > :not(md-card-content) img {
    width: initial;
    max-width: 100%;
}
