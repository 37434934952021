@font-face {
  font-family: "salesflare";
  src:
      url('../../../src/fonts/salesflare.woff2?y9wci0') format('woff2'),
      url('../../../src/fonts/salesflare.woff?y9wci0') format('woff'),
      url('../../../src/fonts/salesflare.ttf?y9wci0') format('truetype'),
      url('../../../src/fonts/salesflare.svg?y9wci0#salesflare') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="sf-icon-"], [class*=" sf-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'salesflare' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sf-icon-action-needed:before {
  content: "\e000";
}

.sf-icon-filter:before {
  content: "\e002";
}

.sf-icon-menu:before {
  content: "\e003";
}

.sf-icon-send:before {
  content: "\e004";
}

.sf-icon-search:before {
  content: "\e005";
}

.sf-icon-time:before {
  content: "\e006";
}

.sf-icon-stage:before {
  content: "\e007";
}

.sf-icon-close:before {
  content: "\e008";
}

.sf-icon-accounts:before {
  content: "\e00b";
}

.sf-icon-people:before {
  content: "\e00c";
}

.sf-icon-support:before {
  content: "\e00e";
}

.sf-icon-settings:before {
  content: "\e010";
}

.sf-icon-attachment-o:before {
  content: "\e011";
}

.sf-icon-account-info:before {
  content: "\e012";
}

.sf-icon-account-feed:before {
  content: "\e015";
}

.sf-icon-email-opened:before {
  content: "\e017";
}

.sf-icon-add-event:before {
  content: "\e018";
}

.sf-icon-file-zip:before {
  content: "\e019";
}

.sf-icon-file-excel:before {
  content: "\e01a";
}

.sf-icon-file-image:before {
  content: "\e01d";
}

.sf-icon-file:before {
  content: "\e01e";
}

.sf-icon-file-pdf:before {
  content: "\e01f";
}

.sf-icon-file-powerpoint:before {
  content: "\e020";
}

.sf-icon-file-text:before {
  content: "\e021";
}

.sf-icon-file-video:before {
  content: "\e022";
}

.sf-icon-file-word:before {
  content: "\e023";
}

.sf-icon-lock:before {
  content: "\e024";
}

.sf-icon-webpage:before {
  content: "\e025";
}

.sf-icon-phone:before {
  content: "\e026";
}

.sf-icon-meeting:before {
  content: "\e027";
}

.sf-icon-reminder-clock:before {
  content: "\e028";
}

.sf-icon-date-picker:before {
  content: "\e02a";
}

.sf-icon-edit:before {
  content: "\e02b";
}

.sf-icon-expand:before {
  content: "\e02c";
}

.sf-icon-attachment:before {
  content: "\e02d";
}

.sf-icon-address:before {
  content: "\e02e";
}

.sf-icon-menu-small-vertical:before {
  content: "\e02f";
}

.sf-icon-forward:before {
  content: "\e030";
}

.sf-icon-description:before {
  content: "\e031";
}

.sf-icon-settings-email:before {
  content: "\e032";
}

.sf-icon-settings-notifications:before {
  content: "\e033";
}

.sf-icon-settings-download:before {
  content: "\e034";
}

.sf-icon-opportunities:before {
  content: "\e035";
}

.sf-icon-website:before {
  content: "\e036";
}

.sf-icon-add:before {
  content: "\e038";
}

.sf-icon-save:before {
  content: "\e039";
}

.sf-icon-delete:before {
  content: "\e03a";
}

.sf-icon-collapse:before {
  content: "\e03b";
}

.sf-icon-dots:before {
  content: "\e03c";
}

.sf-icon-sign-out:before {
  content: "\e03d";
}

.sf-icon-target:before {
  content: "\e03e";
}

.sf-icon-meeting-phone:before {
  content: "\e040";
}

.sf-icon-meeting-live:before {
  content: "\e041";
}

.sf-icon-message:before {
  content: "\e042";
}

.sf-icon-email-forward:before {
  content: "\e044";
}

.sf-icon-reply-all:before {
  content: "\e045";
}

.sf-icon-reply:before {
  content: "\e046";
}

.sf-icon-email:before {
  content: "\e047";
}

.sf-icon-info:before {
  content: "\e048";
}

.sf-icon-social:before {
  content: "\e049";
}

.sf-icon-previous:before {
  content: "\e04a";
}

.sf-icon-next:before {
  content: "\e04b";
}

.sf-icon-launch:before {
  content: "\e04c";
}

.sf-icon-group-add:before {
  content: "\e051";
}

.sf-icon-person-add:before {
  content: "\e052";
}

.sf-icon-hot:before {
  content: "\e053";
}

.sf-icon-user_added:before {
  content: "\e054";
}

.sf-icon-user_removed:before {
  content: "\e055";
}

.sf-icon-account_created:before {
  content: "\e056";
}

.sf-icon-reminder:before {
  content: "\e057";
}

.sf-icon-person:before {
  content: "\e058";
}

.sf-icon-key:before {
  content: "\e05a";
}

.sf-icon-clipboard:before {
  content: "\e05b";
}

.sf-icon-wifi-1:before {
  content: "\e05c";
}

.sf-icon-help:before {
  content: "\e05d";
}

.sf-icon-wifi-2:before {
  content: "\e05e";
}

.sf-icon-wifi:before {
  content: "\e05f";
}

.sf-icon-contact:before {
  content: "\e060";
}

.sf-icon-dollar:before {
  content: "\e061";
}

.sf-icon-arrow-right:before {
  content: "\e062";
}

.sf-icon-arrow-left:before {
  content: "\e063";
}

.sf-icon-report:before {
  content: "\e064";
}

.sf-icon-trend-down:before {
  content: "\e065";
}

.sf-icon-trend-up:before {
  content: "\e066";
}

.sf-icon-wifi-background:before {
  content: "\e067";
}

.sf-icon-history:before {
  content: "\e068";
}

.sf-icon-card:before {
  content: "\e069";
}

.sf-icon-location:before {
  content: "\e06a";
}

.sf-icon-list:before {
  content: "\e06b";
}

.sf-icon-fields:before {
  content: "\e06d";
}

.sf-icon-recurring:before {
  content: "\e06f";
}

.sf-icon-office:before {
  content: "\e070";
}

.sf-icon-google:before {
  content: "\e071";
}

.sf-icon-outlook:before {
  content: "\e072";
}

.sf-icon-check-box:before {
  content: "\e073";
}

.sf-icon-sun-outline:before {
  content: "\e074";
}

.sf-icon-check-box-circle:before {
  content: "\e076";
}

.sf-icon-calendar:before {
  content: "\e077";
}

.sf-icon-import:before {
  content: "\e078";
}

.sf-icon-regional:before {
  content: "\e079";
}

.sf-icon-pause:before {
  content: "\e07a";
}

.sf-icon-play:before {
  content: "\e07b";
}

.sf-icon-undo:before {
  content: "\e080";
}

.sf-icon-clone:before {
  content: "\e081";
}

.sf-icon-video:before {
  content: "\e082";
}

.sf-icon-cubes:before {
  content: "\e083";
}

.sf-icon-assignee:before {
  content: "\e084";
}

.sf-icon-calendar-start:before {
  content: "\e085";
}

.sf-icon-calendar-stop:before {
  content: "\e086";
}

.sf-icon-dollar-1x:before {
  content: "\e087";
}

.sf-icon-tag:before {
  content: "\e088";
}

.sf-icon-warning:before {
  content: "\e08a";
}

.sf-icon-in-workflow:before {
  content: "\e08c";
}

.sf-icon-exited-workflow:before {
  content: "\e08d";
}

.sf-icon-entered-workflow:before {
  content: "\e08e";
}

.sf-icon-bullseye:before {
  content: "\e08f";
}

.sf-icon-bookmark-outline:before {
  content: "\e090";
}

.sf-icon-lock-open-outline:before {
  content: "\e091";
}

.sf-icon-lock-closed-outline:before {
  content: "\e092";
}

.sf-icon-text-format:before {
  content: "\e094";
}

.sf-icon-camera:before {
  content: "\e095";
}

.sf-icon-sort:before {
  content: "\e096";
}

.sf-icon-open-arrow-down:before {
  content: "\e097";
}

.sf-icon-merge:before {
  content: "\e098";
}

.sf-icon-workflows:before {
  content: "\e099";
}

.sf-icon-conf-call:before {
  content: "\e09a";
}

.sf-icon-permissions:before {
  content: "\e09b";
}

.sf-icon-custom-insights:before {
  content: "\e09c";
}

.sf-icon-maximize:before {
  content: "\e09d";
}

.sf-icon-minimize:before {
  content: "\e09e";
}

.sf-icon-line-chart:before {
  content: "\e09f";
}

.sf-icon-column-chart:before {
  content: "\e0a0";
}

.sf-icon-scorecard:before {
  content: "\e0a1";
}

.sf-icon-pie-chart:before {
  content: "\e0a2";
}

.sf-icon-merge-fields:before {
  content: "\e900";
}

.sf-icon-sparkles:before {
  content: "\e901";
}

.sf-icon-gift:before {
  content: "\e902";
}

.sf-icon-exchange:before {
  content: "\e903";
}

.sf-icon-table-chart:before {
  content: "\e904";
}

.sf-icon-privacy:before {
  content: "\e905";
}

.sf-icon-article:before {
  content: "\e906";
}

.sf-icon-clear-format:before {
  content: "\e907";
}

.sf-icon-stages:before {
  content: "\e908";
}

.sf-icon-timeline:before {
  content: "\e909";
}

.sf-icon-file-audio:before {
  content: "\e90a";
}

.sf-icon-statuscolor:before {
  content: "\e910";
}

.sf-icon-salesflare:before {
  content: "\e947";
}

.sf-icon-apple:before {
  content: "\eabe";
}
