/* Scrollbars (Chrome & Safari) */
@use "sass/core/variables";

::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    background-color: transparent;
}

::-webkit-scrollbar:hover {
    background-color: transparent;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

:not(textarea)::-webkit-resizer {
    border-radius: 4px;
    background-color: variables.$resizer-color;
}

::-webkit-scrollbar-thumb {
    min-height: 0.8em;
    min-width: 0.8em;
    border-radius: 4px;
    background-color: rgb(0 0 0 / 6%);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(0 0 0 / 12%);
}

::-webkit-scrollbar-thumb:active {
    background-color: rgb(0 0 0 / 12%);
}

::-webkit-scrollbar-thumb:horizontal {
    min-height: 0.8em;
    min-width: 0.8em;
    border-radius: 4px;
    background-color: rgb(0 0 0 / 20%);
}

::-webkit-scrollbar-thumb:hover:horizontal {
    background-color: rgb(0 0 0 / 30%);
}

::-webkit-scrollbar-thumb:active:horizontal {
    background-color: rgb(0 0 0 / 30%);
}
