/**
 * Include these mixins in specific components.
 * These help with migrating/resetting components from old insights to custom insights.
 */
@use "sass/core/variables";

// Some resets since we render an md-card inside another, visually, card
@mixin report-card-reset {
    md-card.sf-report-card {
        border: none;
        overflow: unset; // prevent double scrollbars
        margin: 0; // chart container already has spacing on the sides so we reset here

        // This adds some spacing at the bottom which visually will be the same as the space between the graph and the title and prevents sticking to the bottom when scrolling.
        padding-bottom: variables.$default-spacing;

        md-card-content {
            padding: 0; // fill the space better
        }

        .difference-indicator {
            @include common-report-card-classes;
        }
    }
}

@mixin common-report-card-classes {
    .negative {
        color: variables.$dashboard-palette-red;
    }

    .positive {
        color: variables.$dashboard-palette-green;
    }
}

@mixin css-chart-border-reset {
    .column-chart .chart-column,
    .bar-chart .chart-row,
    .column-chart [data-cp-size],
    .bar-chart [data-cp-size],
    .plot-container > * > * {
        border: none;
        border-top: 0;
        border-bottom: 0;
    }
}
