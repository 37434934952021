@use "sass/core/variables";
@use "sass/core/utils";

.md-subheader {
    @extend %no-select;

    @include utils.font-size(16);

    background-color: variables.$subheader-color;
    color: variables.$salesflare-color;

    .md-subheader-inner {
        flex: 1;
    }
}

.icon-float-right,
%icon-float-right {
    .md-subheader-inner {
        .md-subheader-content {
            display: flex;

            div {
                display: flex;
                align-items: center;
            }
        }
    }
}

.group-header {
    @extend %icon-float-right;

    /* .md-subheader-content > div{
        md-icon {
            margin-left: 4px;
        }
    } */
}

.group-header.drag,
.opportunities-header.drag {
    opacity: 0.4;
}

.group-header.drag {
    background-color: variables.$white-color;
}

.group-header.drag-enter,
.opportunities-header.drag-enter {
    opacity: 1;
}

.group-header.drag-enter {
    background-color: variables.$subheader-color;
}

.list-header {
    @include utils.font-size(14, true);

    margin-right: 0;
    text-align: center;
    color: variables.$secondary-color;

    &.timeline-header {
        text-align: left;
    }
}

.info-header {
    @extend %icon-float-right;

    background-color: variables.$white-color;
}

.md-subheader:not(.md-sticky-no-effect):after {
    right: 0;
}

.md-subheader-wrapper {
    background-color: variables.$white-color;
}

md-sidenav {
    div {
        md-content {
            .md-subheader-wrapper:not(.md-sticky-no-effect).md-sticky-clone {
                z-index: 3;
            }
        }
    }
}

.md-subheader .md-subheader-content {
    z-index: 0;
}

.list-header.top {
    margin-top: 24px;
    margin-bottom: 7px;
}

// Angular material adds these to the subheader
// Adding these on the sticky clone prevents the visual jumpyness due to the text and the height being different
.md-sticky-clone .md-subheader {
    letter-spacing: 0.01em;
    line-height: 1.2em;

    // Prevent md-tooltip from showing its text in the sticky clone
    // The clone doesn't properly take over the behavior which causes the clone to show the text of the tooltip
    // See https://github.com/Salesflare/Client/issues/1697
    md-tooltip {
        @include utils.hide;
    }
}
