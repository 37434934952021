@use "sass:color";
@use "sass/core/variables";

#export-fields-dialog {
    md-select span {
        color: variables.$text-color;
    }

    md-input-container.inline {
        position: absolute;
        top: 20px;
        left: 180px;
    }
}
