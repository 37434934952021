@use "sass/core/variables";

md-input-container input.password-input {
    border: none;

    + .md-errors-spacer {
        min-height: 0; // This is a bit of a hacky way to get the space between input field and progress bar right
    }
}

sf-password-meter {
    .password-meter-wrapper {
        height: 100%;
        width: 100%;
        margin-bottom: -20px; // We want to pull the error messages on the same line as the password-status

        .password-status {
            display: flex;
            flex-direction: row-reverse;
            min-height: 17px;
        }

        md-progress-linear {
            .md-container {
                height: 2px;
            }

            &.weak {
                .md-bar,
                md-progress-linear .md-bar {
                    background-color: variables.$red-color;
                }

                .md-container,
                md-progress-linear .md-container {
                    background-color: rgb(252 179 179);
                }
            }

            &.moderate {
                .md-bar,
                md-progress-linear .md-bar {
                    background-color: variables.$orange-color;
                }

                .md-container,
                md-progress-linear .md-container {
                    background-color: rgb(252 213 179);
                }
            }

            &.strong {
                .md-bar,
                md-progress-linear .md-bar {
                    background-color: variables.$green-color;
                }

                .md-container,
                md-progress-linear .md-container {
                    background-color: #b6deaf;
                }
            }
        }
    }

    + div.error-messages {
        // 19px is the height the element gets naturally when it contains one error (we only want to show one at a time)
        min-height: 19px; // To override the default min-height of 24px
        height: 19px; // We don't want the 'confirm password' field to jump around when one error replaces another one
    }
}
