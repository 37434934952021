@use "sass/core/variables";

#opportunityInfo {
    height: 100%;

    md-list-item.sf-list-item-info {
        min-height: 36px;

        button.md-icon-button.hover-button {
            display: none;
            height: 18px;
            width: 18px;
            margin: 0;
        }

        :hover {
            button.md-icon-button.hover-button {
                display: inline-block;
            }
        }
    }

    md-list-item {
        background-color: variables.$gray-color;
    }

    // All but first one --> if more than 2 icons only last 2 have margin
    md-list .md-secondary-container md-icon:last-child {
        color: variables.$blue-color;
        margin-left: 8px;
    }

    button.md-button {
        text-transform: none;
    }

    @media screen and (min-width: variables.$layout-breakpoint-sm) {
        md-toolbar {
            h1 {
                max-width: 210px;
            }

            h2 {
                max-width: 170px;
            }

            .sf-avatar_large {
                right: 32px;
            }

            .sf-avatar_top {
                margin-top: 16px;
                margin-left: -36px; // to get it to overlap with the opp avatar
                margin-right: 0;
                z-index: 2001;
            }
        }
    }

    @media screen and (max-width: variables.$layout-breakpoint-sm) {
        .sf-avatar_large {
            left: 35px;
        }

        .sf-avatar_top {
            margin-right: 0;
            z-index: 1001;
        }
    }

    .info-item {
        margin: 16px;

        md-icon {
            margin-right: 8px;
        }
    }

    .error,
    .error md-icon {
        color: variables.$error-color;

        &.text {
            padding: 0 16px;
            font-size: 12px;
        }
    }

    .stage-update-entry:last-child {
        // To make the spacing between the stage updates and the button below it look the same as the other opportunity info sections
        // Other sections have 16px bottom-margin but the text above it has a line-height of 18px while the stage-update-entry has a line-height of 30px, taking 10px instead of 16px to offset that.

        margin-bottom: 10px;
    }

    md-content {
        .md-subheader-wrapper:not(.md-sticky-no-effect).md-sticky-clone {
            // The subheaders should always show over all elements other than dialogs and their backdrops
            // The stage update arrows have a variable z-index based on the amount of stages in that pipeline, for that reason we keep the number high enough

            z-index: 79; // z-index of backdrop is 80
        }
    }
}

md-dialog #opportunityInfo {
    .sf-avatar_large {
        right: 50px;
    }
}

.opportunity-sidebar {
    width: 100%;

    // overlap the FAB
    z-index: 71;
}
