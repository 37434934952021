@use "sass/core/variables";

$import-padding: 0 (variables.$default-spacing * 0.5) 0 variables.$default-spacing;

sf-import-list .import-list {
    padding: $import-padding;
    border-bottom: 1px;

    &:last-child {
        padding-bottom: 100px;
    }

    .support-header {
        font-size: 16px;
    }

    .import-title {
        font-size: 14px;
    }
}
