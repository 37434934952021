@use "sass/core/variables";

sf-workflow-step-analytics {
    md-toolbar .md-toolbar-tools {
        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            .search-mode {
                margin-left: 11px;
                width: 100%;
            }
        }
    }

    .tabs-analytics-bar {
        width: 100%;
        background: variables.$white-color;
        height: 64px;

        md-tabs.workflow-analytic-tabs {
            min-height: 0;
        }

        .tabs-analytics-column {
            text-align: center;
            font-weight: 600;
            font-size: 18px;
            margin: auto;
        }

        &:not(.editing) {
            cursor: pointer;
            color: variables.$salesflare-color;
        }

        &.editing {
            cursor: default;
        }
    }

    .workflow-filter-select,
    .workflow-audience-search {
        background-color: variables.$white-color;
        padding-right: variables.$default-spacing;
        padding-left: variables.$default-spacing;
        color: variables.$accent-color;
    }

    md-input-container.search-input {
        // flex doesn't work well with padding, so margin on child elements is needed
        margin-left: variables.$default-spacing;

        @media screen and (max-width: variables.$layout-breakpoint-sm) {
            input {
                border: 0;
            }
        }

        @media screen and (min-width: variables.$layout-breakpoint-sm) {
            // Fix vertical alignment search bar and filter select
            padding-bottom: variables.$default-spacing * 0.5;
        }
    }

    sf-workflow-send-email-audience {
        .list {
            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                margin: 0 16px 32px;
            }

            md-list-item {
                width: 100% !important;

                .md-button.account-link.md-default-theme:not([disabled]):hover,
                .md-button:not([disabled]):hover {
                    &.account-link,
                    &.fake-button {
                        background-color: transparent;

                        p {
                            text-decoration: underline;
                        }
                    }
                }

                sf-exit-workflow-record-button {
                    button.exit-record-button,
                    button.re-enter-record-button {
                        width: 55px;
                        margin: 0;
                        padding: 0;

                        &.md-icon-button {
                            @media screen and (min-width: variables.$layout-breakpoint-sm) {
                                line-height: 0; // Centers icon in list item
                            }
                        }
                    }
                }

                @media screen and (max-width: variables.$layout-breakpoint-sm) {
                    md-menu {
                        position: fixed;
                        width: 32px;
                        right: 0;
                        margin-left: 4px;

                        .md-button {
                            width: 32px;
                            height: 32px;
                            padding: 0;
                            margin-right: -12px; // stick it to the right side
                            border-radius: 0;

                            &:hover {
                                background-color: transparent;
                            }

                            md-icon {
                                margin-bottom: 4px;
                            }
                        }
                    }
                }

                .md-list-item-inner div:not(:last-child, .md-secondary-container, [md-ink-ripple-checkbox]) {
                    margin-right: 0;
                }

                .fake-button {
                    font-weight: 400;
                    font-size: 14px;
                    color: variables.$text-color;
                    width: 100%;
                    text-transform: none;
                    text-align: left;
                    padding-left: 0;
                }

                .account-link {
                    text-transform: none;
                    text-align: left;
                    padding-left: 0;

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        color: variables.$text-color;
                    }
                }
            }

            md-list-item.workflow__header {
                border-bottom: 1px solid variables.$light-gray-color;
                font-weight: 500;
                height: 64px;
                padding: 0 0 0 variables.$default-spacing;

                div.md-list-item-inner .workflow__header__content {
                    color: variables.$accent-color;
                    margin-right: 0;
                    text-align: middle;
                }

                .md-secondary-container {
                    width: 55px;
                }
            }

            md-list-item.contact-list-item {
                .md-list-item-text .contact-info-row {
                    margin-right: variables.$default-spacing * 0.5;

                    h1 {
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    // Same way campaign details used to do it
    md-list-item._md-button-wrap > div.md-button {
        padding-right: 0 !important;
    }

    .workflow-analytics-header {
        min-height: 48px;
        max-height: 56px;

        // flex doesn't work well with padding, so margin on child elements is needed
        padding: variables.$default-spacing 0;

        // Leave as much space as possible for the tabs-filler element
        // Start from the max-content width to show full title when possible        .
        .md-subheader {
            flex-basis: max-content;
            flex-grow: 0;

            // flex doesn't work well with padding, so margin on child elements is needed
            margin-right: 12px;
            margin-left: variables.$default-spacing;
        }

        // This fills up any width left by title other elements (title, icons, md-select)
        #tabs-filler {
            min-height: 24px;
        }

        &.fullscreen-header-spacing {
            margin-top: variables.$default-spacing;
        }

        .md-subheader-inner {
            padding: 0;
            width: 100%;

            .md-subheader-content > span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }
        }

        md-select.filter-select {
            width: 200px;
            margin: 0;
            background-color: variables.$gray-color;
            color: variables.$accent-color;
            margin-right: variables.$default-spacing;

            md-select-value {
                min-height: 20px;
            }
        }

        > md-icon {
            flex-grow: 0;
            flex-basis: content;
            flex-shrink: 0;

            // flex doesn't work well with padding, so margin on child elements is needed
            margin-right: variables.$default-spacing;

            // When the filter select is active, less margin is needed between the elements
            &.not-last {
                margin-right: variables.$default-spacing * 0.5;
            }
        }
    }

    .workflow-interaction-date {
        vertical-align: middle;
    }

    .md-button.md-raised.header-button {
        margin-right: variables.$default-spacing * 0.5;
        padding: 0 variables.$default-spacing * 0.5;
        height: 24px;
        line-height: inherit;
        font-size: 12px;
        min-width: max-content;

        &:not([disabled]),
        &:not([disabled]):hover {
            background-color: variables.$white-color;
            color: variables.$accent-color;
        }

        md-icon {
            color: variables.$accent-color;
        }

        > span {
            vertical-align: middle;
            margin-left: 2px;
        }
    }
}
