@use "sass/core/utils";

#deleteContactDialog {
    max-width: 480px;

    md-dialog-content {
        div.microcopy {
            padding: 8px 16px;

            @include utils.font-size(12);
        }
    }
}
