@use "sass/core/variables";

#meeting {
    md-time-picker {
        padding-top: 2px;

        button.md-icon-button {
            display: none;
        }
    }

    .jodit-container {
        padding-bottom: 4px;
        border-bottom: 1px solid lightgray;
        height: 200px !important;
        width: 100% !important;
    }

    .jodit-toolbar__box {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .jodit-wysiwyg {
        padding: 16px 0;
    }

    .mobile-meeting-time {
        margin-bottom: variables.$default-spacing;
        margin-top: -(variables.$default-spacing);
    }
}
