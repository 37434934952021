@use "sass/core/variables";

$file-upload-height: 48px; // height of list item aka task line
$file-upload-padding: 0 (variables.$default-spacing * 0.5) 0 variables.$default-spacing;

.file-upload {
    @media screen and (width >= 960px) {
        padding: variables.$default-spacing variables.$default-spacing variables.$default-spacing * 0.5 variables.$default-spacing;
    }

    @media screen and (width <= 960px) {
        padding: variables.$default-spacing variables.$default-spacing variables.$default-spacing * 0.5;
    }

    #file-button {
        background-color: variables.$blue-color;
        color: white;
        height: $file-upload-height;
        padding: $file-upload-padding;
        width: 100%;

        .import-icon {
            margin-right: variables.$default-spacing;
            margin-left: 0;
            color: white;
            opacity: 0.9;
            margin-bottom: 2px;
        }

        .file-upload-button {
            width: 100%;
        }
    }
}
