@use "sass/core/variables";

#formErrorIndicator {
    background-color: variables.$material-gray-color;
    color: variables.$error-color;
    padding: 16px;
}

sf-form-error-indicator {
    z-index: 2;
}
