@use "sass/core/variables";
@use "shared";

sf-talk-to-us-info {
    .content-wrapper {
        .content-text {
            margin: variables.$default-spacing * 2 0;
            width: 480px;
            max-width: 100%;
        }

        button {
            min-width: shared.$button-min-width; // reset to material since it looks better
            padding: 0 8px;

            &:not(:last-child) {
                margin: 0 0 16px;
            }

            &:last-child {
                margin-bottom: 32px;
            }

            &.md-raised:not([disabled]) {
                background-color: variables.$blue-color;
                color: variables.$white-color;
            }
        }

        .md-button.md-raised:not([disabled]):hover {
            background-color: variables.$blue-color;
        }
    }
}
