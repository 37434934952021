@use "sass/core/variables";

.pipeline-disabled {
    opacity: 0.5;
}

#stage-settings {
    md-content {
        padding-bottom: 100px;
    }

    md-list-item {
        padding-left: 16px;
    }

    section.as-sortable-placeholder {
        // Used !important here to overwrite inline style on as-sortable-placeholder from sortable framework.
        // Original placeholder took the height of the whole section including stages, while we only need the height of the md-subheader

        height: 48px !important;
    }

    .md-list-item-text h1 {
        margin-top: -6px;
    }
}

.pipeline-header.md-subheader {
    .md-button.md-icon-button {
        height: 22px;
    }

    .container md-icon {
        margin: 0;
        padding-left: variables.$default-spacing * 0.5;
    }

    .md-subheader-inner {
        padding-right: 0;
        min-width: 0; // https://makandracards.com/makandra/66994-css-flex-and-min-width
    }

    .groups-text {
        margin-left: 4px;
    }
}

md-list-item.large.stage {
    padding: 0 0 0 16px;

    .stage-color-circle {
        border-radius: 50%;
        height: 48px;
        width: 48px;
        padding: 0;

        // Set to 4px instead of auto to work on IE
        margin: 4px 16px 4px 0;
        font-size: 30px;
        text-align: center;
        color: variables.$white-color;

        span {
            vertical-align: middle;
            margin-right: 1px;
        }
    }
}
