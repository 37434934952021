@use "sass/core/variables";

/*
 * md-autocomplete fixes
 */

md-autocomplete {
    background: transparent;

    input {
        font-size: 14px;
    }
}

// fix position of progress on autocomplete with a label
md-autocomplete[md-floating-label] {
    md-input-container {
        padding-bottom: 0;
    }

    md-progress-linear.md-inline {
        bottom: 28px;
    }
}

// Fixes to get sf-avatar aligned in a autocomplete suggestions
.md-autocomplete-suggestions sf-avatar {
    display: inline-block;
    vertical-align: middle;
}

/*
 * Contact chips fixes
 */

// make sure everything has the same height
.md-contact-chips-suggestions li {
    height: 56px;
    line-height: 56px;
}

.md-contact-suggestion {
    display: flex;

    img {
        margin-bottom: 8px; // we upped the line-height so we need to re-center the image
    }

    .md-contact-name,
    .md-contact-email {
        width: initial;
    }

    .md-contact-email {
        width: initial;
        margin-left: 8px; // since we reset the width, otherwise the email and name will stick together
    }
}

.md-filter-suggestion {
    display: flex;
    height: 48px;

    img {
        margin-bottom: 8px; // we upped the line-height so we need to re-center the image
    }

    .md-filter-name {
        width: initial;
    }
}

.sf-autocomplete-subheader {
    margin: 0 -16px;
    height: 48px;
    line-height: 48px;

    .md-subheader-inner {
        padding: 0 16px;
    }

    span {
        font-size: variables.$font-size-14 + px;
        font-weight: bold;
    }
}
